import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Vant from 'vant';
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
// import axios from 'axios'
import axios from "./utils/request.js";

import "@/components/map/index.js";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
// Vue.use(Vant);
Vue.use(Element);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
