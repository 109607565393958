<template>
  <div class="mapTestPage">
    <map-base ref="mapCom" :map="map" />
  </div>
</template>

<script>
// <script module = 'mapTestPageContainer' lang="renderjs">
export default {
  name: "mapTestPage",
  components: {},
  props: ["map"],
  data() {
    return {
      layerId: "mapTest",
    };
  },
  inject: [],
  watch: {},
  onLoad() {},
  mounted() {
    // this.mapTest();
  },
  methods: {
    mapTest() {
      let layer = this.$refs.mapCom.createLayer("test");
      this.map.addLayer(layer);
      let data = require("./data/滨州3海里.json");
      let features = new this.mapApi.GeoJSON().readFeatures(data);
      layer.getSource().addFeatures(features);
      features.map((v) => {
        let _coords = v.getGeometry().getCoordinates();
        let coorString = "";
        _coords.map((v) => {
          coorString += v[0] + " " + v[1] + ",";
        });
        coorString = coorString.slice(0, coorString.length - 1);
        console.log(423434, coorString);
        let feature = new this.mapApi.Feature({
          geometry: new this.mapApi.Polygon([_coords]),
        });
        feature.setStyle(
          new this.mapApi.Style({
            fill: new this.mapApi.Fill({
              color: "rgba(255,255,255,0)",
            }),
            stroke: new this.mapApi.Stroke({
              color: "purple",
              width: 2,
            }),
            text: new this.mapApi.Text({
              font: "bold 20px sans-serif",
              offsetX: 50,
              offsetY: 0,
              scale: 1,
              text: v.get("name"),
              fill: new this.mapApi.Fill({ color: "purple" }),
              textBaseline: "bottom",
              stroke: new this.mapApi.Stroke({
                // 线样式
                color: "#fff",
                width: 1,
              }),
            }),
          })
        );
        layer.getSource().addFeature(feature);
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped></style>
