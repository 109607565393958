<template>
  <div></div>
</template>
<script>
// <script module = 'mapToolContainer' lang="renderjs">
import MapTool from "@/components/map/map-tool/mapTool.js";
export default {
  name: "mapToolTar",
  components: {},
  props: ["map"],
  data() {
    return {
      mapTool: this.$store.getters.mapTool,
      postParam: {}, //工具条传递参数
      mapToolTar: null, //地图工具条
      singleTool: this.$mapConfig.mapTool.singleTool, ///当前工具执行次数【false：可以执行多次;true：当且只有一次】
      crossTool: this.$mapConfig.mapTool.crossTool, //不同工具条交互【false：可以交叉工具使用;true：禁止交叉工具使用】
      updateTool: this.$mapConfig.mapTool.updateTool, ///刷新要素，保留最新的要素
      identifyTool: this.$mapConfig.mapTool.identifyTool, ///自定义绘制
      toolJson: [
        {
          id: 0,
          type: "IdentifyPost",
          drawType: [
            {
              id: 0,
              type: "Point",
              childType: [
                { id: 0, type: "default" },
                { id: 1, type: "img1", icon: "" },
              ],
            },
            {
              id: 1,
              type: "LineString",
              childType: [
                { id: 0, type: "default" },
                { id: 1, type: "lineDash" },
              ],
            },
            {
              id: 2,
              type: "Polygon",
              childType: [
                { id: 0, type: "default" },
                { id: 1, type: "lineDash" },
              ],
            },
            {
              id: 3,
              type: "Circle",
              childType: [
                { id: 0, type: "default" },
                { id: 1, type: "lineDash" },
              ],
            },
          ],
        },
        {
          id: 1,
          type: "Pos",
        },
        {
          id: 2,
          type: "Extent",
        },
        {
          id: 3,
          type: "Pan",
        },
        {
          id: 4,
          type: "Zoomin",
        },
        {
          id: 5,
          type: "Zoomout",
        },
        {
          id: 6,
          type: "marker",
        },
        {
          id: 7,
          type: "View",
        },
        {
          id: 8,
          type: "Grid",
        },
        {
          id: 9,
          type: "LineString",
        },
        {
          id: 10,
          type: "Clear",
        },
        {
          id: 11,
          type: "Deactive", //取消当前工具条（不清空）
        },
        {
          id: 12,
          type: "Direction",
        },
      ],
      index: 14, //颜色标记
      positionExist: false, //定位
      longitude: null,
      latitude: null,
      sectionType: null, //模块类型
      toolLayers: [], //工具图层

      style: {
        //样式
        fill: new this.mapApi.Fill({
          color: "rgba(0,255,0,0.5)",
        }),
        stroke: new this.mapApi.Stroke({
          color: "red",
          width: 2,
        }),
        image: new this.mapApi.Circle({
          radius: 7,
          fill: new this.mapApi.Fill({
            color: "red",
          }),
        }),
        imageIcon: new this.mapApi.Icon({
          src: require("@/assets/image/map/mapTool/ic_eljcz1.png"),
          scale: 1,
        }),
      },
    };
  },
  inject: [
    "setStartMoveLis",
    "setDrawToolMoveLis",
    "setDragInfoMoveLis",
    "getStartMoveLis",
    "getDrawToolMoveLis",
    "getDragInfoMoveLis",
    "setMapClickAllowed",
  ],
  watch: {
    isShow: {
      handler: function () {
        // if (v) this.deactiveDraw();
        // if (v && !this.mapToolTar) this.initMapTool();
      },
      deep: true,
    },
    //工具条回调监听
    "mapToolTar.callData": {
      handler: function (v) {
        if (v) {
          //工具条内部执行（数据处理）
          this.dealInnerTool(v);
          //非外部传入绘制
          this.dealInnerDraw(v);
          //外部调用
          this.dealOuterTool(v);
        }
      },
    },
    "mapTool.selected": {
      async handler(v) {
        if (v && v.callType === 1) {
          // //清空掉内部工具条相关
          // this.conductMapTool([10]);
          this.deactiveDraw();
          this.triggleOuterToolStore(v.sectionType, 0, 2);
          //区域回放
          if (v.sectionType === 1) {
            //调用多边形选择
            if (v.tooType === 1) {
              this.conductMapTool([0, 2, 0]);
            }
            //调用框选
            else if (v.tooType === 2) {
              this.conductMapTool([2]);
            }
            // 圈选
            else if (v.tooType === 3) {
              this.conductMapTool([0, 3, 0]);
            }
            // 其他
            // ...
          }
          //范围船只筛选
          else if (v.sectionType === 2) {
            //调用多边形选择
            if (v.tooType === 1) {
              this.conductMapTool([0, 2, 0]);
            }
            //调用框选
            else if (v.tooType === 2) {
              this.conductMapTool([2]);
            } // 圈选
            else if (v.tooType === 3) {
              this.conductMapTool([0, 3, 0]);
            }
          }
          //救援筛选
          else if (v.sectionType === 3) {
            //调用多边形选择
            if (v.tooType === 1) {
              this.conductMapTool([0, 2, 0]);
            }
            //调用框选
            else if (v.tooType === 2) {
              this.conductMapTool([2]);
            } // 圈选
            else if (v.tooType === 3) {
              this.conductMapTool([0, 3, 0]);
            }
          }
          //基础工具
          else if (v.sectionType === 4) {
            if (v.tooType === 4) {
              this.conductMapTool([4]);
            } else if (v.tooType === 5) {
              this.conductMapTool([5]);
            } else if (v.tooType === 6) {
              this.conductMapTool([3]);
            } else if (v.tooType === 7) {
              this.conductMapTool([9]);
            } else if (v.tooType === 8) {
              this.conductMapTool([10]);
            } else if (v.tooType === 10) {
              this.conductMapTool([7]);
            }
          }
          //格网
          else if (v.sectionType === 5) {
            if (v.tooType === 9) {
              this.conductMapTool([8]);
            }
          }
          //方位角
          else if (v.sectionType === 6) {
            // 圈选
            if (v.tooType === 3) {
              this.conductMapTool([12]);
            }
          }
          //记录当前外部调用的模块类型
          this.sectionType = v.sectionType;
          //外部引用工具条执行自定义绘制
          this.updateMaptTool({ identifyTool: true });
        }
      },
      deep: true,
    },
  },
  onLoad() {
    // console.log("地图工具条页面");
  },
  mounted() {
    this.postParam = {
      map: this.map,
      mapContainer: document.getElementById(
        this.map.getViewport().parentElement.id
      ),
      mapApi: this.mapApi,
      singleTool: this.singleTool,
      crossTool: this.crossTool,
      updateTool: this.updateTool,
      identifyTool: this.identifyTool,
      mapConfig: this.$mapConfig,
    };
    this.initMapTool();
  },
  methods: {
    triggleOuterToolStore(sectionType, tooType, callType, data) {
      this.$store.dispatch("mapTool/setSelected", {
        sectionType: sectionType,
        tooType: tooType,
        callType: callType,
        data: data,
      });
    },
    dealInnerDraw(v) {
      if (!v.type || v.type === this.toolJson[11].type) return;
      const type = v.type;
      const data = v.data;
      const rootType = type.split("_")[0];
      const childType = type.split("_")[1];
      const getFeature = (type) => {
        let feature,
          features = [];
        switch (type) {
          case "Point":
            feature = new this.mapApi.Feature({
              geometry: new this.mapApi.Point(data.points),
            });
            if (rootType === "Marker")
              feature.setStyle(this.setImageIconStyle());
            else feature.setStyle(this.setImageCircleStyle());
            features.push(feature);
            break;
          case "LineString":
            {
              data.points.map((v, k) => {
                let nextP = data.points[k + 1];
                if (nextP) {
                  feature = new this.mapApi.Feature({
                    geometry: new this.mapApi.LineString([v, nextP]),
                  });
                  feature.setStyle(this.setStrokeStyle());
                  features.push(feature);
                }
              });
            }
            break;
          case "Polygon":
            feature = new this.mapApi.Feature({
              geometry: new this.mapApi.Polygon(data.points),
            });
            feature.setStyle(this.setFillStyle());
            features.push(feature);
            break;
          case "Circle":
            feature = new this.mapApi.Feature({
              geometry: new this.mapApi.CircleGeom(data.center, data.radius),
            });
            features.push(feature);
            feature.setStyle(this.setFillStyle());
            break;
        }
        return features;
      };
      if (!this.sectionType) {
        //自定义更新情况下绘制（格网以及不参与draw的无需重绘）
        if (this.identifyTool) {
          this.addLayer(type);
          let features = [];
          //跨工具情况下，多工具要素均要绘制
          if (this.crossTool) {
            //每种工具当且只需保留一个要素
            if (this.updateTool) {
              this.toolLayers[type].getSource().clear();
              features = getFeature(childType, data);
              this.toolLayers[type].getSource().addFeatures(features);
            }
            // 反之，保留所有工具的所有要素
            else {
              features = getFeature(childType, data);
              this.toolLayers[type].getSource().addFeatures(features);
            }
          }
          //反之,当且只需保留当前绘制的工具要素
          else {
            //当前工具当且只需保留一个要素
            if (this.updateTool) {
              for (let key in this.toolLayers)
                this.toolLayers[key].getSource().clear();
              features = getFeature(childType, data);
              this.toolLayers[type].getSource().addFeatures(features);
            }
            // 反之，保留当前工具的所有要素
            else {
              for (let key in this.toolLayers)
                if (key !== type) this.toolLayers[key].getSource().clear();
              features = getFeature(childType, data);
              this.toolLayers[type].getSource().addFeatures(features);
            }
          }
        }
      }
    },
    dealInnerTool(v) {
      let mapClickFlag = false;
      this.toolConductTypeFun(mapClickFlag);
      if (!v.type) return;
      const rootType = v.type.split("_")[0];
      const childType = v.type.split("_")[1];
      switch (rootType) {
        case "Messure":
          if (v.data) {
            mapClickFlag = true;
          }
          break;
        case "Clear":
          if (v.data) {
            mapClickFlag = true;
            //清空时恢复初始参数
            this.updateMaptTool({ identifyTool: this.identifyTool });

            if (!this.singleTool) {
              this.toolConductTypeFun(mapClickFlag);
            }
          }
          break;
        case "Marker":
          if (v.data) {
            switch (childType) {
              case "Point": //点
                mapClickFlag = true;
                break;
              default:
                break;
            }
          }
          break;
        case "Extent":
          if (v.data) {
            switch (childType) {
              case "Polygon": //面
                if (v.data.points && v.data.points.length > 0) {
                  let coorString = "";
                  v.data.points[0].forEach((point) => {
                    coorString +=
                      point[0].toFixed(6) + " " + point[1].toFixed(6) + ",";
                  });
                  v.data.coorString = coorString.slice(
                    0,
                    coorString.length - 1
                  );
                }
                break;
              default:
                break;
            }
            mapClickFlag = true;
          }
          break;
        case "Calculate":
          if (v.data) {
            switch (childType) {
              case "Point": //点
                break;
              case "LineString": //线
                break;
              case "Polygon": //多边形
                if (v.data.points && v.data.points.length > 0) {
                  let coorString = "";
                  v.data.points[0].forEach((point) => {
                    coorString +=
                      point[0].toFixed(6) + " " + point[1].toFixed(6) + ",";
                  });
                  v.data.coorString = coorString.slice(
                    0,
                    coorString.length - 1
                  );
                }
                break;
              case "Circle": //圆
                // if (v.data.radius && v.data.center) {
                // }
                break;
            }
            mapClickFlag = true;
          }
          break;
        case "Direction":
          if (v.data) {
            mapClickFlag = true;
          }
          break;
        default: {
          if (v.data) {
            mapClickFlag = true;
            this.toolConductTypeFun(mapClickFlag);
          }
          break;
        }
      }
      // console.log(rootType, childType, v.data);
      if (this.singleTool) {
        setTimeout(() => {
          this.toolConductTypeFun(mapClickFlag);
        }, 500);
      }
    },
    dealOuterTool(v) {
      if (!v.type) return;
      const rootType = v.type.split("_")[0];
      const childType = v.type.split("_")[1];
      let tooType = 0;
      switch (rootType) {
        case "Extent":
          if (v.data) {
            switch (childType) {
              case "Polygon": //面
                tooType = 2;
                break;
              default:
                break;
            }
          }
          break;
        case "Calculate":
          if (v.data) {
            switch (childType) {
              case "Polygon": //多边形
                tooType = 1;
                break;
              case "Circle": //圆
                tooType = 3;
                break;
            }
          }
          break;
        case "Direction":
          if (v.data) {
            switch (childType) {
              case "Circle": //面
                tooType = 3;
                break;
              default:
                break;
            }
          }
          break;
        default: {
          break;
        }
      }
      if (tooType && this.sectionType && v.data) {
        this.triggleOuterToolStore(this.sectionType, tooType, 2, [
          rootType,
          childType,
          v.data,
        ]);
        //绘制完终止工具条
        setTimeout(() => {
          this.deactiveDraw();
        });
      }
    },
    //取消当前工具条激活状态
    deactiveDraw() {
      this.sectionType = null; //模块选择
      this.updateMaptTool({ identifyTool: this.identifyTool }); //还原初始参数
      this.conductMapTool([11]); //执行
      // this.initMapTool();
    },
    toolConductTypeFun(mapClickFlag) {
      this.setDrawToolMoveLis(mapClickFlag);
      this.setMapClickAllowed(mapClickFlag);
    },
    // 初始化地图工具条
    initMapTool() {
      this.mapToolTar = new MapTool(this.postParam);
    },
    updateMaptTool(param) {
      if (this.mapToolTar) {
        let tar = {};
        for (let key in param) {
          if (Object.prototype.hasOwnProperty.call(this.postParam, key))
            tar[key] = param[key];
        }
        this.mapToolTar.update(Object.assign(this.postParam, tar));
      }
    },
    // 执行地图工具
    conductMapTool(data, index) {
      //外部工具条执行时，禁用默认工具条
      if (this.sectionType) {
        this.triggleOuterToolStore(this.sectionType, -2, 2);
        return;
      }
      this.positionExist = false;
      this.index = index;
      try {
        if (data) {
          const tar = this.toolJson[data[0]];
          switch (tar.type) {
            case this.toolJson[0].type:
              {
                const type1 = tar.drawType[data[1]];
                const type2 = type1.childType[data[2]];
                if (tar.type && type1.type && type2.type)
                  this.mapToolTar.addMapToolEvent(
                    tar.type,
                    type1.type,
                    type2.type,
                    type2.icon
                  );
              }
              break;
            case this.toolJson[1].type:
              // this.mapToolTar.addMapToolEvent(tar.type, data[1])
              this.positionExist = true;
              break;
            default:
              this.mapToolTar.addMapToolEvent(tar.type);
              break;
          }
        } else {
          this.mapToolTar.addMapToolEvent();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setImageIconStyle(icon) {
      return new this.mapApi.Style({
        image: new this.mapApi.Icon({
          src: icon
            ? icon
            : require("@/assets/image/map/mapTool/ic_eljcz1.png"),
          scale: this.style.imageIcon.getScale(),
        }),
      });
    },
    setImageCircleStyle() {
      return new this.mapApi.Style({
        image: new this.mapApi.Circle({
          radius: 7,
          fill: new this.mapApi.Fill({
            color: "rgba(255,0,0,0.5)",
          }),
        }),
      });
    },
    setFillStyle(color, lineDash) {
      return new this.mapApi.Style({
        fill: new this.mapApi.Fill({
          color: color ? color : "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new this.mapApi.Stroke({
          color: "red",
          width: 2,
          lineDash: lineDash ? lineDash : [1, 3, 5, 7],
        }),
      });
    },
    setStrokeStyle(lineDash) {
      return new this.mapApi.Style({
        stroke: new this.mapApi.Stroke({
          color: "red",
          width: 2,
          lineDash: lineDash ? lineDash : [1, 3, 5, 7],
        }),
      });
    },
    addLayer(layerId) {
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      if (!this.$refs.mapCom.getLayer(layerId, 1)) {
        let layer = this.$refs.mapCom.createLayer(layerId);
        this.$refs.mapCom.insertAtLayer(baseLayerLength, layer);
        this.toolLayers[layerId] = layer;
      }
    },
    removeLayer(layerId) {
      if (this.$refs.mapCom.getLayer(layerId, 1)) {
        this.$refs.mapCom.removeLayer(layerId, 1);
        this.toolLayers[layerId] = null;
      }
    },
    changePosition(e, t) {
      if (t === 0) this.longitude = e.target.value;
      else this.latitude = e.target.value;
    },
    setPosition() {
      this.$store.dispatch("mapPosition/setSelected", {
        type: 0,
        position: [this.longitude, this.latitude],
      });
      // this.$store.dispatch("mapPosition/setSelected", {
      //   type: 1,
      //   deviceType: "1",
      //   position: [119.8498, 35.38257],
      //   data: { shipName: "鲁岚渔养69251" },
      // });
    },
    //轨迹添加测试start------------------------------------------
    addRouteTest() {
      this.$store.dispatch("mapRoute/setSelected", {
        pageType: "test",
        id: new Date().getTime() + Math.random().toString(36).substr(2),
        name: "test" + Math.random().toString(36).substr(10),
        terminalCode:
          new Date().getTime() + Math.random().toString(36).substr(2),
        key: "mapRoute/getAisTrailData",
      });
    },
    clearRouteRelevantAllTest() {
      this.$store.dispatch("mapRoute/clear", { pageType: "test" });
      this.index = 14;
    },
    // 轨迹添加测试end-----------------------------------------------------
    // 气象添加测试start----------------------------------------------------
    addWeather(data) {
      this.$store.dispatch("mapWeather/setSelected", {
        type: data[0],
        checked: data[1],
      });
    },
    // 气象添加测试end-----------------------------------------------------
    // 渔船添加测试start----------------------------------------------------
    addShip(data) {
      this.$store.dispatch("mapShip/setSelected", {
        deviceType: data[0],
        spatial: data[1],
        checked: data[2],
        colorRender: data[3],
      });
      this.$store.dispatch("mapLegend/setSelected", {
        type: data[0],
        checked: data[2],
      });
    },
    // 渔船添加测试start----------------------------------------------------
    // 地图切换start----------------------------------------------------
    switchMap(data) {
      this.$store.dispatch("mapSwitch/setSelected", {
        type: data[0],
      });
    },
    // 地图切换end-----------------------------------------------------
    // 渔业地理边界start----------------------------------------------------
    addFishBound(data) {
      this.$store.dispatch("mapFishBound/setSelected", {
        type: data[0],
        checked: data[1],
      });
    },
    // 渔业地理边界end----------------------------------------------------
    // 专题图层start----------------------------------------------------
    addTheme(data) {
      this.$store.dispatch("mapTheme/setSelected", {
        type: data[0],
        checked: data[1],
      });
    },
    // 专题图层end----------------------------------------------------
    //区域回放start-------------------------------------------------------
    areaPlayBack(tooType) {
      this.$store.dispatch("mapTool/setSelected", {
        sectionType: 1,
        tooType: tooType,
        callType: 1,
      });
    },
    //end-------------------------------------------------------
    //范围筛选start-------------------------------------------------------
    extentFilter(tooType) {
      this.$store.dispatch("mapTool/setSelected", {
        sectionType: 2,
        tooType: tooType,
        callType: 1,
      });
    },
    //范围筛选end-------------------------------------------------------
  },
  computed: {
    isShow() {
      return false
      // return this.$store.getters.system.isMapTolShow;
    },
  },
};
</script>

<style lang="scss" scoped>
// .mapToolContainer {
//   position: absolute;
//   z-index: 999;
//   top: 6rem;
//   left: 50%;
//   transform: translate(-50%);
//   display: none;
//   transition: all 1.5s;
//   background: #0a4555;
//   border: 1px solid #08abb3;
//   padding: 5px;

//   button {
//     border: none;
//     background: none;
//     color: white;
//     padding: 0 8px;
//     border-right: 1px solid #08abb3;
//   }

//   .buttonTool {
//     background: none;
//     color: white;
//     padding: 0 8px;
//     border-right: 1px solid #08abb3;
//   }

//   &.active {
//     display: block;
//     transition: all 1.5s;
//   }

//   .buttonTool:last-child {
//     border-right: none;
//   }
// }
</style>
