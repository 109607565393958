import Grid from './js/grid.js'
const seaWeather = function (options) {
  let mapApi = options.mapApi
  let mapTar = options.map
  let builder;
  let grid;
  let gridData = options.data;
  let λ0, φ0, Δλ, Δφ, ni, nj, maxLon, minLat;
  let gridValueTar = null
  let minValue = options.minValue ? options.minValue : 0.1
  let maxValue = options.maxValue ? options.maxValue : 30


  let bilinearInterpolateVector = function bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
    let rx = 1 - x;
    let ry = 1 - y;
    return g00 * rx * ry + g10 * x * ry + g01 * rx * y + g11 * x * y;
  };
  let createWeatherBuilder = function createWeatherBuilder(res) {
    let _data, _data1, _data2;
    if (res.length > 1) {
      _data1 = res[0]; //res.dataJson[0]//res[0];
      _data2 = res[1]; //res.dataJson[1];
      _data = _data1.data.map(function (v, i) {
        var u = _data2.data[i]
        return Math.sqrt(u * u + v * v)
      });
    } else {
      _data1 = res[0]; //res.dataJson[0];
      _data = _data1.data.map(function (v) {
        // 向量中有 负值，需取绝对值
        return Math.abs(v)
      });
    }

    return {
      header: res[0].header,
      data: function data(i) {
        return _data[i];
      },
      interpolate: bilinearInterpolateVector
    };
  };
  let buildGrid = function buildGrid(data, callback) {
    builder = createWeatherBuilder(data);
    let header = builder.header;
    λ0 = header.lo1;
    φ0 = Math.max(header.la2, header.la1);
    Δλ = header.dx;
    Δφ = header.dy;
    ni = header.nx;
    nj = header.ny;
    maxLon = header.maxLon;
    minLat = header.minLat;
    grid = [];
    let p = 0;
    let isContinuous = Math.floor(ni * Δλ) >= 360;

    for (let j = 0; j < nj; j++) {
      let row = [];
      for (let i = 0; i < ni; i++, p++) {
        row[i] = builder.data(p);
      }
      if (isContinuous) {
        row.push(row[0]);
      }

      grid[j] = row;
    }

    callback({
      interpolate: interpolate,
      minValue: minValue,
      maxValue: maxValue
    });
  };

  let interpolate = function interpolate(λ, φ) {
    if (!grid) return null;
    if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat) {
      return -1;
    }
    let i = (λ - λ0) / Δλ;
    let j = (φ0 - φ) / Δφ;
    let fi = Math.floor(i),
      ci = fi + 1;
    let fj = Math.floor(j),
      cj = fj + 1;
    let row = grid[fj];

    if (row) {
      let g00 = row[fi];
      let g10 = row[ci];
      if (ci === row.length)
        g10 = row[row.length - 1];
      row = grid[cj];
      if (cj === grid.length) row = grid[grid.length - 1]
      if (isValue(g00) && isValue(g10)) {
        let g01 = row[fi];
        let g11 = row[ci];
        if (ci === row.length) g11 = row[row.length - 1];

        if (isValue(g01) && isValue(g11)) {
          return builder.interpolate(i - fi, j - fj, g00, g10, g01, g11);
        }
      }
    }
    return null;
  };

  let isValue = function isValue(x) {
    return x !== null && x !== undefined;
  };
  let start = function start() {
    buildGrid(gridData, function (grid) {
      addGridValue(grid)
    });
  };
  let addGridValue = function addGridValue(grid) {
    gridValueTar = new Grid({
      map: mapTar,
      mapApi: mapApi,
      gridValue: grid,
      extent: [λ0, minLat, maxLon, φ0],
      minValue: minValue,
      maxValue: maxValue
    }).addGrid()
  }
  start()
  var fun = () => {

  }
  fun.prototype.appendTo = function (map, layerIndex) {
    gridValueTar.insertAtLayer(map, layerIndex)
  }
  fun.prototype.clearWeather = function () {
    gridValueTar.removeGrid()
  }
  return new fun();
}
const SeaWeather = function (options) {
  return new seaWeather(options)
}
export default SeaWeather

