<template>
  <div>
    <list :data="selectId" v-if="selectId.length" @getPath="getPath"></list>
    <pop :show="show" @closePop="closePop">
      <div>
        <h3>台风路径</h3>
        <div class="list_route">
          <div style="overflow: scroll">
            <template v-for="(item, index) in yearList">
              <div
                :key="index"
                class="tyName"
                @click.prevent="selectTy(item, index)"
                :class="activeIndex === index ? 'active' : ' '"
              >
                {{ item.TYPHOON_YEAR }}
              </div>
            </template>
          </div>

          <div class="check">
            <van-checkbox-group v-model="result" @change="changeTy" :max="4">
              <van-checkbox
                style="margin: 30px 0"
                v-for="(item, index) in tyList"
                :name="JSON.stringify(item)"
                :key="index"
                >{{ item.TYPHOON_ID }} {{ item.CHN_NAME }}
                {{ item.ENG_NAME }}</van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
      </div>
    </pop>
  </div>
</template>
<script>
// <script module = 'typhoonListContainer' lang="renderjs">
import Pop from "@/components/ui/popup.vue";
import List from "@/components/ui/tylist";
import yearList from "@/constants/yearList";
import { Checkbox, CheckboxGroup, Toast } from "vant";
export default {
  name: "TyphoonList",
  components: {
    Pop,
    List,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
  },
  data() {
    return {
      yearList, //台风年份列表
      tyList: [], //台风列表
      result: [], //选中台风
      selectId: [],
      ids: [],
      activeIndex: 0,
      show: false,
      pre: "https://map.hengtianyi.com/",
    };
  },
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.result = [];
      this.$nextTick(() => {
        this.getTyList();
        let currentYear = new Date().getFullYear();
        //默认显示当前台风路径
        this.$axios
          .get(this.pre + `/api/getTyphoonList.json?occurDate=${currentYear}`)
          .then(async (res) => {
            if (res.success) {
              // this.loadRoute(res);
            } else {
              Toast.fail(res.message);
            }
          });
      });
    },
    async loadRoute(res) {
      let typhoonObj = res.result.rows[0];
      this.selectId.push(typhoonObj);
      //多选框回显
      this.result.push(JSON.stringify(typhoonObj));
      await this.$store.dispatch("mapTyphoon/getSelected", this.selectId);
      let obj = this.$store.getters.mapTyphoon.filterObj;
      if (obj.checked) this.getPath(obj.TYPHOON_ID, 1);
    },
    //弹出层
    showPop() {
      this.show = true;
    },
    //关闭弹出层
    closePop() {
      this.show = false;
    },
    //获取台风列表
    getTyList(data) {
      let year = data === undefined ? this.yearList[0].TYPHOON_YEAR : data;
      this.$axios
        .get(this.pre + `/api/getTyphoonList.json?occurDate=${year}`)
        .then((res) => {
          if (res.success) {
            this.tyList = res.result.rows;
          } else {
            Toast.fail(res.message);
          }
        });
    },
    // 获取台风路径
    getPath(id, loadType) {
      this.$axios
        .get(this.pre + `/api/getTyphoonRoute.json?typhoonId=${id}`)
        .then((res) => {
          if (res.success) {
            //添加
            this.$store.dispatch("mapTyphoon/setSelected", {
              data: res.result,
              type: loadType ? loadType : 2,
              TYPHOON_ID: id,
            });
          } else {
            Toast.fail(res.message);
          }
        });
    },
    //选中台风
    async changeTy(val) {
      // // 清空所有
      // this.$store.dispatch("mapTyphoon/setSelected", { type: -1 });
      this.selectId = val.map((item) => {
        item = JSON.parse(item);
        return item;
      });
      await this.$store.dispatch("mapTyphoon/getSelected", this.selectId);
      let obj = this.$store.getters.mapTyphoon.filterObj;
      // console.log(22,obj)
      if (obj.checked) this.getPath(obj.TYPHOON_ID, 1);
      else
        this.$store.dispatch(
          "mapTyphoon/setSelected",
          Object.assign({ TYPHOON_ID: obj.TYPHOON_ID, type: 0 })
        );
      // this.closePop();
    },
    //选择台风年份
    selectTy(item, index) {
      this.activeIndex = index;
      this.getTyList(item.TYPHOON_YEAR);
    },
    removeRoute() {
      this.result = []
      this.closePop();
    },
  },
};
</script>
<style lang="scss" scoped>
h3 {
  color: #69729b;
  margin-left: 10px;
}

.tyName {
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  width: 80px;
}

.check {
  margin: -30px 5px;
  padding: 10px 0;
  overflow-y: scroll;
}

.active {
  background-color: #3c81f4;
  color: #fff;
  position: relative;
  border-radius: 0 20px 20px 0;
}

.list_route {
  display: flex;
  position: absolute;
  height: 100%;
  overflow-y: scroll;
}

/deep/.van-checkbox-group {
  margin: 10px 0;
}

/deep/.van-checkbox__label {
  color: #3d83ec !important;
  font-size: 20px;
}

/deep/.van-checkbox__icon .van-icon {
  border: 1px solid #3d83ec;
}
</style>
