//容器
<template>
  <div>
    <div class="weather_map">
      <map-base ref="mapCom" :map="map" />
      <MapTime ref="mapTime" :map="map" />
    </div>
    <sea-Weather ref="seaWeather" class="seaWeather" :map="map" />
  </div>
</template>
<script>
// <script module = 'mapWeatherContainer' lang="renderjs">
import SeaWeather from "./seaWeather/index";
import MapTime from "./time/map-time/map-time.vue";
export default {
  name: "MapWeather",
  props: ["map"],
  components: {
    SeaWeather,
    MapTime,
  },
  data() {
    return {};
  },
  provide() {
    return {
      dealTimeLine: this.dealTimeLine,
    };
  },
  created() {
    // console.log("进入created方法");
  },
  mounted() {},
  updated() {},
  watch: {},
  computed: {},
  methods: {
    dealTimeLine() {},
    singleClick(evt) {
      this.$refs.seaWeather.singleClick(evt);
    },
    touchOnly(feature, layerId) {
      this.$refs.seaWeather.touchOnly(feature, layerId);
    },
  },
};
</script>

<style scoped>
.weather_map {
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
}

.seaWeather {
  z-index: 9999;
  width: 100%;
  bottom: 0;
  height: auto;
  width: auto;
}
</style>
