export default {
    install(Vue) {
        /**
         * 设配适配
         * @returns 
         */
        Vue.prototype.$isMobile = function isMobile() {
            return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(navigator.userAgent);
        };
        /**
         * 地图分辨率适配
         * @returns 
         */
        Vue.prototype.$ajustPixel = function ajustPixel(evt) {
            // if (evt.pixel)
            //     //适配比例
            //     evt.pixel = [
            //         evt.pixel[0] / this.$mapConfig.mapScale[0],
            //         evt.pixel[1] / this.$mapConfig.mapScale[1],
            //     ];
            return evt;
        };
        Vue.prototype.$parseTime = function (time, cFormat) {
            if (arguments.length === 0 || !time) {
                return null
            }
            const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
            let date
            if (typeof time === 'object') {
                date = time
            } else {
                if ((typeof time === 'string')) {
                    if ((/^[0-9]+$/.test(time))) {
                        // support "1548221490638"
                        time = parseInt(time)
                    } else {
                        // support safari
                        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                        time = time.replace(new RegExp(/-/gm), '/')
                    }
                }

                if ((typeof time === 'number') && (time.toString().length === 10)) {
                    time = time * 1000
                }
                date = new Date(time)
            }
            const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay()
            }
            const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
                const value = formatObj[key]
                // Note: getDay() returns 0 on Sunday
                if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
                return value.toString().padStart(2, '0')
            })
            return time_str
        }
        Vue.prototype.$getTimeFormat = function (timestamp, format = "yyyy-mm-dd hh:mm:ss") {
            var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear()
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
            var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            const dateJson = {
                "yyyy-mm-dd hh:mm:ss": Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s,
                "yyyy-mm-dd": Y + '-' + M + '-' + D,
                "yyyy/mm/dd": Y + '/' + M + '/' + D,
                "yyyymmdd": Y + M + D,
                "yyyy年mm月dd日": Y + '年' + M + '月' + D + '日',
                "mm月dd日": M + '月' + D + '日',
                "hh时": h + "时",
                "yyyy-mm-dd hh": Y + '-' + M + '-' + D + ' ' + h,
            }
            return dateJson[format]
        }
        /**
     * /<summary>将度转换成为度分秒</summary>
     * @param {*} value
     */
        Vue.prototype.$formatDegree = function (value) {
            value = Math.abs(value);
            var v1 = Math.floor(value); // 度
            var v2 = Math.floor((value - v1) * 60); // 分
            var v3 = Math.round(((value - v1) * 3600) % 60); // 秒
            return v1 + "°" + v2 + "'" + v3 + '"';
            // return v1 + "°" + v2 + "'";
        };
    }
}