<template>
  <div v-if="isShow" id="coord" ref="coord" class="coordStyle">
    经度：{{ lonlat.lon }},纬度：{{ lonlat.lat }}
  </div>
</template>
<script>
// <script module = 'mapLonlatContainer' lang="renderjs">
export default {
  name: "mapLonlat",
  props: ["map", "isShow"],
  components: {},
  data() {
    return {
      lonlat: {
        lon: null,
        lat: null,
      },
    };
  },
  mounted() {
    this.setLonLat(this.map.getView().getCenter());
    this.bindEvent();
  },
  methods: {
    bindEvent() {
      this.map.on("pointermove", this.touchOnly);
    },
    touchOnly(e) {
      const lonlat = this.map.getCoordinateFromPixel(e.pixel);
      this.setLonLat(lonlat);
    },
    setLonLat(lonlat) {
      this.lonlat = {
        lon: this.$formatDegree(lonlat[0].toFixed(6)),
        lat: this.$formatDegree(lonlat[1].toFixed(6)),
      };
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.coordStyle {
  position: fixed;
  height: fit-content;
  width: fit-content;
  bottom: 1rem;
  right: 30rem;
  z-index: 10;
  font-size: 1rem;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(57, 78, 96, 1);
  color: rgba(255, 255, 255, 1);
}
</style>