<template>
<div></div>
  <!-- <hlDialog
    v-if="timeLineFlag"
    :title="options.title"
    :top="options.top"
    :width="options.width"
    :height="options.height"
    :right="options.right"
    class="hlDialog"
    :topeArea="options.topeArea"
    @close="closed"
  >
    <div class="timeContainer">
      <map-base ref="mapCom" :map="map" />
      <mapTimeline
        ref="timeline"
        :auto-play="timeline.autoPlay"
        :repeat="true"
        :date-arr="timeline.dateArr"
        :show-hour="true"
        :init-hour="timeline.initHour"
        :time24="timeline.time24"
        @timeToDo="timelineNext"
      />
      <mapTimeSelect
        ref="timeSelect"
        class="timeSelect"
        :st="timeSelect.startTime"
        :et="timeSelect.endTime"
        @timeSelect="getTimeSelect"
      />
    </div>
  </hlDialog> -->
</template>

<script>
// import { hlDialog } from "@/components";
// import mapTimeSelect from "../map-timeSelect/map-timeSelect.vue";
// import mapTimeline from "../map-timeline/map-timeline.vue";
export default {
  name: "timeControl",
  components: { 
    // hlDialog, mapTimeSelect, mapTimeline 
    },
  props: ["map"],
  data() {
    return {
      options: {
        title: "轨迹回放",
        height: "20rem",
        width: "25rem",
        top: "10rem",
        right: "2rem",
        topeArea: ",,c",
      },
      mapTime: this.$store.getters.mapTime,

      timeLineFlag: false,
      timeline: {
        dateArr: [],
        initHour: 0,
        autoPlay: false,
      },

      timeSelect: {
        startTime: new Date().getTime() - 24 * 60 * 60 * 1000,
        endTime: new Date().getTime(),
      },
    };
  },
  inject: [],
  watch: {
    "mapTime.selected": {
      async handler(v) {
        if (v) {
          //时间轴
          if (
            v.type === 1 &&
            v.callType === 1 &&
            v.moduleType === "mapWeather"
          ) {
            this.timeLineFlag = v.data.checked;
            if (this.timeLineFlag) {
              this.timeline.autoPlay = v.data.autoPlay;
              if (this.timeline.dateArr.length === 0) {
                v.data.startTime = Number(v.data.startTime);
                v.data.endTime = Number(v.data.endTime);
                Object.assign(this.timeSelect, {
                  startTime: v.data.startTime,
                  endTime: v.data.endTime,
                });
                let d = Math.round(
                  (v.data.endTime - v.data.startTime) / 1000 / 60 / 60 / 24
                );
                if (d < 0) d = 1;
                this.initTimeLine(v.data.startTime, d);
              }
            } else {
              this.timeline = {
                dateArr: [],
                initHour: 0,
                autoPlay: false,
              };
            }
          }
          //时间控件
          else if (
            v.type === 2 &&
            v.callType === 1 &&
            v.moduleType === "mapWeather"
          ) {
            if (v.data.startTime && v.data.endTime)
              Object.assign(this.timeSelect, v.data);
          }
        }
      },
      deep: true,
    },
  },
  onLoad() {},
  mounted() {
    // console.log("轨迹时间控件");
  },
  methods: {
    initTimeLine(startTime, d) {
      var e = new Date(startTime);
      if (this.timeline.autoPlay) this.timeline.initHour = e.getHours();
      else this.timeline.initHour = e.getHours();
      var s = this.$timestampTostr(e);
      e.setDate(e.getDate() + d);
      e = this.$timestampTostr(e);
      this.timeline.dateArr = this.$getDateArr(s, e);
      console.log("时间轴集合", this.timeline.dateArr);
    },
    // 时间轴节点触发回调
    timelineNext(v) {
      this.$store.dispatch("mapTime/setSelected", {
        type: 1,
        callType: 2,
        moduleType: "mapWeather",
        data: v
          ? {
              startTime: this.timeSelect.startTime,
              endTime: new Date(v.name.replace(/-/g, "/")).getTime(),
            }
          : this.timeSelect,
      });
    },
    getTimeSelect(data) {
      this.timeSelect = data;
      this.timeLineFlag = false;
      this.timeline = {
        dateArr: [],
        initHour: 0,
        autoPlay: false,
      };
      this.$nextTick(() => {
        this.timeLineFlag = true;
        this.timeline.autoPlay = true;
        this.$nextTick(() => {
          let d = Math.round(
            (this.timeSelect.endTime - this.timeSelect.startTime) /
              1000 /
              60 /
              60 /
              24
          );
          this.initTimeLine(this.timeSelect.startTime, d);
          // //内部调用时,默认执行全时间段
          // this.timelineNext();
        });
      });
    },
    closed() {
      this.$store.dispatch("mapWeather/setSelected", {
        checked: false,
        childType: 1,
      });
      this.timeline = {
        dateArr: [],
        initHour: 0,
        autoPlay: false,
      };
      this.timeLineFlag = false;
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.hlDialog {
  ::v-deep {
    .hl-dialog__header {
      color: #fff;
      background-color: #409eff;
      .hl-dialog__title {
        color: #fff;
      }
    }
  }
}
.timeContainer {
  position: relative;
  width: 100%;
  height: 100%;
  .timeLine {
    width: 100%;
    position: fixed;
    bottom: 2rem;
    z-index: 98;
  }
  .timeSelect {
    position: absolute;
    top: 8rem;
    z-index: 98;
    width: 100%;
    padding: 0.8rem;
    box-sizing: border-box;
  }
}
/deep/ .el-form-item__label {
  color: #fff !important;
}
/deep/ .el-input__inner {
  color: #fff !important;
}
</style>
