export default [

  {
    TYPHOON_YEAR: 2022,

  },
  {
    TYPHOON_YEAR: 2021,

  },
  {
    TYPHOON_YEAR: 2020,

  },
  {
    TYPHOON_YEAR: 2019,

  },
  {
    TYPHOON_YEAR: 2018,

  },
  {
    TYPHOON_YEAR: 2017,

  },
  {
    TYPHOON_YEAR: 2016,

  },
  {
    TYPHOON_YEAR: 2015,

  },
  {
    TYPHOON_YEAR: 2014,

  },
  {
    TYPHOON_YEAR: 2013,

  },
  {
    TYPHOON_YEAR: 2012,

  },
  {
    TYPHOON_YEAR: 2011,

  },
  {
    TYPHOON_YEAR: 2010,

  },

]