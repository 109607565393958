<script>
import Map from "ol/Map";
import View from "ol/View";
import {
  get as getProjection,
  transformExtent as transformExtent,
  transform as transform,
  fromLonLat as fromLonLat,
} from "ol/proj";
import Overlay from "ol/Overlay";
import {
  getCenter,
  getWidth,
  getTopLeft,
  boundingExtent,
  containsExtent,
} from "ol/extent";
import { always, singleClick } from "ol/events/condition";
import { unByKey } from "ol/Observable";
import { getLength } from "ol/sphere";
import { getRenderPixel, toContext, getVectorContext } from "ol/render";
import Feature from "ol/Feature";

import WFS from "ol/format/WFS";
import GeoJSON from "ol/format/GeoJSON";
import TopoJSON from "ol/format/TopoJSON";
import WKT from "ol/format/WKT";
import {
  and as andFilter,
  equalTo as equalToFilter,
  like as likeFilter,
} from "ol/format/filter";

import VectorSource from "ol/source/Vector";
import TileJSONSource from "ol/source/TileJSON";
import XYZSource from "ol/source/XYZ";
import WMTSSource from "ol/source/WMTS";
import Cluster from "ol/source/Cluster";
import TileWMSSource from "ol/source/TileWMS";
import Stamen from "ol/source/Stamen";
import ImageArcGISRestSource from "ol/source/ImageArcGISRest";
import ImageCanvasSource from "ol/source/ImageCanvas";
import ImageStaticSource from "ol/source/ImageStatic";

import WMTSTileGrid from "ol/tilegrid/WMTS";

import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import HeatMapLayer from "ol/layer/Heatmap";
import imageLayer from "ol/layer/Image";

import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Circle from "ol/style/Circle";
import Icon from "ol/style/Icon";

import InteractionSelect from "ol/interaction/Select";
import InteractionDragZoom from "ol/interaction/DragZoom";
import Draw from "ol/interaction/Draw";
import InteractionDragPan from "ol/interaction/DragPan";
import InteractionMouseWheelZoom from 'ol/interaction/MouseWheelZoom';

import Polygon, { fromExtent, fromCircle } from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import MultiPoint from "ol/geom/MultiPoint";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";
import CircleGeom from "ol/geom/Circle";

import { defaults as defaultControls } from "ol/control";
import {defaults as defaultsInteractions} from 'ol/interaction';

import {
  lineString as TLineString,
  point as TPoint,
  lineSlice as TLineSlice,
  polygon as TPolygon,
  multiPolygon as TMultiPolygon,
  booleanEqual as TBooleanEqual,
  multiLineString as TMultiLineString,
  multiPoint as TMultiPoint,
  clone as TClone,
  bezierSpline as TBezierSpline,
  buffer as TBuffer,
  length as TLength,
  booleanCrosses as TBooleanCrosses,
  booleanOverlap as TBooleanOverlap,
  booleanDisjoint as TBooleanDisjoint,
  booleanWithin as TBooleanWithin,
  booleanContains as TBooleanContains,
  booleanPointInPolygon as TBooleanPointInPolygon,
  area as TArea,
  union as Tunion,
} from "@turf/turf/turf";

export default {
  // openlayer地图脚本
  Map,
  View,
  getProjection,
  transformExtent,
  transform,
  fromLonLat,
  getWidth,
  Overlay,
  getCenter,
  getTopLeft,
  boundingExtent,
  containsExtent,
  always,
  singleClick,
  unByKey,
  getLength,
  getRenderPixel,
  toContext,
  getVectorContext,
  Feature,

  WFS,
  GeoJSON,
  TopoJSON,
  WKT,
  andFilter,
  equalToFilter,
  likeFilter,

  VectorSource,
  TileJSONSource,
  XYZSource,
  WMTSSource,
  Cluster,
  TileWMSSource,
  Stamen,
  ImageArcGISRestSource,
  ImageCanvasSource,
  ImageStaticSource,

  WMTSTileGrid,

  TileLayer,
  VectorLayer,
  HeatMapLayer,
  imageLayer,

  Style,
  Stroke,
  Text,
  Fill,
  Circle,
  Icon,

  InteractionSelect,
  InteractionDragZoom,
  Draw,
  InteractionDragPan,
  InteractionMouseWheelZoom,

  Polygon,
  fromExtent,
  fromCircle,
  LineString,
  Point,
  MultiPoint,
  MultiLineString,
  MultiPolygon,
  CircleGeom,
  defaultControls,
  defaultsInteractions,

  TLineString,
  TPoint,
  TLineSlice,
  TPolygon,
  TMultiPolygon,
  TBooleanEqual,
  TMultiLineString,
  TMultiPoint,
  TClone,
  TBezierSpline,
  TBuffer,
  TLength,
  TBooleanCrosses,
  TBooleanOverlap,
  TBooleanDisjoint,
  TBooleanWithin,
  TBooleanContains,
  TBooleanPointInPolygon,
  TArea,
  Tunion,
  // kriging
};
</script>
