import request from "@/utils/request";

export function getForecastData(data) {
  return request({
    url: "/api/forecast/getForecastData.json",
    method: "post",
    data,
  });
}
export function getStations(data) {
  return request({
    url: "/api/geographicInformation/getDataListAll.json",
    method: "post",
    data,
  });
}
export function distanceCalculate(data) {
  return request({
    url: "/api/geographicInformation/distanceCalculate.json",
    method: "post",
    data,
  });
}
