import GridAngle from './js/gridAngle.js'
const seaWeather = function (options) {
  let mapApi = options.mapApi
  let mapTar = options.map
  let builder;
  let grid;
  let gridData = options.data;
  let λ0, φ0, Δλ, Δφ, ni, nj, maxLon, minLat;
  let gridValueTar = null
  let minValue = options.minValue ? options.minValue : 0.1
  let maxValue = options.maxValue ? options.maxValue : 30


  let bilinearInterpolateVector = function bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
    var rx = 1 - x;
    var ry = 1 - y;
    var a = rx * ry,
      b = x * ry,
      c = rx * y,
      d = x * y;
    var u = g00[0] * a + g10[0] * b + g01[0] * c + g11[0] * d;
    var v = g00[1] * a + g10[1] * b + g01[1] * c + g11[1] * d;
    return [u, v, Math.sqrt(u * u + v * v)];
  };
  let createWeatherBuilder = function createWeatherBuilder(uComp, vComp) {
    let uData = uComp.data,
      vData = vComp.data;
    return {
      header: uComp.header,
      data: function data(i) {
        return [uData[i], vData[i]];
      },
      bilinearInterpolateVector: bilinearInterpolateVector
    };
  };
  var createBuilder = function createBuilder(data) {
    var uComp = null,
      vComp = null;
    data.forEach(function (record) {
      if (record.header.type) {
        switch (record.header.type) {
          case "windU":
            uComp = record;
            break;
          case "windV":
            vComp = record;
            break;

          default:

        }
      }
      else {
        uComp = vComp = record;
      }
    });
    return createWeatherBuilder(uComp, vComp);
  }
  let buildGrid = function buildGrid(data, callback) {
    builder = createBuilder(data);
    let header = builder.header;
    λ0 = header.lo1;
    φ0 = Math.max(header.la2, header.la1);
    Δλ = header.dx;
    Δφ = header.dy;
    ni = header.nx;
    nj = header.ny;
    maxLon = header.maxLon;
    minLat = header.minLat;
    grid = [];
    let p = 0;
    let isContinuous = Math.floor(ni * Δλ) >= 360;

    for (let j = 0; j < nj; j++) {
      let row = [];
      for (let i = 0; i < ni; i++, p++) {
        row[i] = builder.data(p);
      }
      if (isContinuous) {
        row.push(row[0]);
      }

      grid[j] = row;
    }

    callback({
      interpolate: interpolate,
      minValue: minValue,
      maxValue: maxValue
    });
  };

  let interpolate = function interpolate(λ, φ) {
    if (!grid) return null;
    if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat) {
      return -1;
    }
    let i = (λ - λ0) / Δλ;
    let j = (φ0 - φ) / Δφ;
    let fi = Math.floor(i),
      ci = fi + 1;
    let fj = Math.floor(j),
      cj = fj + 1;
    let row = grid[fj];

    if (row) {
      let g00 = row[fi];
      let g10 = row[ci];
      if (ci === row.length)
        g10 = row[row.length - 1];
      row = grid[cj];
      if (cj === grid.length) row = grid[grid.length - 1]
      if (isValue(g00) && isValue(g10)) {
        let g01 = row[fi];
        let g11 = row[ci];
        if (ci === row.length) g11 = row[row.length - 1];

        if (isValue(g01) && isValue(g11)) {
          return builder.bilinearInterpolateVector(i - fi, j - fj, g00, g10, g01, g11);
        }
      }
    }
    return null;
  };

  let isValue = function isValue(x) {
    return x !== null && x !== undefined;
  };
  let start = function start() {
    buildGrid(gridData, function (grid) {
      addGridValue(grid)
    });
  };
  let addGridValue = function addGridValue(grid) {
    gridValueTar = new GridAngle({
      map: mapTar,
      mapApi: mapApi,
      gridValue: grid,
      extent: [λ0, minLat, maxLon, φ0],
    }).addGrid()
  }
  start()
  var fun = () => {

  }
  fun.prototype.clearWeather = function () {
    gridValueTar.removeGrid()
  }
  return new fun();
}
const SeaWeather = function (options) {
  return new seaWeather(options)
}
export default SeaWeather

