<template>
  <div class="timerContainer">
    <div class="timebar">
      <span v-if="pageOption.prev" class="prevPage" @click="prevPage">
        <i class="el-icon-arrow-left"
      /></span>
      <span v-if="pageOption.next" class="nextPage" @click="nextPage">
        <i class="el-icon-arrow-right"
      /></span>
      <div ref="progress" class="progress">
        <span
          ref="timeShowBlock"
          class="timeShowBlock"
          @mousedown="DragTimeBlock($event)"
        ></span>
        <div ref="prgressfill" class="prgressfill" />
        <div ref="dateTimeBlock" class="dateTimeBlock">
          <div
            v-for="(item, index) in dateArr"
            :key="index"
            ref="dateTimeBlockChild"
            class="dateTimeBlockChild"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <span class="playerBtn" @click="playerClicked"
        ><i :class="playing ? 'el-icon-video-pause' : 'el-icon-video-play'" />{{
          playing ? "暂停" : "播放"
        }}</span
      >
      <span class="playerBtn" @click="playerReset"
        ><i class="el-icon-refresh" />刷新</span
      >
    </div>
    <div class="time_container">
      <div style="display: flex; align-items: center">
        当前时间：<span>{{ timeShowBlock }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    dateArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    ispage: {
      type: Boolean,
      default: false,
    },
    // 自动播放
    autoPlay: {
      type: Boolean,
      default: true,
    },
    // 显示到小时
    showHour: {
      type: Boolean,
      default: false,
    },
    // 初始时间
    initHour: {
      type: Number,
      default: 0,
    },
    // 每次变化间隔时间，单位s
    perSecond: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      timeShowBlock: "",
      time24: 0,
      timeValue: 0,
      dateWidth: 0,
      dateindex: 0,
      compareTime: "",
      dateArrPage: [],
      dateArrPageIndex: 0,
      // dateArr: ['2020-07-05', '2020-07-06', '2020-07-07'],
      playing: false,
      dx: "",
      mx: "",
      drapis: false,
      intervalCom: null,
      timeLine: {},
      pageOption: {
        prev: false,
        next: false,
        pasize: 15,
      },
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      // 启动分页
      if (this.ispage) {
        this.pageStart();
      }

      this.$nextTick(() => {
        this.initialTime = new Date(
          this.dateArr[0] + " " + this.initHour + ":00"
        ).getTime();
      });

      this.DateTimeBlockInit();

      setTimeout(() => {
        // this.$refs.timeShowBlock.style.left =
        //   this.startPosition() + this.time24 * this.initHour + "px";
        // this.$refs.prgressfill.style.width = this.time24 * this.initHour + "px";
        this.$refs.timeShowBlock.style.left = this.startPosition() + "px";
        this.$refs.prgressfill.style.width = 0 + "px";
        this.RequestCom();
        if (this.autoPlay) {
          this.playerClicked();
        }
      }, 200);
    });
  },
  created() {},
  activated() {},
  deactivated() {},
  destroyed() {
    if (this.intervalCom) {
      clearInterval(this.intervalCom);
      this.intervalCom = null;
    }
  },
  methods: {
    // 将时间块进行分组用于分页
    pageStart() {
      this.pageOption.prev = true;
      this.pageOption.next = true;
      for (var i = 0; i < this.dateArr.length; i += this.pageOption.pasize) {
        this.dateArrPage.push(
          this.dateArr.slice(i, i + this.pageOption.pasize)
        );
      }
      this.dateArr = this.dateArrPage[this.dateArrPageIndex];
    },
    timelineReset() {
      this.dateArr = this.dateArrPage[this.dateArrPageIndex];
      this.DateTimeBlockInit();
      this.$refs.timeShowBlock.style.left = 0 + "px";
      this.$refs.prgressfill.style.width = 0 + "px";
    },
    prevPage() {
      if (this.dateArrPageIndex === 0) {
        return false;
      }
      this.dateArrPageIndex--;
      this.timelineReset();
    },
    nextPage() {
      if (this.dateArrPageIndex === this.dateArrPage.length - 1) {
        return false;
      }
      this.dateArrPageIndex++;
      this.timelineReset();
    },
    // 播放按钮绑定事件
    playerClicked() {
      this.playing = !this.playing;
      this.playing ? this.startPlayTime() : this.stopPlayTime();
    },
    playerReset() {
      this.$store.dispatch("mapRoute/fitExtent");
    },
    RequestAndSetValue(v) {
      var datestr = this.dateArr[this.dateindex % this.dateArr.length];
      if (this.showHour) {
        this.timeShowBlock =
          (datestr === undefined ? this.dateArr[this.dateindex - 1] : datestr) +
          " " +
          v;

        // if (this.compareTime !== v) {
        //   // console.log('触发后台', this.timeShowBlock)
        //   this.$emit("timeToDo", { name: this.timeShowBlock });
        // }
        if (
          (this.compareTime !== v && this.autoPlay) ||
          (this.compareTime !== v && this.compareTime && !this.autoPlay)
        ) {
          // console.log('触发后台', this.timeShowBlock)
          if (new Date(this.timeShowBlock) >= this.initialTime)
            this.$emit("timeToDo", { name: this.timeShowBlock });
        }
      } else {
        this.timeShowBlock =
          datestr === undefined ? this.dateArr[this.dateindex - 1] : datestr;

        if (this.compareTime !== v && v === "0:00") {
          // console.log('触发后台', this.timeShowBlock)
          if (new Date(this.timeShowBlock) >= this.initialTime)
            this.$emit("timeToDo", { name: this.timeShowBlock });
        }
      }
      this.compareTime = v;
    },
    MapClear() {
      console.log("清空图层");
      this.stopPlayTime();
    },
    RequestCom() {
      // 通过计算展示24小时
      this.dateindex = parseInt(
        this.$refs.prgressfill.clientWidth / this.dateWidth
      );
      var fillwidth =
        this.$refs.prgressfill.clientWidth - this.dateWidth * this.dateindex;
      this.timeValue = parseInt(fillwidth / this.time24);
      
      this.timeValue = this.timeValue + this.initHour + 1;
      if (this.timeValue >= 24) this.timeValue = this.timeValue - 24;
      if (this.timeValue >= 0 && this.timeValue <= this.initHour)
        this.dateindex += 1;
      this.RequestAndSetValue(this.timeValue + ":00");
    },
    startPosition() {
      return this.$refs.progress.offsetLeft;
    },
    endPosition() {
      return this.$refs.progress.offsetLeft + this.$refs.progress.clientWidth;
    },
    StopBarBeyond() {
      if (
        Number(this.$refs.timeShowBlock.style.left.replace("px", "")) >
          this.endPosition() ||
        this.$refs.prgressfill.clientWidth >= this.$refs.progress.clientWidth
      ) {
        if (this.repeat) {
          this.$refs.timeShowBlock.style.left = this.startPosition() + "px";
          this.$refs.prgressfill.style.width = "0";
        } else {
          this.drapis = false;
          this.stopPlayTime();
          this.$refs.timeShowBlock.style.left = this.endPosition() + "px";
          this.$refs.prgressfill.style.width =
            this.$refs.progress.clientWidth + "px";
        }
      }
      if (
        Number(this.$refs.timeShowBlock.style.left.replace("px", "")) <
          this.startPosition() ||
        this.$refs.prgressfill.clientWidth === 0
      ) {
        this.$refs.timeShowBlock.style.left = this.startPosition() + "px";
        this.$refs.prgressfill.style.width = "0";
        this.drapis = false;
      }
    },
    playTimeProgress(addparm) {
      this.$refs.timeShowBlock.style.left =
        Number(this.$refs.timeShowBlock.style.left.replace("px", "")) +
        addparm +
        "px";
      this.$refs.prgressfill.style.width =
        Number(this.$refs.prgressfill.style.width.replace("px", "")) +
        addparm +
        "px";
      this.RequestCom();
      // 解决当鼠标快速移动时，会溢出的bug
      this.StopBarBeyond();
    },
    startPlayTime() {
      var interval = this.showHour
        ? (1000 * this.perSecond) / this.time24
        : (1000 * this.perSecond) / 24 / this.time24;
      this.intervalCom = setInterval(() => {
        this.playTimeProgress(0.75);
      }, interval / 2);
    },
    stopPlayTime() {
      this.playing = false;
      clearInterval(this.intervalCom);
    },
    DragTimeBlock(e) {
      // 清空图层
      this.MapClear();
      e = e || window.event;
      this.mx = e.pageX; // 点击时鼠标X坐标
      this.dx = this.$refs.timeShowBlock.offsetLeft;
      this.drapis = true;
      document.onmousemove = (e) => {
        e = e || window.event;
        var x = e.pageX; // 移动时鼠标X坐标
        var moveX = this.dx + x - this.mx + 15; // 移动后对话框新的left值
        if (this.drapis) {
          this.$refs.timeShowBlock.style.left = moveX + "px";
          this.$refs.prgressfill.style.width =
            moveX - this.$refs.prgressfill.offsetLeft + "px";
          // 解决当鼠标快速移动时，会溢出的bug
          this.StopBarBeyond();
          this.RequestCom();
        }
      };
      document.onmouseup = () => {
        // 第二时间块，拖拽到第一时间块时，距离判断到不了0:00不触发第一时间块到请求
        // 加上鼠标离开后可以规避这一问题
        this.$emit("timeToDo", { name: this.timeShowBlock });
        this.drapis = false;
        document.onmouseup = null;
        this.stopPlayTime();
      };
    },
    DateTimeBlockInit() {
      this.$nextTick(() => {
        var widthc = this.$refs.progress.clientWidth / (this.dateArr.length - 1) - 1;
        // this.$refs.dateTimeBlockChild.forEach((child) => {
        //   child.style.width = widthc + "px";
        // });
        this.dateWidth = widthc;
        this.time24 = widthc / 24;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.timerContainer {
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
  color: white;

  .buttons {
    .playerBtn {
      color: #fff;
      font-size: 1rem;
      margin: 0 0.5rem 0 0;
      background-color: rgba(64, 158, 255, 0.9);
      border-radius: 4px;
      cursor: pointer;
      padding: 0.2rem;
    }
  }

  .time_container {
    width: 100%;
    height: 10rem;
    border: 1px solid #e1e1e1;
    margin-top: 1rem;
    padding: 0.8rem;
    box-sizing: border-box;
    color: white;

    span {
      flex: 1;
      background: rgba(64, 158, 255, 0.2);
      border-radius: 4px;
      padding: 0.5rem 0.8rem;
    }
  }
}

.prevPage,
.nextPage {
  cursor: pointer;
  font-size: 0.3rem;
  color: #fff;
}

.prevPage {
  position: absolute;
  left: 0.7rem;
}

.nextPage {
  position: absolute;
  right: 0.7rem;
}

.timebar {
  font-size: 0.16rem;
  width: 100%;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .progress {
    width: 100%;
    height: 1rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    box-shadow: 0 0 0.03rem rgba(0, 0, 0, 0.3);
  }

  .prgressfill {
    width: 0px;
    background: #ed9d02;
    height: 100%;
    border-radius: 1rem;
  }

  .timeShowBlock {
    position: absolute;
    top: 0.5rem;
    background: #f6a512;
    color: #fff;
    padding: 0.2rem 0.3rem;
    margin-left: -0.15rem;
    height: 1rem;
    border-radius: 0.03rem;
    line-height: 1rem;
    font-size: 1rem;
    user-select: none;
    cursor: -webkit-grab;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

    &::before {
      content: "";
      position: absolute;
      border: 0.05rem solid transparent;
      border-top-color: #f6a512;
      bottom: -0.1rem;
      margin-left: 0.06rem;
    }
  }

  .dateTimeBlock {
    display: none;
    width: 100%;
  }

  .dateTimeBlockChild {
    float: left;
    text-align: center;
    border-right: 0.02rem solid rgba(111, 111, 111, 0.8);
    font-size: 1rem;
    font-weight: 400;
    color: white;
    cursor: pointer;
    text-shadow: black 0 0 0.03rem, black 0 0 0.03rem;
    line-height: 2.5rem;
    user-select: none;

    &:last-child {
      border-right: 0;
    }
  }
}
</style>
