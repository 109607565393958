// import clip from "./clip.js";
class Grid {
  constructor(props) {
    //这里初始化class的时候需要传map对象进来
    this.map = props.map
    this.id = props.id ? props.id : '_grid'
    this.mapApi = props.mapApi
    this.disX = props.disX ? props.disX : 100
    this.disY = props.disY ? props.disY : 100
    // this.extent = props.extent ? props.extent : [115.94182490303399, 33.846405515799184, 124.16724989874709, 38.846405515799184]
    this.extent = props.extent ? props.extent : null
    // this.gridLayer = null
    this.grid = null
    //样式        
    this.style = {
      fill: new this.mapApi.Fill({
        // color: 'rgba(255, 255, 0, 0.2)'
        color: "rgba(255,255,255,0)",
      }),
      stroke: new this.mapApi.Stroke({
        color: "#21F3CC",
        width: 3
      }),
      image: new this.mapApi.Circle({
        radius: 7,
        fill: new this.mapApi.Fill({
          color: 'red'
        })
      }),
      text: new this.mapApi.Text({
        // text: pos.toString(),
        text: '',
        fill: new this.mapApi.Fill({
          color: '#000000'
        }),
        textAlign: "center",
        offsetX: 0,
        textBaseline: "middle"
      }),
      imageIcon: new this.mapApi.Icon({
        // anchor: [0.5, 0.5],
        src: 'a.png',
        scale: 1,
        // rotation: this.getRadius(data.angle)
        // anchorYUnits: 'pixels'
      })
    }
    this.gridStyle = new this.mapApi.Style({
      fill: this.style.fill,
      stroke: this.style.stroke,
      image: this.style.image,
      // text: this.style.text
    })
    this.ids = [this.id + '_30', this.id + '_10', this.id + '30_noText']
    this.init()
    this.map.getView().fit(this.getExtent().expand(0.0000001).extent)
  }
  init() {
    //新建临时图层，并设置临时图层渲染各种要素的样式
    let gridLayer30 = new this.mapApi.VectorLayer({
      id: this.ids[0],
      source: new this.mapApi.VectorSource(),
      style: this.gridStyle
    });
    let gridLayer10 = new this.mapApi.VectorLayer({
      id: this.ids[1],
      source: new this.mapApi.VectorSource(),
      style: this.gridStyle
    });
    let gridLayer30NoText = new this.mapApi.VectorLayer({
      id: this.ids[2],
      source: new this.mapApi.VectorSource(),
      style: this.gridStyle
    });
    this.insertAtLayer(999, gridLayer30)
    this.insertAtLayer(999, gridLayer10)
    this.insertAtLayer(999, gridLayer30NoText)
    this.getLayer(this.ids[0], 1).setVisible(false);
    this.getLayer(this.ids[1], 1).setVisible(false);
    this.getLayer(this.ids[2], 1).setVisible(false);
  }
  insertAtLayer(index, layer) {
    let layersArray = this.map.getLayers();
    layersArray.insertAt(index, layer);
  }
  addLayer() {
    this.removeGrid()
    this.init()
  }
  removeLayer(layerId, isFullLayer) {
    let array = this.map.getLayers().array_.map((v) => {
      return v;
    });
    array.forEach((layer) => {
      if (isFullLayer) {
        if (layer && layer.get("id") && layer.get("id") === layerId) {
          if (layer.getSource) layer.getSource().clear();
          this.map.removeLayer(layer);
        }
      } else {
        if (
          layer &&
          layer.get("id") &&
          layer.get("id").indexOf(layerId) > -1
        ) {
          if (layer.getSource) layer.getSource().clear();
          this.map.removeLayer(layer);
        }
      }
    });
  }
  getLayer(layerId, isFullLayer) {
    let layer = null;
    this.map.getLayers().array_.map((v) => {
      if (isFullLayer) {
        if (v && v.get("id") && v.get("id") === layerId) {
          layer = v;
        }
      } else {
        if (v && v.get("id") && v.get("id").indexOf(layerId) > -1) {
          layer = v;
        }
      }
    });
    return layer;
  }
  setText(text) {
    this.style.text.text_ = text
  }
  removeGrid() {
    this.removeLayer(this.id)
    if (this.grid30Evt) this.map.un('moveend', this.grid30Evt)
  }
  addGrid() {
    let data, features30;
    let data10, features10;
    this.map
      .getView()
      .fit([119.120916, 34.672952, 120.045458, 35.430688])
    data = window.json1_map
    features30 = new this.mapApi.GeoJSON().readFeatures(data)
    data10 = window.json2_map
    features10 = new this.mapApi.GeoJSON().readFeatures(data10)
    this.addGridData(features30, features10)
    return this
  }
  addGridData(features30, features10) {
    let preZoom, mapZoom;
    const showGridType = (features, type, textScale) => {
      let stroke = { color: 'orange', width: 0.5, lineDash: [1] }
      if (type === 2) {
        features[0].map(v => {
          let fea = new this.mapApi.Feature({
            geometry: v.getGeometry()
          })
          fea.setStyle(
            new this.mapApi.Style({
              stroke: new this.mapApi.Stroke({
                color: stroke.color,
                width: stroke.width,
                lineDash: stroke.lineDash,
              })
            })
          );
          features[1].push(fea)
        })
      } else {
        features[0].map(v => {
          v.setStyle(
            new this.mapApi.Style({
              stroke: new this.mapApi.Stroke({
                color: stroke.color,
                width: stroke.width,
                lineDash: stroke.lineDash,
              }),
              text: new this.mapApi.Text({
                font: "12px",
                rotation: 0,
                textAlign: "center",
                placement: "point",
                maxAngle: Math.PI / 4,
                overflow: true,
                scale: textScale,
                text: v.get('TYPE'),
                fill: new this.mapApi.Fill({ color: "green" }),
                stroke: new this.mapApi.Stroke({
                  // 线样式
                  color: "#fff",
                  width: 1,
                }),
              }),
            })
          );
        })
      }
    }
    const polygon9 = () => {
      if (preZoom === this.map.getView().getZoom()) return
      mapZoom = this.map.getView().getZoom()
      if (!preZoom) preZoom = mapZoom
      if (mapZoom <= 6) {
        this.getLayer(this.ids[0], 1).setVisible(false);
        this.getLayer(this.ids[1], 1).setVisible(false);
        this.getLayer(this.ids[2], 1).setVisible(true);
      } else if (mapZoom <= 10 && (preZoom === mapZoom || preZoom <= 6 || preZoom > 10)) {
        this.getLayer(this.ids[0], 1).setVisible(true);
        this.getLayer(this.ids[1], 1).setVisible(false);
        this.getLayer(this.ids[2], 1).setVisible(false);
      } else if (mapZoom > 10 && (preZoom === mapZoom || preZoom <= 10)) {
        this.getLayer(this.ids[0], 1).setVisible(false);
        this.getLayer(this.ids[1], 1).setVisible(true);
        this.getLayer(this.ids[2], 1).setVisible(false);
      }
      preZoom = mapZoom
    }

    let featuresNoText = [];
    showGridType([features30], 1, 1.2)
    showGridType([features10], 1, 2)
    showGridType([features30, featuresNoText], 2)
    this.getLayer(this.ids[0], 1).getSource().addFeatures(features30);
    this.getLayer(this.ids[1], 1).getSource().addFeatures(features10);
    this.getLayer(this.ids[2], 1).getSource().addFeatures(featuresNoText);
    // console.log(this.getLayer(this.ids[0], 1).getSource().getFeatures().length)
    // console.log(this.getLayer(this.ids[1], 1).getSource().getFeatures().length)
    // console.log(this.getLayer(this.ids[2], 1).getSource().getFeatures().length)

    this.grid30Evt = () => { polygon9() }
    //默认执行添加格网
    polygon9()
    //地图事件控制添加格网
    this.map.on('moveend', this.grid30Evt)
  }
  getExtent(ex) {
    let tar = {}
    const extent = () => {
      ex = ex ? ex : this.map.getView().calculateExtent()
      tar.extent = ex
      return tar
    }
    const expand = (value) => {
      ex[0] += value
      ex[1] += value
      ex[2] -= value
      ex[3] -= value
      tar.extent = ex
      return tar
    }
    const getGeom = () => {
      var _viewGeom = new this.mapApi.fromExtent(ex);
      tar.geom = _viewGeom
      return tar
    }
    tar.expand = expand
    tar.getGeom = getGeom
    extent()
    return tar
  }
}
export default Grid