class Grid {
    constructor(props) {
        //这里初始化class的时候需要传map对象进来
        this.map = props.map
        this.id = props.id ? props.id : '_gridValue'
        this.mapApi = props.mapApi
        this.disX = props.disX ? props.disX : 100
        this.disY = props.disY ? props.disY : 100
        this.minValue = props.minValue
        this.maxValue = props.maxValue
        this.extent = props.extent ? props.extent : [115.94182490303399, 33.846405515799184, 124.16724989874709, 38.846405515799184]
        this.gridLayer = null
        this.grid = null
        this.gridSource = null
        this.gridValue = props.gridValue
        this.moveend = null
        this.movestart = null
        // console.log(this)

        //样式        
        this.style = {
            fill: new this.mapApi.Fill({
                color: 'rgba(255, 255, 0, 0.2)'
            }),
            stroke: new this.mapApi.Stroke({
                color: 'black',
                width: 1
            }),
            image: new this.mapApi.Circle({
                radius: 7,
                fill: new this.mapApi.Fill({
                    color: 'red'
                })
            }),
            text: new this.mapApi.Text({
                // text: pos.toString(),
                text: '',
                fill: new this.mapApi.Fill({
                    color: '#000000'
                }),
                textAlign: "center",
                offsetX: 0,
                textBaseline: "middle"
            }),
            imageIcon: new this.mapApi.Icon({
                // anchor: [0.5, 0.5],
                src: 'a.png',
                scale: 1,
                // rotation: this.getRadius(data.angle)
                // anchorYUnits: 'pixels'
            })
        }
        this.gridStyle = new this.mapApi.Style({
            fill: this.style.fill,
            stroke: this.style.stroke,
            image: this.style.image,
            // text: this.style.text
        })
        this.init()
    }
    init() {
        this.removeGrid()
        //临时图层的数据源
        this.gridSource = new this.mapApi.VectorSource();
        //新建临时图层，并设置临时图层渲染各种要素的样式
        this.gridLayer = new this.mapApi.VectorLayer({
            id: this.id,
            source: this.gridSource,
            style: this.gridStyle
        });
        // this.map.addLayer(this.gridLayer)
        return this
    }
    setText(text) {
        this.style.text.text_ = text
        return this
    }
    addGrid() {
        var fun = () => {
            this.clearFeatures()
            this.createGrid()
            this.addCenterPoint()
            // this.addGridLine()
            // this.addExtent()
        }
        var fun1 = () => {
            this.clearFeatures()
        }
        fun1()
        fun()
        this.moveend = this.map.on("moveend", () => {
            fun()
        })
        this.movestart = this.map.on("movestart", () => {
            fun1()
        })
        return this
    }
    insertAtLayer(map, index) {
        let layersArray = map.getLayers();
        layersArray.insertAt(index, this.gridLayer);
        return this
    }
    removeGrid() {
        let layer = this.getLayer(this.id)
        if (layer) {
            this.map.removeLayer(layer)
            this.gridLayer = null
            this.clearFeatures()
            this.mapApi.unByKey(this.moveend);
            this.mapApi.unByKey(this.movestart);
        }
        return this
    }
    getLayer(layerId, isFullLayer) {
        let layer = null;
        if (isFullLayer) {
            this.map.getLayers().array_.map((v) => {
                if (v && v.get("id") && v.get("id") === layerId) {
                    layer = v;
                }
            });
        } else {
            this.map.getLayers().array_.map((v) => {
                if (v && v.get("id") && v.get("id").indexOf(layerId) > -1) {
                    layer = v;
                }
            });
        }
        return layer;
    }
    getExtent(ex) {
        let tar = {}
        const extent = () => {
            ex = ex ? ex : this.map.getView().calculateExtent()
            tar.extent = ex
            return tar
        }
        const expand = (value) => {
            ex[0] += value
            ex[1] += value
            ex[2] -= value
            ex[3] -= value
            tar.extent = ex
            return tar
        }
        const getGeom = () => {
            var _viewGeom = new this.mapApi.fromExtent(ex);
            tar.geom = _viewGeom
            return tar
        }
        tar.expand = expand
        tar.getGeom = getGeom
        extent()
        return tar
    }
    clearFeatures() {
        if (this.gridSource) this.gridSource.clear()
        return this
    }
    compareExtent(extent) {
        let mapExtent = [], returnExtent = []
        mapExtent = this.getExtent().extent
        extent[0] < mapExtent[0] ? returnExtent[0] = mapExtent[0] : returnExtent[0] = extent[0]
        extent[1] < mapExtent[1] ? returnExtent[1] = mapExtent[1] : returnExtent[1] = extent[1]
        extent[2] > mapExtent[2] ? returnExtent[2] = mapExtent[2] : returnExtent[2] = extent[2]
        extent[3] > mapExtent[3] ? returnExtent[3] = mapExtent[3] : returnExtent[3] = extent[3]
        // console.log('mapExtent:', mapExtent)
        // console.log('returnExtent:', returnExtent)
        return returnExtent
    }
    createGrid() {
        let extent = null
        if (this.extent) extent = this.compareExtent(this.getExtent(this.extent).extent)
        else {
            extent = this.getExtent(this.extent).extent
        }
        const disX = this.disX
        const disY = this.disY
        const pix1 = this.map.getPixelFromCoordinate([extent[0], extent[1]])
        const pix2 = this.map.getPixelFromCoordinate([extent[2], extent[3]])
        // console.log(pix1, pix2)
        // const pos1 = this.map.getCoordinateFromPixel(pix1)
        // const pos2 = this.map.getCoordinateFromPixel(pix2)
        // console.log(pos1, pos2)
        let xCount = parseInt((pix2[0] - pix1[0]) / disX)
        let yCount = Math.abs(parseInt((pix2[1] - pix1[1]) / disY))
        const xd = (pix2[0] - pix1[0]) % disX
        const yd = (pix2[1] - pix1[1]) % disY
        // let startX = pix1[0] + xd / 2
        // let startY = pix1[1] + yd / 2
        let startX = pix1[0] + xd / 2 - disX
        let startY = pix1[1] + yd / 2 + disY
        xCount = xCount + 2
        yCount = yCount + 2
        // yCount += 1
        // console.log([startX, startY])
        let grid = {
            nodePixelXY: [],
            nodePosXY: [],
            centerPixelXY: [],
            centerPosXY: [],
            nodePixelYX: [],
            nodePosYX: [],
            centerPixelYX: [],
            centerPosYX: [],
            count: 0
        }
        const that = this
        const getXYGrid = function () {
            for (let i = 0, x = 0, y = 0, centerX = 0, centerY = 0, nodePosXY = 0, centerPosXY = 0; i <= xCount; i++) {
                grid.nodePixelXY[i] = []
                grid.nodePosXY[i] = []
                grid.centerPixelXY[i] = []
                grid.centerPosXY[i] = []
                x = startX + disX * i
                centerX = x + disX / 2
                for (let j = 0; j <= yCount; j++) {
                    y = startY - disY * j
                    centerY = y - disY / 2
                    nodePosXY = that.map.getCoordinateFromPixel([x, y])
                    centerPosXY = that.map.getCoordinateFromPixel([centerX, centerY])
                    grid.nodePixelXY[i][j] = [x, y]
                    grid.centerPixelXY[i][j] = [centerX, centerY]
                    grid.nodePosXY[i][j] = nodePosXY
                    grid.centerPosXY[i][j] = centerPosXY
                    grid.count++
                }
            }
        }
        const getYXGrid = function () {
            for (let i = 0, x = 0, y = 0, centerX = 0, centerY = 0, nodePosYX = 0, centerPosYX = 0; i <= yCount; i++) {
                grid.nodePixelYX[i] = []
                grid.nodePosYX[i] = []
                grid.centerPixelYX[i] = []
                grid.centerPosYX[i] = []
                y = startY - disY * i
                centerY = y - disY / 2
                for (let j = 0; j <= xCount; j++) {
                    x = startX + disX * j
                    centerX = x + disX / 2
                    nodePosYX = that.map.getCoordinateFromPixel([x, y])
                    centerPosYX = that.map.getCoordinateFromPixel([centerX, centerY])
                    grid.nodePixelYX[i][j] = [x, y]
                    grid.centerPixelYX[i][j] = [centerX, centerY]
                    grid.nodePosYX[i][j] = nodePosYX
                    grid.centerPosYX[i][j] = centerPosYX
                }
            }
        }
        getXYGrid()
        getYXGrid()
        this.grid = grid
        return this
    }

    addCenterPoint() {
        let isValue = function isValue(x) {
            return x !== null && x !== undefined;
        };
        const grid = this.grid
        const addPoint = (pos) => {
            let fea = new this.mapApi.Feature({
                geometry: new this.mapApi.Point(pos)
            })
            // let posS = pos.toString().split(',')
            // let text = Number(posS[0]).toFixed(6) + '\n' + Number(posS[1]).toFixed(6)
            let text = this.gridValue.interpolate(pos[0], pos[1]);
            if (isValue(text) && !isNaN(text) && text >= this.minValue && text <= this.maxValue) {
                // this.setText(text.toFixed(1).toString())
                fea.setStyle(new this.mapApi.Style({
                    text: new this.mapApi.Text({
                        text: text.toFixed(1).toString(),
                        fill: new this.mapApi.Fill({
                            color: 'red'
                        }),
                        textAlign: "center",
                        offsetX: 0,
                        textBaseline: "middle"
                    }),
                }))
                this.gridSource.addFeature(fea)
            }
        }
        // const geom = this.getExtent(this.extent).getGeom().geom
        for (let m = 1; m < grid.centerPosXY.length - 2; m++) { //纵向超过范围的第一条（m = 0）跟最后一条(m = grid.centerPosXY.length - 2)过滤掉
            for (let n = 1, centerPosXY = 0; n < grid.centerPosXY[m].length - 2; n++) { //横向超过范围的第一条（n = 0）跟最后一条(n = grid.centerPosXY.length - 2)过滤掉
                centerPosXY = grid.centerPosXY[m][n];
                addPoint(centerPosXY)
            }
        }
        return this
    }
    addGridLine() {
        const grid = this.grid
        const addLine = (nodePos, nodePosNext) => {
            const line = new this.mapApi.LineString([nodePos, nodePosNext]);
            let _feature = new this.mapApi.Feature({
                geometry: line,
            });
            this.gridSource.addFeature(_feature)
        }
        const addGridXY = function () {
            for (let m = 1; m < grid.nodePosXY.length - 1; m++) { //纵向超过范围的第一条（m = 0）跟最后一条(m = grid.nodePosXY.length - 1)过滤掉
                for (let n = 0, nodePosXY = 0, nodePosNext = 0; n < grid.nodePosXY[m].length; n++) {
                    nodePosXY = grid.nodePosXY[m][n];
                    nodePosNext = grid.nodePosXY[m][n + 1];
                    if (nodePosNext) addLine(nodePosXY, nodePosNext)
                }
            }
        }
        const addGridYX = function () {
            for (let m = 1; m < grid.nodePosYX.length - 1; m++) { //横向超过范围的第一条（m = 0）跟最后一条(m = grid.nodePosYX.length - 1)过滤掉
                for (let n = 0, nodePosYX = 0, nodePosNext = 0; n < grid.nodePosYX[m].length; n++) {
                    nodePosYX = grid.nodePosYX[m][n];
                    nodePosNext = grid.nodePosYX[m][n + 1];
                    if (nodePosNext) addLine(nodePosYX, nodePosNext)
                }
            }
        }
        addGridXY()
        addGridYX()
        return this
    }
    addExtent() {
        const geom = this.getExtent(this.extent).getGeom().geom
        const fea = new this.mapApi.Feature({
            geometry: geom
        })
        this.gridSource.addFeature(fea)
        return this
    }
}
export default Grid
