<template>
  <div class="mapRouteContainer">
    <map-base ref="mapCom" :map="map" />
    <map-time ref="mapTime" :map="map" />
  </div>
</template>
<script>
// <script module = 'mapRouteContainer' lang="renderjs">
import "./map-route.css";
export default {
  name: "mapRoute",
  props: ["map"],
  components: {},
  watch: {
    "mapRoute.selected": {
      async handler(v) {
        if (v) {
          // 先清空所有轨迹
          v = JSON.parse(JSON.stringify(v));
          v.uniqueId = this.uniqueId;
          v.pageType = this.pageType;
          this.clearShipRouteRelevantAll(v);

          this.requsetParam[v.pageType + v.uniqueId] = v;
          //非刷新则重新添加时间空间
          if (!v.isRefresh) {
            // 添加时间控件
            // ...
            this.$store.dispatch("mapTime/setSelected", {
              type: 1,
              callType: 1,
              moduleType: "shipRoute",
              data: {
                // startTime: new Date("2022-09-20 00:00:00").getTime(),
                // endTime: new Date().getTime(),
                startTime: v.endTime - 1 * 24 * 60 * 60 * 1000,
                endTime: v.endTime,
                checked: true,
                autoPlay: true,
              },
            });
          }
          //刷新时则刷新当前时间点并定位
          else {
            this.dealTimeLine(true, {
              startTime: v.startTime,
              endTime: v.endTime,
              autoPlay: v.isRefresh === 1 ? true : false,
            });
            this.$store.dispatch("mapTime/setSelected", {
              type: 1,
              callType: 2,
              moduleType: "shipRoute",
              data: v,
            });
          }
        }
      },
      deep: true,
    },
    "mapRoute.unSelected": {
      async handler() {
        // if (v) {
        //   this.clearShipRouteRelevantAll(v);
        // }
        this.clearShipRouteRelevantAll({
          pageType: this.pageType,
        });
      },
      deep: true,
    },
    "mapTime.selected": {
      async handler(v) {
        if (v) {
          //时间轴
          if (v.type === 1 && v.callType === 2 && v.moduleType === "shipRoute") {
            for (let key in this.requsetParam) {
              let param = JSON.parse(JSON.stringify(this.requsetParam[key]));
              Object.assign(param, v.data);
              let data = await this.getData(param.key, param);
              // console.log("轨迹数据", data);
              this.initRouteLayer(Object.assign(param, { data: data }));
              if (data) this.dealRoute(Object.assign(param, { data: data }));
            }
          }
        }
      },
      deep: true,
    },
    "mapInfoWin.selected": {
      handler(v) {
        if (v) {
          this.clearSingleRoute({
            pageType: v.layerId,
            uniqueId: v.layerId + v.shipName,
          });
        }
      },
      deep: true,
    },
    "mapRoute.fitExtent": {
      handler() {
        //定位到最新位置
        let layer = this.$refs.mapCom.getLayer(
          "routeLineLayer" + this.pageType + this.uniqueId
        );
        if (layer)
          this.map
            .getView()
            .fit(
              this.$refs.mapCom.getExtent(layer.getSource().getExtent(), 0.05)
            );
      },
      deep: true,
    },
  },
  data() {
    return {
      mapRoute: this.$store.getters.mapRoute,
      mapTime: this.$store.getters.mapTime,
      mapInfoWin: this.$store.getters.mapInfoWin,
      routeTimeArray: [], //轨迹监听
      routeArray: [], //轨迹数组
      recordIndex: null, //轨迹记录索引值
      requsetParam: {}, //轨迹请求参数

      //单轨迹配置参数
      pageType: 1,
      uniqueId: "",
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.$store.dispatch("mapRoute/clear", {});
    // }, 30000);
  },
  methods: {
    // 绘制静态轨迹
    addStaticRoute(param) {
      const routeCoords = param.routeCoords;
      const routeTime = param.routeTime;
      const routeDirection = param.routeDirection;
      const routeSpeed = param.routeSpeed;
      const pageType = param.pageType;
      // const startTime = param.startTime;
      // const endTime = param.endTime;
      // const key = param.key;
      const uniqueId = param.uniqueId;
      // const queryId = param.queryId;
      const style = param.style;
      const name = param.name;
      const isPosTo = param.isPosTo;
      let routeSectionCoords = [];
      // 计算行驶的方向
      const offsetAngle = (startx, starty, endx, endy) => {
        let tan = 0;
        if (endx == startx) {
          tan = (Math.atan(0) * 180) / Math.PI;
        } else {
          tan =
            (Math.atan(Math.abs((endy - starty) / (endx - startx))) * 180) /
            Math.PI;
        }
        if (endx >= startx && endy >= starty) {
          //第一象限
          return -tan;
        } else if (endx < startx && endy > starty) {
          //第二象限
          return tan - 180;
        } else if (endx < startx && endy < starty) {
          return 180 - tan; //第三象限
        } else {
          //第四象限
          return tan;
        }
      };
      let startx = ""; //起点坐标x
      let starty = ""; //起点坐标y
      let endx = ""; //终点坐标x
      let endy = ""; //终点坐标y
      let val_x = ""; //每小段的起点x
      let val_y = ""; //每小段的起点y
      let carSpeed = 10 * 10000; //10是扩大实际速度100倍
      let roadTime = 16; //地图每次渲染平均为16毫秒，将此作为小车单次行驶所耗费的时间
      if (routeCoords.length > 0) {
        for (let j = 0; j < routeCoords.length - 1; j++) {
          startx = routeCoords[j][0];
          starty = routeCoords[j][1];
          endx = routeCoords[j + 1][0];
          endy = routeCoords[j + 1][1];
          //-------------------------------------------------------------动态变化小车移动速度------------------------------------------------------------
          //当前路段小车的行驶角度
          let offsetA = offsetAngle(startx, starty, endx, endy);
          //小车每次行驶的距离
          let dis = carSpeed * (roadTime / 3600 / 1000);
          // disX = Math.abs(dis * Math.cos(offsetA * Math.PI / 180) / 111 / Math.cos(routeCoords[j][1] * Math.PI / 180)) * 100;
          //每一次行驶的经度差
          let disX = Math.abs(
            (dis * Math.cos((offsetA * Math.PI) / 180)) / 111
          ); //为了保持小车行驶的节点数,暂时忽略同纬度下经度差的cos值
          //每一次行驶的纬度差
          let disY = Math.abs(
            (dis * Math.sin((offsetA * Math.PI) / 180)) / 111
          );
          //方向判定
          if (startx > endx) disX = -disX;
          if (starty > endy) disY = -disY;
          //当前路段小车行驶的节点数（类推出：disCount * roadTime = 耗费的时间）
          let disCount = Math.abs((endx - startx) / disX);
          // console.log('count', disCount);
          //重新赋值初始位置节点
          val_x = startx;
          val_y = starty;
          //当前路段行驶轨迹数组的第一个节点坐标
          routeSectionCoords.push({ position: routeCoords[j], nodeIndex: j });
          //disCount-1的目的是避免二次存入初始节点坐标
          for (let i = 0; i < disCount - 1; i++) {
            val_x += disX;
            val_y += disY;
            let val = [
              parseFloat(val_x.toFixed(6)),
              parseFloat(val_y.toFixed(6)),
            ];
            routeSectionCoords.push({ position: val, nodeIndex: j });
          }
        }
        //保存该最短路径最后一个节点坐标
        routeSectionCoords.push({
          position: routeCoords[routeCoords.length - 1],
          nodeIndex: routeCoords.length - 1,
        });
        routeSectionCoords = routeCoords;
        this.routeTimeArray.push({
          uniqueId: pageType + uniqueId,
          listenser: "",
        });
        //添加静态轨迹
        const addAllRoute = () => {
          this.$refs.mapCom.addLayer("routeLineLayer" + pageType + uniqueId);
          let lineFeatures = [];
          //添加时间段内轨迹点
          for (
            let routeCoordsIndex = 0;
            routeCoordsIndex < routeCoords.length;
            routeCoordsIndex++
          ) {
            //最新位置点添加以及定位（最后一个位置点）
            if (routeCoordsIndex === routeCoords.length - 1) {
              if (isPosTo === this.mapRoute.isPosTo[1]) {
                if (routeCoords.length > 1) {
                  //定位到最新位置
                  this.map.getView().fit(
                    this.$refs.mapCom.getExtent(
                      this.$refs.mapCom
                        .getLayer("routeLineLayer" + pageType + uniqueId)
                        .getSource()
                        .getExtent(),
                      0.0005
                    )
                  );
                } else {
                  this.map.getView().animate({
                    center: routeCoords[routeCoordsIndex],
                  });
                }
              }
            }
            //最新位置点信息窗口
            if (routeCoordsIndex === routeCoords.length - 1) {
              if (routeCoords.length > 1) {
                let showData = {
                  船名: name,
                  时间: routeTime[routeCoordsIndex],
                  经度: routeCoords[routeCoordsIndex][0],
                  纬度: routeCoords[routeCoordsIndex][1],
                  航速: routeSpeed[routeCoordsIndex] + "节",
                  航向: routeDirection[routeCoordsIndex] + "°",
                  dragId: uniqueId,
                };
                //添加最新位置点弹窗
                this.$refs.mapCom.initInfoWinContainer(
                  routeCoords[routeCoordsIndex],
                  [showData],
                  "轨迹信息",
                  [],
                  (item, element) => {
                    console.log(item, element);
                    // element.addEventListener(item.type, (evt) => {
                    //   item.fun = () => {}
                    // })
                  },
                  pageType + "route",
                  1
                );
                //最后一个点标记
                let markerFeature = new this.mapApi.Feature(
                  new this.mapApi.Point(routeCoords[routeCoordsIndex])
                );
                markerFeature.setStyle(
                  new this.mapApi.Style({
                    image: new this.mapApi.Circle({
                      radius: 8,
                      fill: new this.mapApi.Fill({
                        color: "#f2204a",
                      }),
                      stroke: new this.mapApi.Stroke({
                        color: "#fff",
                        width: 2,
                      }),
                    }),
                  })
                );
                this.$refs.mapCom
                  .getLayer("routeLineLayer" + pageType + uniqueId)
                  .getSource()
                  .addFeature(markerFeature);
              }
            }
            //轨迹线绘制
            if (routeCoordsIndex != routeCoords.length - 1) {
              let line = new this.mapApi.LineString([
                [
                  routeCoords[routeCoordsIndex][0],
                  routeCoords[routeCoordsIndex][1],
                ],
                [
                  routeCoords[routeCoordsIndex + 1][0],
                  routeCoords[routeCoordsIndex + 1][1],
                ],
              ]);
              let feature = new this.mapApi.Feature({
                geometry: line,
              });
              feature.setStyle(
                new this.mapApi.Style({
                  fill: new this.mapApi.Fill({
                    //填充样式
                    color: "rgba(255, 0, 0, 0.4)",
                  }),
                  stroke: new this.mapApi.Stroke({
                    color: style[routeCoordsIndex],
                    width: 3,
                  }),
                })
              );
              lineFeatures.push(feature);
            }
          }
          this.$refs.mapCom
            .getLayer("routeLineLayer" + pageType + uniqueId)
            .getSource()
            .addFeatures(lineFeatures);
          // this.map.render();
        };
        addAllRoute();
      }
    },

    // 获取轨迹数据
    dealRoute(param) {
      param = JSON.parse(JSON.stringify(param));
      let routeCoords = [];
      let routeTime = [];
      let routeDirection = []; //轨迹航向
      let routeSpeed = []; //轨迹航速
      let styles = [];
      // let secondTime = new Date(param.secondTime).getTime();
      let endTime = new Date(param.endTime).getTime();
      let secondTime = endTime - 60 * 60 * 1000;
      let data = param.data;
      for (let i = 0; i < data.length; i++) {
        if (
          this.$refs.mapCom.isValue(data[i].longitude) &&
          this.$refs.mapCom.isValue(data[i].latitude)
        )
          routeCoords.push([data[i].longitude, data[i].latitude]);
        else if (
          this.$refs.mapCom.isValue(data[i].lon) &&
          this.$refs.mapCom.isValue(data[i].lat)
        )
          routeCoords.push([data[i].lon, data[i].lat]);
        if (this.$refs.mapCom.isValue(data[i].ts))
          routeTime.push(this.$getTimeFormat(data[i].ts));
        routeDirection.push(
          data[i].course ? data[i].course : data[i].angle ? data[i].angle : 0
        );
        routeSpeed.push(data[i].speed);
        let routeT = new Date(routeTime[i]).getTime();
        let style =
          routeT >= secondTime && routeT <= endTime ? "blue" : "#10F265";
        styles.push(style);
      }
      this.addStaticRoute(
        Object.assign(param, {
          routeCoords: routeCoords,
          routeTime: routeTime,
          routeDirection: routeDirection,
          routeSpeed: routeSpeed,
          animating: false,
          style: styles,
        })
      );
    },

    // 清空轨迹图层
    initRouteLayer(param) {
      const pageType = param.pageType;
      const uniqueId = param.uniqueId;
      // console.log("轨迹数组：", this.routeTimeArray);
      if (this.routeTimeArray.length != 0) {
        for (let i = this.routeTimeArray.length - 1; i >= 0; i--) {
          //清空所有轨迹图层相关
          if (!pageType) {
            this.map.un("postcompose", this.routeTimeArray[i].listenser);
            if (i === 0) {
              this.routeTimeArray = [];
              this.$refs.mapCom.removeLayer("routeLineLayer");
              this.$refs.mapCom.removeLayer("routeMoveLayer");
              this.$refs.mapCom.removeOverlay("textInfo");
              this.$refs.mapCom.closeInfoWin();
            }
          } else if (
            //清空某一类别所有轨迹图层
            pageType &&
            !uniqueId &&
            this.routeTimeArray[i].uniqueId.indexOf(pageType) !== -1
          ) {
            this.map.un("postcompose", this.routeTimeArray[i].listenser);
            this.routeTimeArray.splice(i, 1);
            this.$refs.mapCom.removeLayer("routeLineLayer" + pageType);
            this.$refs.mapCom.removeLayer("routeMoveLayer" + pageType);
            this.$refs.mapCom.removeOverlay("textInfo" + pageType);
            this.$refs.mapCom.closeInfoWin(null, pageType + "route");
          } else if (
            //清空某单一轨迹图层
            pageType &&
            uniqueId &&
            this.routeTimeArray[i].uniqueId.indexOf(pageType + uniqueId) !== -1
          ) {
            this.map.un("postcompose", this.routeTimeArray[i].listenser);
            this.routeTimeArray.splice(i, 1);
            this.$refs.mapCom.removeLayer(
              "routeLineLayer" + pageType + uniqueId
            );
            this.$refs.mapCom.removeLayer(
              "routeMoveLayer" + pageType + uniqueId
            );
            this.$refs.mapCom.removeOverlay("textInfo" + pageType + uniqueId);
            this.$refs.mapCom.closeInfoWin(null, pageType + "route" + uniqueId);
          }
        }
      }
      this.map.render();
    },

    /**
     * 轨迹数组处理
     * id：对应的id;
     * route：轨迹;
     * type: 0--删除;1--查询;2--更新;
     */
    dealRouteArray(param) {
      let route = null;
      //1、param不存在；2、页面是单页面并且无节点id;次两种情况清空所有尾迹节点
      if (!param || (!param.pageType && !param.uniqueId)) {
        this.routeArray = [];
      }
      //页面存在且节点id不存在的情况下，则清空当前页面类型的所有轨迹
      else if (param.pageType && !param.uniqueId) {
        this.routeArray.map((v, k) => {
          if (v.pageType === param.pageType) {
            this.routeArray.splice(k, 1);
          }
        });
      }
      //节点id存在的情况下，如果页面类型不存在，则定义为单页面；反之页面类型存在，则默认当前页面
      else {
        if (!param.pageType) param.pageType = "singlePage";
        switch (param.type) {
          case "0": //删除
          case 0:
            this.routeArray.map((v, k) => {
              if (v.pageType + v.uniqueId === param.pageType + param.uniqueId) {
                this.routeArray.splice(k, 1);
              }
            });
            break;
          case "1": //查询
          case 1:
            this.routeArray.map((v) => {
              if (param.pageType + param.uniqueId === v.pageType + v.uniqueId) {
                route = v;
              }
            });
            break;
          case "2": //更新
          case 2:
            {
              let existFlag = false;
              this.routeArray.map((v) => {
                if (
                  param.pageType + param.uniqueId ===
                  v.pageType + v.uniqueId
                ) {
                  v = param;
                  existFlag = true;
                }
              });
              //如果不含有该条轨迹，则更新添加该条轨迹
              if (!existFlag) this.routeArray.push(param);
            }
            break;
          default:
            break;
        }
      }
      return route;
    },

    // 删除单击轨迹相关
    clearSingleRoute(param) {
      //删除轨迹图层
      this.initRouteLayer(param);
      //轨迹数组处理
      this.dealRouteArray(Object.assign(param, { type: 0 }));
      this.deleteRequestParam(param);
    },

    //移除所有轨迹相关
    clearShipRouteRelevantAll(param) {
      this.initRouteLayer(param);
      this.dealRouteArray(param);
      this.deleteRequestParam();
    },

    deleteRequestParam(param) {
      if (param) delete this.requsetParam[param.pageType + param.uniqueId];
      else
        for (let key in this.requsetParam) {
          delete this.requsetParam[key];
        }

      let keys = Object.getOwnPropertyNames(this.requsetParam);
      keys = keys.slice(0, keys.length - 1);
      if (keys.length === 0 && param && !param.refresh)
        this.dealTimeLine(false);
    },

    dealTimeLine(status, param) {
      this.$store.dispatch("mapTime/setSelected", {
        type: 1,
        callType: 1,
        moduleType: "shipRoute",
        data:
          status && param
            ? Object.assign(param, { checked: status })
            : { checked: status },
      });
      this.$store.dispatch("mapTime/setSelected", {
        type: 2,
        callType: 1,
        moduleType: "shipRoute",
        data: {
          checked: false,
        },
      });
    },

    // 获取轨迹数据
    getData(key, value) {
      value = {
        bdId: value.queryId,
        xbdId: value.queryId,
        aisId: value.queryId,
        mmsi: value.queryId,
        startTime: Number(value.startTime),
        endTime: Number(value.endTime),
      };
      return new Promise((resolve) => {
        try {
          this.$store
            .dispatch(key, value)
            .then((res) => {
              resolve(res.result);
            })
            .catch(() => {})
            .finally(() => {
              // resolve(require("./data/mapRouteData.json"));
            });
        } catch (error) {
          console.log(error);
          // resolve(require("./data/mapRouteData.json"));
        }
      });
    },
  },
  beforeDestroy() {
    for (let i = this.routeTimeArray.length - 1; i >= 0; i--) {
      this.map.un("postcompose", this.routeTimeArray[i].listenser);
      this.routeTimeArray.splice(i, 1);
    }
  },
};
</script>
<style lang="scss" scoped>
.mapRouteContainer {
  // position: absolute;
  // z-index: 999;
  // right: 30rem;
  // top: 9rem;
}
</style>