const state = {
  selected: {
    type: null, //-1-全部删除,0-删除，1-添加，2-刷新
    TYPHOON_ID: null,
    data: [],
  },
  ids: {},
  change: null,
  filterObj: {}
}
const mutations = {
  SET_SELECTED: (state, param) => {
    if (!(param["type"] === 1 && state.ids[param.TYPHOON_ID])) {
      state.selected = param
      state.change = Math.random()
    }
    if (param["type"] === 1 && !state.ids[param.TYPHOON_ID]) {
      state.ids[param.TYPHOON_ID] = param
    } else if (param["type"] === 0) {
      delete state.ids[param.TYPHOON_ID]
    } else if (param["type"] === -1) {
      for (let k in state.ids) {
        delete state.ids[k]
      }
    }
  },
  GET_SELECTED: (state, param) => {
    let idsArr = Object.getOwnPropertyNames(state.ids)
    idsArr = idsArr.slice(0, idsArr.length - 1)
    let paramIds = []
    param.map(v => paramIds.push(v.TYPHOON_ID))
    let f1 = idsArr.filter(v => !paramIds.includes(v))
    let f2 = paramIds.filter(v => !idsArr.includes(v))
    let f3 = f1.concat(f2)
    state.filterObj = { TYPHOON_ID: f3[0], checked: idsArr.length < paramIds.length ? true : false }
  }
}
const actions = {
  setSelected(context, param) {
    context.commit('SET_SELECTED', param)
  },
  getSelected(context, param) {
    context.commit('GET_SELECTED', param)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}