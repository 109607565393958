import * as mapForecastData from '@/api/mapForecastData.js'
const state = {
  selected: {
    title: null,//定位类型   
    checked: null,
    image: [],
    change: null,
    layerId: null
  },
  contextMore: {
    isShow: null,
    change: null
  },
  data: {
    change: null,
    area: null,
    beginDate: null,
    element: null,
    ageing: null,
    checked: null
  },
  currentPosRecords: [],
  stations: [
    {
      layerId: 'citySea',
      forecastType: 2,
      title: "城市近海预报",
    },
    {
      layerId: 'fishBound',
      forecastType: 4,
      title: "渔场预报",
    },
    {
      layerId: 'grid',
      forecastType: 8,
      title: "网格化预报",
    },
    {
      layerId: 'mathAnimate',
      forecastType: null,
      title: "数模动画预报",
    },
    {
      layerId: 'port',
      forecastType: 6,
      title: "港口预报",
    },
    {
      layerId: 'seaArea',
      forecastType: 1,
      title: "海区预报",
    },
    {
      layerId: 'seaRanch',
      forecastType: 7,
      title: "海洋牧场预报",
    },
  ],
  context_more: [
    {
      title: "网格化预报",
      layerId: "grid",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: false,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/grid.png")
      ],
    },
    {
      title: "数模动画预报",
      layerId: "mathAnimate",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: true,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/mathAnimate.png")
      ],
    },
    {
      title: "海区预报",
      layerId: "seaArea",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: false,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/seaArea.png")
      ],
    },
    {
      title: "城市近海预报",
      layerId: "citySea",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: false,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/citySea.png")
      ],
    },
    {
      title: "渔场预报",
      layerId: "fishBound",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: false,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/fishBound.png")
      ],
    },
    {
      title: "港口预报",
      layerId: "port",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: false,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/port.png")
      ],
    },
    {
      title: "海洋牧场预报",
      layerId: "seaRanch",
      style: {
        width: "35px",
        height: "35px",
        display: "flex",
      },
      checked: false,
      disabled: false,
      fun: () => { },
      image: [
        require("@/assets/image/map/typhoon/seaRanch.png"),
        require("@/assets/image/map/typhoon/seaRanch_old.png"),
      ],
    },
  ],
  context: [
    {
      type: 'wave',
      title: "海浪",
      image_select: require("@/assets/image/map/typhoon/wave_select.png"),
      image: require("@/assets/image/map/typhoon/wave.png"),
    },
    {
      type: 'temp',
      title: "海温",
      image_select: require("@/assets/image/map/typhoon/temp_select.png"),
      image: require("@/assets/image/map/typhoon/temp.png"),
    },
    {
      type: 'current',
      title: "海流",
      image_select: require("@/assets/image/map/typhoon/current_select.png"),
      image: require("@/assets/image/map/typhoon/current.png"),
    },
    {
      type: 'typhoon',
      title: "台风",
      image_select: require("@/assets/image/map/typhoon/typhoon_select.png"),
      image: require("@/assets/image/map/typhoon/typhoon.png"),
    },
    {
      type: 'more',
      title: "更多",
      image_select: require("@/assets/image/map/typhoon/more_select.png"),
      image: require("@/assets/image/map/typhoon/more.png"),
    },
  ],
  foreCastItems: {
    time: {
      title: "时效",
      image: require("@/assets/image/map/typhoon/time.png"),
    },
    waveLevelNew: {
      title: "浪级",
      image: require("@/assets/image/map/typhoon/waveLevel.png"),
    },
    waveHeightNew: {
      title: "浪高(m)",
      image: require("@/assets/image/map/typhoon/waveHight.png"),
    },
    waveDirectionNew: {
      title: "浪向",
      image: require("@/assets/image/map/typhoon/waveDirection.png"),
    },
    swellDirectionNew: {
      title: "涌向",
      image: require("@/assets/image/map/typhoon/swellDirection.png"),
    },
    wavePeriodNew: {
      title: "(海浪)周期",
      image: require("@/assets/image/map/typhoon/wavePeriod.png"),
    },
    waterTempNew: {
      title: "水温(℃)",
      image: require("@/assets/image/map/typhoon/waterTemp.png"),
    },
    tideHightNew: {
      title: "潮汐",
      image: require("@/assets/image/map/typhoon/tideHight.png"),
    },
    windPowerNew: {
      title: "风力",
      image: require("@/assets/image/map/typhoon/windPower.png"),
    },
    windDirectionNew: {
      title: "风向",
      image: require("@/assets/image/map/typhoon/windDirection.png"),
    },
    visibilityNew: {
      title: "能见度",
      image: require("@/assets/image/map/typhoon/visibility.png"),
    },
    swimmingFitnessNew: {
      title: "游泳适宜度",
      image: require("@/assets/image/map/typhoon/swimmingFitness.png"),
    },
    gustNew: {
      title: "阵风",
      image: require("@/assets/image/map/typhoon/gust.png"),
    },
    weatherNew: {
      title: "天气状况",
      image: require("@/assets/image/map/typhoon/weather.png"),
    },
    weatherTempNew: {
      title: "气温(℃)",
      image: require("@/assets/image/map/typhoon/weatherTemp.png"),
    },
    HighLowWaterNew: {
      title: "潮时、潮高",
      image: require("@/assets/image/map/typhoon/HighLowWater.png"),
    },
    windSpeedNew: {
      title: "风速",
      image: require("@/assets/image/map/typhoon/windSpeed.png"),
    },
    tideHightTime: {
      title: "潮时",
      image: require("@/assets/image/map/typhoon/HighLowWater.png"),
    },
    tideHightLevel: {
      title: "潮位(cm)",
      image: require("@/assets/image/map/typhoon/tideHightLevel.png"),
    },
  },
}
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected)
        if (param[v] !== undefined) state.selected[v] = param[v]
      state.selected['change'] = !state.selected['change']
    })
  },
  GET_DATA: (state, param) => {
    setTimeout(() => {
      for (let v in state.data)
        if (param[v] !== undefined) state.data[v] = param[v]
      state.data['change'] = !state.data['change']
    })
  },
}
const actions = {
  setSelected(context, param) {
    context.commit('SET_SELECTED', param)
  },
  getData(context, param) {
    context.commit('GET_DATA', param)
  },
  getForecastData(key, param) {
    return new Promise((resolve, reject) => {
      mapForecastData.getForecastData(param).then(response => {
        resolve(response)
      }).catch(error => {
        console.log(key)
        reject(error)
      })
    })
  },
  getStations(key, param) {
    return new Promise((resolve, reject) => {
      mapForecastData.getStations(param).then(response => {
        resolve(response)
      }).catch(error => {
        console.log(key)
        reject(error)
      })
    })
  },
  distanceCalculate(key, param) {
    return new Promise((resolve, reject) => {
      mapForecastData.distanceCalculate(param).then(response => {
        resolve(response)
      }).catch(error => {
        console.log(key)
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}