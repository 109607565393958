<template>
  <div>
    <map-base ref="mapCom" :map="map" />
    <!-- 地图样式切换 -->
    <div
      v-show="mapSwitchShow"
      class="map_change"
      @mouseover="Enter"
      @mouseleave="Out"
    >
      <div
        v-for="(item, index) in ImgC"
        v-show="index == activeIndex || activeIndex == -1"
        :key="index"
        class="map_box"
        @click="switchMap(item, index)"
      >
        <img :src="item.src" />
      </div>
    </div>
  </div>
</template>
<script>
// <script module = 'mapSwitchContainer' lang="renderjs">
export default {
  name: "mapSwitch",
  props: ["sw", "map"],
  components: {},
  data() {
    return {
      mapSwitch: this.$store.getters.mapSwitch,
      mapSwitchShow: this.sw,

      show_vectorMap: null, //显示矢量图
      show_imageMap: null, //显示影像图,
      show_htMap: null, //显示海图
      baseLayerIds: [], //基础图层Id数组,

      layerCountMax: this.$mapConfig.baseMapCountMax,

      // 小地图
      ImgC: [
        {
          id: 0,
          src: require("@/assets/image/map/baseMap/btn_map3.png"),
          value: "hlMap",
        },
        {
          id: 1,
          src: require("@/assets/image/map/baseMap/btn_map2.png"),
          value: "imageMap",
        },
        {
          id: 2,
          src: require("@/assets/image/map/baseMap/btn_map1.png"),
          value: "htMap",
        },
      ],
      mapSwitchIndexArray: "",
      activeIndex: "",
    };
  },
  watch: {
    "mapSwitch.selected": {
      handler(v) {
        if (v) {
          // this.switchMapPost("htMapxyz");
          this.switchMapPost(v.type);
          // this.switchMapPost("tltLayer");
        }
      },
      deep: true,
    },
  },
  mounted() {
    switch (this.$mapConfig.mapSwitch) {
      case 1:
        this.switchMapPost("vectorMap");
        break;
      case 2:
        this.switchMapPost("vectorMap");
        break;
      case 3:
        this.switchMapPost("hlMap");
        break;
    }
    // this.switchMapPost();
  },
  methods: {
    addBaseLayer(p1, p2, p3) {
      if (!p1 && !p2 && !p3) return;
      this.mapSwitchIndexArray = [
        {
          index: p1 ? 0 : p2 ? 1 : 2,
        },
        {
          index: -1,
        },
      ];
      this.activeIndex = p1 ? 0 : p2 ? 1 : 2;
      // let value = "";
      this.ImgC.map((v) => {
        if (v.id === this.activeIndex) {
          this.changeBaseLayer(v.value);
        }
      });
    },
    //小地图底图切换
    switchMap(data, index) {
      let th = this;
      th.mapSwitchIndexArray[0].index = index;
      th.$refs.mapCom.removeAllBaseLayer();
      th.changeBaseLayer(data.value);
    },
    //传入方式调用
    switchMapPost(value) {
      this.$refs.mapCom.removeAllBaseLayer();
      this.changeBaseLayer(value);
    },
    /**
     * 切换天地
     */
    switchBaseMapTD(value) {
      let th = this;
      if (value === "vectorMap") {
        let vecLayer = th.initTDvecLayer(true);
        vecLayer.map((v, k) => {
          th.$refs.mapCom.insertAtLayer(k, v);
        });
        // th.$refs.mapCom.insertAtLayer(-1, th.initTDvecLayer(true));
      } else if (value === "imageMap") {
        // let imageLayer = th.initTDImageLayer(true);
        let imageLayer = th.initTDImageLayer(true);
        imageLayer.map((v, k) => {
          th.$refs.mapCom.insertAtLayer(k, v);
        });
        th.$refs.mapCom.insertAtLayer(th.layerCountMax - 1, th.addThemeLayer());
      } else if (value === "htMap") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayerOriginChange("", true, "EPSG:3857", "htLayer")
        );
        // th.$refs.mapCom.insertAtLayer(
        //   0,
        //   th.initTileLayerOrigin(
        //     this.$mapValue.mapUrl[1] + "20210602/",
        //     true,
        //     "EPSG:4326",
        //     "htLayer"
        //   )
        // );
      } else if (value === "htMap000") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayer0000(
            this.$mapValue.mapUrl[3] + "htTest/",
            true,
            "EPSG:3857",
            "htLayer000"
          )
        );
      } else if (value === "htMapxyz") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayerZXY(
            this.$mapValue.mapUrl[3] + "htTestNew/hty/base/",
            true,
            "EPSG:3857",
            "htLayer000"
          )
        );
      } else if (value === "tltLayer") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayerOriginChange("", true, "EPSG:3857", "tltLayer")
        );
      } else if (value === "hlMap") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayer(
            this.$mapValue.mapUrl[1] + "Layers/_alllayers/",
            true,
            "EPSG:3857",
            "hlLayer"
          )
        );
      }
      //补充图层到最大图层数目
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      for (let i = 0; i < th.layerCountMax - baseLayerLength; i++) {
        let layer = new th.mapApi.TileLayer({
          source: new th.mapApi.WMTSSource({}),
          id: "coverLayer" + i,
          type: "baseMap",
        });
        th.$refs.mapCom.insertAtLayer(baseLayerLength + i, layer);
      }
    },
    /**
     * 切换高德
     */
    switchBaseMapGD(value) {
      let th = this;
      if (value === "vectorMap") {
        let vecLayer = th.initGDVecLayer(true);
        vecLayer.map((v, k) => {
          th.$refs.mapCom.insertAtLayer(k, v);
        });
        // th.$refs.mapCom.insertAtLayer(-1, th.initTDvecLayer(true));
      } else if (value === "imageMap") {
        // let imageLayer = th.initTDImageLayer(true);
        let imageLayer = th.initGDImageLayer(true);
        imageLayer.map((v, k) => {
          th.$refs.mapCom.insertAtLayer(k, v);
        });
        th.$refs.mapCom.insertAtLayer(th.layerCountMax - 1, th.addThemeLayer());
      } else if (value === "htMap") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayerOrigin(
            this.$mapValue.mapUrl[1] + "20210602/",
            true,
            "EPSG:4326",
            "htLayer"
          )
        );
      }
      //补充图层到最大图层数目
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      for (let i = 0; i < th.layerCountMax - baseLayerLength; i++) {
        let layer = new th.mapApi.TileLayer({
          source: new th.mapApi.WMTSSource({}),
          id: "coverLayer" + i,
          type: "baseMap",
        });
        th.$refs.mapCom.insertAtLayer(baseLayerLength + i, layer);
      }
    },
    /**
     * 切换arcgis
     */
    switchBaseMapArcgis(value) {
      let th = this;
      if (value === "imageMap") {
        let imageLayer = th.addArcgisImageLayer();
        imageLayer.map((v, k) => {
          th.$refs.mapCom.insertAtLayer(k, v);
        });
        th.$refs.mapCom.insertAtLayer(th.layerCountMax - 1, th.addThemeLayer());
      } else if (value === "hlMap") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayer(
            this.$mapValue.mapUrl[1] + "Layers/_alllayers/",
            true,
            "EPSG:3857",
            "htLayer"
          )
        );
      } else if (value === "htMap") {
        th.$refs.mapCom.insertAtLayer(
          0,
          th.initTileLayerOrigin(
            this.$mapValue.mapUrl[1] + "20210602/",
            true,
            "EPSG:4326",
            "htLayer"
          )
        );
      }
      //补充图层到最大图层数目
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      for (let i = 0; i < th.layerCountMax - baseLayerLength; i++) {
        let layer = new th.mapApi.TileLayer({
          source: new th.mapApi.WMTSSource({}),
          id: "coverLayer" + i,
          type: "baseMap",
        });
        th.$refs.mapCom.insertAtLayer(baseLayerLength + i, layer);
      }
    },
    changeBaseLayer(value) {
      switch (this.$mapConfig.mapSwitch) {
        case 1:
          this.switchBaseMapTD(value);
          break;
        case 2:
          this.switchBaseMapGD(value);
          break;
        case 3:
          this.switchBaseMapArcgis(value);
          break;
      }
    },
    // 鼠标移入事件
    Enter() {
      this.activeIndex = this.mapSwitchIndexArray[1].index;
    },
    // 鼠标移除事件
    Out() {
      this.activeIndex = this.mapSwitchIndexArray[0].index;
    },
    //--------------------------------------------------初始地图配置start----------------------------------------------------------------------------------------------------------------------
    /**
     * 初始化地图
     */
    initMap(map) {
      this.map = map;
      this.$refs.mapCom.initMap(map);
      this.switchMapPost("vectorMap");
    },
    //--------------------------------------------------初始地图配置end----------------------------------------------------------------------------------------------------------------------
    /**
     * 天地图矢量
     */
    initTDvecLayer() {
      let projection = this.mapApi.getProjection("EPSG:4326");

      let projectionExtent = projection.getExtent();

      let size = this.mapApi.getWidth(projectionExtent) / 256;

      let resolutions = [];

      for (let z = 2; z < 19; ++z) {
        resolutions[z] = size / Math.pow(2, z);
      }

      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          name: "0-20级底图",
          url: "http://t{0-6}.tianditu.gov.cn/vec_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "vec",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "tdvec",
      });
      let layer2 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          type: "baseMap",
          name: "0-20级注记",
          url: "http://t{0-6}.tianditu.gov.cn/cva_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "cva",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "tdcva",
      });
      return [layer1, layer2];
    },
    /**
     * 添加天地图影像图
     */
    initTDImageLayer() {
      let projection = this.mapApi.getProjection("EPSG:4326");

      let projectionExtent = projection.getExtent();

      let size = this.mapApi.getWidth(projectionExtent) / 256;

      let resolutions = [];

      for (let z = 2; z < 19; ++z) {
        resolutions[z] = size / Math.pow(2, z);
      }

      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          name: "0-20级影像底图",
          url: "http://t{0-6}.tianditu.gov.cn/img_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "img",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "gdimg",
      });
      let layer2 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          name: "0-20级影像注记",
          url: "http://t{0-6}.tianditu.gov.cn/cia_w/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "cia",
          style: "default",
          matrixSet: "w",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "gdcia",
      });
      return [layer1, layer2];
    },
    /**
     * 添加高德矢量
     */
    initGDVecLayer(visible) {
      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          crossOrigin: "anonymous",
          url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7",
        }),
        name: "高德矢量",
        id: "vecLayer",
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push("vecLayer");
      return [layer1];
    },
    /**
     * 添加高德影像
     */
    initGDImageLayer(visible) {
      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          crossOrigin: "anonymous",
          url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=6",
        }),
        name: "高德影像底图",
        id: "imageLayer",
        visible: visible,
        type: "baseMap",
      });
      let layer2 = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          crossOrigin: "anonymous",
          url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=8",
        }),
        name: "高德影像注记",
        id: "imageCvaLayer",
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push("imageLayer");
      this.baseLayerIds.push("imageCvaLayer");
      return [layer1, layer2];
    },
    /**
     * 添加切片影像图(重写切片)
     */
    initTileLayer(url, visible, projection, layerId) {
      //给8位字符串文件名补0
      function zeroPad(num, len, radix) {
        let str = num.toString(radix || 10);
        while (str.length < len) {
          str = "0" + str;
        }
        return str.toUpperCase();
        // return str;
      }
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          tileUrlFunction: function (tileCoord) {
            let x = "C" + zeroPad(tileCoord[1], 8, 16);
            let y = "R" + zeroPad(tileCoord[2], 8, 16);
            let z = "L" + zeroPad(tileCoord[0], 2, 10);
            return url + z + "/" + y + "/" + x + ".png";
          },
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    /**
     * 添加切片影像图(原生切片)
     */
    initTileLayerOrigin(url, visible, projection, layerId) {
      //给8位字符串文件名补0
      // function zeroPad(num, len, radix) {
      //   let str = num.toString(radix || 10);
      //   while (str.length < len) {
      //     str = "0" + str;
      //   }
      //   // return str.toUpperCase();
      //   return str;
      // }
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: url + "{z}/{y}/{x}.png",
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    initTileLayerZXY(url, visible, projection, layerId) {
      //给8位字符串文件名补0
      // function zeroPad(num, len, radix) {
      //   let str = num.toString(radix || 10);
      //   while (str.length < len) {
      //     str = "0" + str;
      //   }
      //   // return str.toUpperCase();
      //   return str;
      // }
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: url + "{z}/{x}/{y}.png",
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    initTileLayerOriginChange(url, visible, projection, layerId) {
      //给8位字符串文件名补0
      // function zeroPad(num, len, radix) {
      //   let str = num.toString(radix || 10);
      //   while (str.length < len) {
      //     str = "0" + str;
      //   }
      //   // return str.toUpperCase();
      //   return str;
      // }
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          tileUrlFunction: (tileCoord) => {
            let u = "";
            let x = tileCoord[1];
            let y = tileCoord[2];
            let z = tileCoord[0];
            // if (z <= 8) u = url + z + "/" + y + "/" + x + ".png";
            // else u = url + z + "/" + x + "/" + y + ".png";
            if (z <= 7)
              u =
                this.$mapValue.mapUrl[1] +
                "20210602/" +
                z +
                "/" +
                y +
                "/" +
                x +
                ".png";
            else u = this.$mapValue.mapUrl[5] + z + "/" + x + "/" + y + ".png";
            return u;
          },
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    /**
     * 添加专题图
     */
    addThemeLayer: function () {
      let th = this;
      return new th.mapApi.VectorLayer({
        id: th.layerId,
        name: th.layerId,
        type: "baseMap",
        source: new th.mapApi.VectorSource({
          projection: "EPSG:4326",
          url: this.$mapValue.mapUrl[1] + "json/themeMap.json",
          format: new th.mapApi.GeoJSON(),
        }),
        visible: true,
        style: (v) => {
          return new this.mapApi.Style({
            fill: new this.mapApi.Fill({
              color: "rgba(59,136,237,0.5)",
            }),
            // stroke: new this.mapApi.Stroke({
            //   width: 2,
            //   color: "#ffe036"
            // }),
            text: new this.mapApi.Text({
              font: "bold 15px 微软雅黑",
              offsetX: 0,
              offsetY: 40,
              text: v.get("text"),
              fill: new this.mapApi.Fill({ color: "#0059bd" }),
              textBaseline: "bottom",
              stroke: new this.mapApi.Stroke({
                // 线样式
                color: "#fff",
                width: 1,
              }),
            }),
          });
        },
      });
    },
    /**
     * esri影像图
     */
    addArcgisImageLayer() {
      const layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: "https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        }),
        id: "arcgisImageLayer",
        type: "baseMap",
      });
      return [layer];
    },
    /**
     * esri矢量图
     */
    addArcgisVecLayer() {
      const layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: "https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
        }),
        id: "arcgisVecLayer",
        type: "baseMap",
      });
      return [layer];
    },

    /**
     * 添加切片影像图(重写切片)
     */
    initTileLayer0000(url, visible, projection, layerId) {
      //给8位字符串文件名补0
      function zeroPad(num) {
        var r = num.toString();
        while (r.length < 8) {
          r = 0 + r;
        }
        return r;
      }
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          tileUrlFunction: function (tileCoord) {
            let z = tileCoord[0];
            let y = zeroPad(tileCoord[1]);
            let x = y + "_" + zeroPad(tileCoord[2]);
            return url + z + "/" + y + "/" + x + ".png";
          },
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    //--------------------------------------------------底图切换end----------------------------------------------------------------------------------------------------------------------
  },
};
</script>
<style lang="scss" scoped>
/* 地图样式切换模块样式 */
.map_change {
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.map_box {
  width: 150px;
  height: 110px;
  position: absolute;
  cursor: pointer;
  background: white;
  min-width: 1.5rem;
  min-height: 1.1rem;
  bottom: 0;
  padding: 0.05rem;
}

img {
  width: 100%;
  height: 100%;
}

.map_change:hover > .map_box {
  position: relative;
}
</style>