import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
// title:页面标题
// type:路由权限，0代表移动端底部导航，1代表手机端首页的menu菜单
export const constantRoutes = [
  {
    path: "/",
    name: "/",
    component: () => import("@/views/home/appMenu"),
    hidden: true,
  },
];
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });
const router = createRouter();
export default router;
