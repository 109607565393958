<template>
  <div>
    <map-base ref="mapCom" :map="map" />
    <MapTyphoon ref="typhoon" :map="map" />
    <!-- 台风路径 -->
    <TyphoonList ref="typhoonList" class="typhoon" />
    <!-- 风羽 -->
    <WindBarbsChart
      v-if="showWindBarbsChart"
      :img-point="imgPoint"
      @closed="chartClosed"
    />
    <!-- 海流 -->
    <CurrentChart
      v-if="showCurrentChart"
      :img-point="imgPoint"
      @closed="chartClosed"
    />
    <!-- 浪高 -->
    <WaveChart
      v-if="showWaveChart"
      :img-point="imgPoint"
      @closed="chartClosed"
    />
    <!-- 风场 -->
    <legends v-if="legendFlag_wind" class="legend" :legend-type="pathType" />
    <div class="check-items" v-if="legendFlag_wind">
      <el-switch
        v-model="seaW_Distribute_checked_wind"
        active-text="默认"
        @change="changeWeatherDefault_wind"
      />
      <el-switch
        v-model="seaW_Particle_checked_wind"
        active-text="粒子"
        @change="changeWeatherParticle_wind"
      />
      <el-switch
        v-model="seaW_GridAngle_checked_wind"
        active-text="风羽"
        @change="changeWeatherAngle_wind"
      />
      <el-switch
        v-model="seaW_GridValue_checked_wind"
        active-text="数值"
        @change="changeWeatherValue_wind"
      />
    </div>
    <!-- 流场 -->
    <legends v-if="legendFlag_current" class="legend" :legend-type="pathType" />
    <div class="check-items" v-if="legendFlag_current">
      <el-switch
        v-model="seaW_Distribute_checked_current"
        active-text="默认"
        @change="changeWeatherDefault_current"
      />
      <el-switch
        v-model="seaW_Particle_checked_current"
        active-text="粒子"
        @change="changeWeatherParticle_current"
      />
      <el-switch
        v-model="seaW_GridValue_checked_current"
        active-text="数值"
        @change="changeWeatherValue_current"
      />
    </div>
    <!-- 海浪 -->
    <legends v-if="legendFlag_wave" class="legend" :legend-type="pathType" />
    <div class="check-items" v-if="legendFlag_wave">
      <el-switch
        v-model="seaW_Distribute_checked_wave"
        active-text="默认"
        @change="changeWeatherDefault_wave"
      />
      <el-switch
        v-model="seaW_GridValue_checked_wave"
        active-text="数值"
        @change="changeWeatherValue_wave"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import SeaWeather_Distribute from "./SeaWeather_Distribute";
import SeaWeather_Particle from "./SeaWeather_Particle";
import SeaWeather_GridValue from "./SeaWeather_GridValue";
import SeaWeather_GridAngle from "./SeaWeather_GridAngle";
import MapTyphoon from "./typhoon/map-typhoon";
import TyphoonList from "./typhoon/typhoonList.vue";
import WindBarbsChart from "./Charts/WindBarbsChart";
import WaveChart from "./Charts/WaveChart";
import CurrentChart from "./Charts/CurrentChart";
import legends from "./legend";
export default {
  name: "seaWeather",
  props: ["map"],
  components: {
    MapTyphoon,
    TyphoonList,
    WindBarbsChart,
    WaveChart,
    CurrentChart,
    legends,
  },
  inject: ["dealTimeLine"],
  data() {
    return {
      mapWeather: this.$store.getters.mapWeather,
      mapTyphoon: this.$store.getters.mapTyphoon,
      baseLayerLength: 0,

      // baseUrl: this.$mapValue.mapUrl[2] + "gis/meteorology/",
      baseUrl: "/mapWeather/",
      time: "",
      weatherParam: null,
      seawWeatherInfoWin: null,

      showWindBarbsChart: false,
      showCurrentChart: false,
      showWaveChart: false,
      imgPoint: null,
      seaWeatherData: null,
      // 风场
      seaW_Distribute_checked_wind: null,
      seaW_Particle_checked_wind: null,
      seaW_GridValue_checked_wind: null,
      seaW_GridAngle_checked_wind: null,
      legendFlag_wind: false,
      seaW_Distribute_wind: false,
      seaW_Particle_wind: false,
      seaW_GridValue_wind: false,
      seaW_GridAngle_wind: false,
      //流场
      seaW_Distribute_checked_current: null,
      seaW_Particle_checked_current: null,
      seaW_GridValue_checked_current: null,
      legendFlag_current: false,
      seaW_Distribute_current: false,
      seaW_Particle_current: false,
      seaW_GridValue_current: false,
      //海浪
      seaW_Distribute_checked_wave: null,
      seaW_GridValue_checked_wave: null,
      legendFlag_wave: false,
      seaW_Distribute_wave: false,
      seaW_GridValue_wave: false,
      //台风
      legendFlag_typhoon: false,
      pathType: "wind",
    };
  },
  watch: {
    "mapWeather.selected": {
      handler(v) {
        //台风
        if (v && v.childType === 2) {
          this.initLayer();
          if (v.checked) {
            this.addTyphoon(v.data);
          } else {
            this.handleUnCheck(v.data);
          }
        }
      },
      deep: true,
    },
    "mapTyphoon.change": {
      handler() {
        this.initLayer();
        let selected = this.mapTyphoon.selected;
        switch (selected.type) {
          case -1:
            this.clearAllTyphoon();
            this.$refs.typhoonList.removeRoute();
            break;
          case 0:
            this.clearTyphoon(selected);
            break;
          case 1:
            this.addTyphoon(selected.data);
            break;
          case 2:
            this.clearTyphoon(selected);
            this.addTyphoon(selected.data);
            break;
          case 3:
            // this.$refs.typhoonList.getData();
            this.$refs.typhoonList.showPop();
            break;
        }
      },
    },
  },
  mounted() {},
  methods: {
    // 初始化图层参数
    initLayer() {
      this.baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      this.$refs.typhoon.init(this.baseLayerLength + 1);
      this.lLayerCount = 1;
    },
    // 初始化默认选中项
    initSelectedItem(value) {
      switch (value) {
        case "wind":
          this.seaW_Distribute_checked_wind = true;
          break;
        case "current":
          this.seaW_Distribute_checked_current = true;
          break;
        case "wave":
          this.seaW_Distribute_checked_wave = true;
          break;
      }
    },

    // 缩放结束事件
    moveEndEvt() {},

    // 鼠标滑动事件
    touchOnly(feature, layerId) {
      // if (!this.$isMobile()) this.$refs.mapCom.closeInfoWinOver();
      // if (this.$refs.mapCom.getLayer(this.$mapValue.layer[8].layerId))
      this.addTyphoonInfoWin(feature, layerId);
    },

    // 台风弹窗
    addTyphoonInfoWin(feature, layerId) {
      let showData, title, centerX, centerY;
      if (layerId.indexOf("typhoonCenter") > -1) {
        this.$refs.mapCom.closeInfoWinOver();
        centerY =
          this.$formatDegree(parseFloat(feature.get("centerY"))) +
          (parseFloat(feature.get("centerY")) < 0 ? "S" : "N");
        centerX =
          this.$formatDegree(parseFloat(feature.get("centerX"))) +
          (parseFloat(feature.get("centerX")) < 0 ? "W" : "E");
        showData = {
          英文名称: feature.get("engName"),
          台风时间: feature.get("time"),
          中心位置: centerY + "/" + centerX,
          气压: feature.get("PA"),
          风速: feature.get("speed"),
          移动方向: feature.get("moveDirect") ? feature.get("moveDirect") : "-",
          七级: feature.get("sevenLevel"),
          十级: feature.get("tenLevel"),
          十二级: feature.get("twelveLevel"),
        };
        title = feature.get("TYPHOON_ID") + "(" + feature.get("name") + ")";
        //  信息弹窗
        this.$refs.mapCom.initInfoWinContainerOver(
          feature.getGeometry().flatCoordinates,
          [showData],
          title,
          [
            { show: false, name: "" },
            { show: false, name: "" },
          ],
          (item, element) => {}
        );
        // this.$refs.typhoon.typhoonCircle(feature.getProperties());
      } else if (layerId.indexOf("typhoonForecastCenter") > -1) {
        this.$refs.mapCom.closeInfoWinOver();
        centerY =
          this.$formatDegree(parseFloat(feature.get("centerY"))) +
          (parseFloat(feature.get("centerY")) < 0 ? "S" : "N");
        centerX =
          this.$formatDegree(parseFloat(feature.get("centerX"))) +
          (parseFloat(feature.get("centerX")) < 0 ? "W" : "E");
        showData = {
          英文名称: feature.get("engName"),
          台风时间: feature.get("time"),
          预报台: feature.get("countryName"),
          中心位置: centerY + "/" + centerX,
          气压: feature.get("PA"),
          风速: feature.get("speed"),
        };
        title = feature.get("TYPHOON_ID") + "(" + feature.get("name") + ")";
        //  信息弹窗
        this.$refs.mapCom.initInfoWinContainerOver(
          feature.getGeometry().flatCoordinates,
          [showData],
          title,
          [
            { show: false, name: "" },
            { show: false, name: "" },
          ],
          (item, element) => {}
        );
      }
    },

    // 地图单击事件
    singleClick(evt) {
      let array = [];
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        array.push([feature, layer ? layer.get("id") : ""]);
        if (array.length === 1) {
          let feature = array[0][0];
          let layerId = array[0][1];
          this.addTyphoonInfoWin(feature, layerId);
        }
      });
      if (
        !(
          this.seaW_Distribute_checked_wind ||
          this.seaW_Particle_checked_wind ||
          this.seaW_GridValue_checked_wind ||
          this.seaW_GridAngle_checked_wind ||
          this.seaW_Distribute_checked_current ||
          this.seaW_Particle_checked_current ||
          this.seaW_GridValue_checked_current ||
          this.seaW_Distribute_checked_wave ||
          this.seaW_GridValue_checked_wave
        )
      )
        return;
      let showSeaWeatherInfoWin = true;
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer && layer.get("id") && layer.get("id") !== "_gridValue")
          showSeaWeatherInfoWin = false;
      });
      if (showSeaWeatherInfoWin) this.addSeaWeatherInfoWin(evt.coordinate);
    },

    // 获取点位信息
    getWeatherValue(coordinate) {
      let isValue = function isValue(x) {
        return x !== null && x !== undefined;
      };
      let interpolate = (x, y, g00, g10, g01, g11) => {
        let rx = 1 - x;
        let ry = 1 - y;
        return g00 * rx * ry + g10 * x * ry + g01 * rx * y + g11 * x * y;
      };
      const λ = coordinate[0];
      const φ = coordinate[1];
      const λ0 = this.weatherParam.minLon;
      const φ0 = this.weatherParam.maxLat;
      const maxLon = this.weatherParam.maxLon;
      const minLat = this.weatherParam.minLat;
      const Δλ = this.weatherParam.xScale;
      const Δφ = this.weatherParam.yScale;
      if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat) {
        return -1;
      }
      let grid = this.weatherParam.posValue;
      let i = (λ - λ0) / Δλ;
      let j = (φ0 - φ) / Δφ;
      let fi = Math.floor(i),
        ci = fi + 1;
      let fj = Math.floor(j),
        cj = fj + 1;
      let row = grid[fj];
      // console.log(row);
      if (row) {
        let g00 = row[fi].value;
        let g10 = row[ci].value;
        if (ci === row.length) g10 = row[row.length - 1].value;
        row = grid[cj];
        if (cj === grid.length) row = grid[grid.length - 1];
        if (isValue(g00) && isValue(g10)) {
          let g01 = row[fi].value;
          let g11 = row[ci].value;
          if (ci === row.length) g11 = row[row.length - 1].value;

          if (isValue(g01) && isValue(g11)) {
            return [
              fj,
              fi,
              interpolate(i - fi, j - fj, g00, g10, g01, g11),
              grid[fj][fi].angle,
            ];
          }
        }
      }
    },

    // 获取风级
    getWindLevel(value) {
      let level = 0;
      if (value <= 0.2) level = 0;
      else if (value <= 1.5) level = 1;
      else if (value <= 3.3) level = 2;
      else if (value <= 5.4) level = 3;
      else if (value <= 7.9) level = 4;
      else if (value <= 10.7) level = 5;
      else if (value <= 13.8) level = 6;
      else if (value <= 17.1) level = 7;
      else if (value <= 20.7) level = 8;
      else if (value <= 24.4) level = 9;
      else if (value <= 28.4) level = 10;
      else if (value <= 32.6) level = 11;
      else level = 12;
      return level;
    },

    // 信息窗口
    addSeaWeatherInfoWin(coordinate) {
      this.$refs.mapCom.removeLightMarkerLayer();
      this.$refs.mapCom.removeSeaWeatherInfo();
      this.chartClosed();
      if (!this.weatherParam) return;
      const weatherValue = this.getWeatherValue(coordinate);
      const data = weatherValue[2];
      const imglat = weatherValue[0];
      const imglon = weatherValue[1];
      const angle = weatherValue[3];
      if (!data) return;
      let showData = null,
        minValue = null,
        maxValue = null,
        title = null,
        chartParam = {};
      // let time = this.time + "时";
      let time = this.$timestampTostr(this.time);

      let pos =
        (coordinate[0] < 180
          ? coordinate[0].toFixed(4) + "°E"
          : (360 - coordinate[0]).toFixed(4) + "°W") +
        " ," +
        (coordinate[1] > 0
          ? coordinate[1].toFixed(4) + "°N"
          : -coordinate[1].toFixed(4) + "°S");

      switch (this.weatherParam.type) {
        case "wind":
          showData = {
            //20211110--老版本
            // 风速: data.value + "m/s",
            // 风向: data.angle + "°",
            风速: data.toFixed(3) + "m/s",
            风级: this.getWindLevel(data) + "级",
            风向: angle.toFixed(3) + "°",
            时间: time,
            坐标: pos,
          };
          title = "风场";
          minValue = this.weatherParam.minValue;
          maxValue = this.weatherParam.maxValue;
          break;
        case "current":
          showData = {
            流速: data.toFixed(3) + "m/s",
            流向: angle.toFixed(3) + "°",
            时间: time,
            坐标: pos,
          };
          title = "流场";
          minValue = this.weatherParam.minValue;
          maxValue = this.weatherParam.maxValue;
          break;
        case "wave":
          showData = {
            海浪: data.toFixed(3) + "米",
            坐标: pos,
          };
          title = "海浪";
          minValue = this.weatherParam.minValue;
          maxValue = this.weatherParam.maxValue;
          break;
        case "fvcom":
          showData = {
            潮位: data.toFixed(3) + "米",
            坐标: pos,
          };
          title = "潮位";
          minValue = this.weatherParam.minValue;
          maxValue = this.weatherParam.maxValue;
          break;
      }
      chartParam.type = this.weatherParam.type;
      chartParam.lat = coordinate[1];
      chartParam.lon = coordinate[0];
      chartParam.imglat = imglat;
      chartParam.imglon = imglon;
      if (Number(data) >= minValue && Number(data) <= maxValue) {
        this.$refs.mapCom.addSeaWeatherInfo(
          coordinate,
          showData,
          title,
          (e) => {
            this.showChart(chartParam);
          }
        );
      }
    },

    // 判断数据加载成功
    getWeaterPointData(coordinate) {
      if (this.weatherParam && this.weatherParam.posValue) {
        const data = this.getWeatherValue(coordinate)[2];
        if (data) {
          return { value: data };
        } else {
          return null;
        }
      } else {
        return new Promise((resolve) => {
          const fun = () => {
            let timeOut = setTimeout(() => {
              if (this.weatherParam && this.weatherParam.posValue) {
                let data = this.getWeatherValue(coordinate)[2];
                clearTimeout(timeOut);
                resolve({ value: data });
              } else {
                fun();
              }
            });
          };
          fun();
        });
      }
    },

    // 请求数据
    async addWeather(type, time) {
      this.initLayer();
      if (!time) return;
      this.time = time;
      time = this.$parseTime(time, "{y}{m}{d}{h}");

      this.weatherParam = null;
      this.seaWeatherData = null;
      this.$refs.mapCom.removeSeaWeatherInfo();

      let resolution, center;
      switch (type) {
        case "wind":
          resolution = "0.25";
          center = [120, 36];
          this.pathType = type;
          //   this.seaW_Particle_checked_wind = true;
          //   this.seaW_Distribute_checked_wind = true;
          //   this.seaW_GridAngle_checked_wind = true;
          //   this.seaW_GridValue_checked_wind = true;
          this.legendFlag_wind = true;
          this.legendFlag_current = false;
          this.legendFlag_wave = false;
          this.legendFlag_typhoon = false;
          break;
        case "current":
          resolution = "0.001";
          center = [120, 36];
          this.pathType = type;
          // if (this.seaW_Particle_checked_current === null)
          //   this.seaW_Particle_checked_current = true;
          // if (this.seaW_Distribute_checked_current === null)
          //   this.seaW_Distribute_checked_current = true;
          // if (this.seaW_GridAngle_checked_current === null)
          //   this.seaW_GridAngle_checked_current = true;
          // if (this.seaW_GridValue_checked_current === null)
          //   this.seaW_GridValue_checked_current = true;
          this.legendFlag_wind = false;
          this.legendFlag_current = true;
          this.legendFlag_wave = false;
          this.legendFlag_typhoon = false;
          break;
        case "wave":
          resolution = "0.05";
          center = [120, 36];
          this.pathType = type;
          if (this.seaW_Distribute_checked_wave === null)
            this.seaW_Distribute_checked_wave = true;
          if (this.seaW_GridValue_checked_wave === null)
            this.seaW_GridValue_checked_wave = true;
          this.legendFlag_wind = false;
          this.legendFlag_current = false;
          this.legendFlag_wave = true;
          this.legendFlag_typhoon = false;
          break;
        case "typhoon":
          this.addTyphoonList();
          break;
        default:
          break;
      }
      //非相关图层不添加
      if (type !== "wind" && type !== "wave" && type !== "current") return;
      const path =
        this.baseUrl +
        time.replace(/-/g, "").replace(/ /g, "") +
        "_" +
        resolution;
      const param = await this.getImageParam(path + ".json");
      param.xScale = Number(resolution);
      param.yScale = Number(resolution);
      param.maxLon = Number(param.Lonmax);
      param.minLon = Number(param.Lonmin);
      param.maxLat = Number(param.Latmax);
      param.minLat = Number(param.Latmin);
      param.maxValueR = Number(param.RMax);
      param.minValueR = Number(param.RMin);
      param.maxValueG = Number(param.GMax);
      param.minValueG = Number(param.GMin);
      param.lngs = [];
      param.lats = [];
      param.center = center;
      param.type = type;
      param.imageUrl = path + ".png";
      param.type = type;
      this.weatherParam = param;
      this.render(param);
    },

    // 格网数据获取
    async render(param) {
      let gridData = [];
      if (!param) return;
      const imageData = await this.getImageData(param);
      const values = imageData[0];
      const width = imageData[1];
      const height = imageData[2];
      const posValue = imageData[3];
      const types = imageData[4];
      if (posValue) {
        this.weatherParam.posValue = posValue;
        //最大最小值获取
        this.weatherParam.posValue.map((v) => {
          v.map((vv) => {
            if (!this.weatherParam.maxValue)
              this.weatherParam.maxValue = vv.value;
            if (!this.weatherParam.minValue)
              this.weatherParam.minValue = vv.value;
            if (vv.value > this.weatherParam.maxValue)
              this.weatherParam.maxValue = vv.value;
            if (vv.value < this.weatherParam.minValue)
              this.weatherParam.minValue = vv.value;
          });
        });
        const header = {
          dx: (param.maxLon - param.minLon) / width,
          dy: (param.maxLat - param.minLat) / height,
          nx: width,
          ny: height,
          lo1: param.minLon,
          la1: param.maxLat,
          la2: 0,
          maxLon: param.maxLon,
          minLat: param.minLat,
          // feature: _coords[1]
        };
        values.map((v, k) => {
          let json = {
            header: JSON.parse(
              JSON.stringify(Object.assign(header, { type: types[k] }))
            ),
            data: v,
          };
          gridData.push(json);
        });
        // console.log("gridData", gridData);
        this.seaWeatherData = gridData;
        // this.addWeatherLayer(param.type,gridData);
      }
    },

    // 渲染数据
    addWeatherLayer(type, data) {
      if (
        (!this.seaWeatherData ||
          (this.seaWeatherData && this.seaWeatherData.length === 0)) &&
        (!data || (data && data.length === 0))
      )
        return;
      //风场
      this.seaW_Distribute_wind ? this.seaW_Distribute_wind.clearWeather() : "";
      this.seaW_Distribute_wind = null;
      this.seaW_Particle_wind ? this.seaW_Particle_wind.clearWeather() : "";
      this.seaW_Particle_wind = null;
      this.seaW_GridValue_wind ? this.seaW_GridValue_wind.clearWeather() : "";
      this.seaW_GridValue_wind = null;
      this.seaW_GridAngle_wind ? this.seaW_GridAngle_wind.clearWeather() : "";
      this.seaW_GridAngle_wind = null;
      //色带_wind
      if (!this.seaW_Distribute_wind && this.seaW_Distribute_checked_wind) {
        this.seaW_Distribute_wind = new SeaWeather_Distribute({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "wind",
        });
        this.seaW_Distribute_wind.appendTo(
          this.map,
          this.baseLayerLength + this.lLayerCount
        );
      }
      //粒子_wind
      if (!this.seaW_Particle_wind && this.seaW_Particle_checked_wind) {
        this.seaW_Particle_wind = new SeaWeather_Particle({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          colorScale: ["rgb(36,104, 180)"],
          weatherType: "wind",
        });
        this.seaW_Particle_wind.appendTo(
          this.map,
          this.baseLayerLength + this.lLayerCount + 1
        );
      }
      //数值_wind
      if (!this.seaW_GridValue_wind && this.seaW_GridValue_checked_wind) {
        this.seaW_GridValue_wind = new SeaWeather_GridValue({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "wind",
        });
        this.seaW_GridValue_wind.appendTo(
          this.map,
          this.baseLayerLength + this.lLayerCount + 2
        );
      }
      //风羽_wind
      if (!this.seaW_GridAngle_wind && this.seaW_GridAngle_checked_wind) {
        this.seaW_GridAngle_wind = new SeaWeather_GridAngle({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "wind",
        });
      }
      //流场
      this.seaW_Distribute_current
        ? this.seaW_Distribute_current.clearWeather()
        : "";
      this.seaW_Distribute_current = null;
      this.seaW_Particle_current
        ? this.seaW_Particle_current.clearWeather()
        : "";
      this.seaW_Particle_current = null;
      this.seaW_GridValue_current
        ? this.seaW_GridValue_current.clearWeather()
        : "";
      this.seaW_GridValue_current = null;
      //色带_current
      if (
        !this.seaW_Distribute_current &&
        this.seaW_Distribute_checked_current
      ) {
        this.seaW_Distribute_current = new SeaWeather_Distribute({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "current",
        });
        this.seaW_Distribute_current.appendTo(this.map, this.baseLayerLength);
      }
      //粒子_current
      if (!this.seaW_Particle_current && this.seaW_Particle_checked_current) {
        this.seaW_Particle_current = new SeaWeather_Particle({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "current",
        });
        this.seaW_Particle_current.appendTo(this.map, this.baseLayerLength + 1);
      }
      //数值_current
      if (!this.seaW_GridValue_current && this.seaW_GridValue_checked_current) {
        this.seaW_GridValue_current = new SeaWeather_GridValue({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "current",
        });
        this.seaW_GridValue_current.appendTo(
          this.map,
          this.baseLayerLength + 2
        );
      }
      //海浪
      this.seaW_Distribute_wave ? this.seaW_Distribute_wave.clearWeather() : "";
      this.seaW_Distribute_wave = null;
      this.seaW_GridValue_wave ? this.seaW_GridValue_wave.clearWeather() : "";
      this.seaW_GridValue_wave = null;
      //色带_wave
      if (!this.seaW_Distribute_wave && this.seaW_Distribute_checked_wave) {
        this.seaW_Distribute_wave = new SeaWeather_Distribute({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "wave",
        });
        this.seaW_Distribute_wave.appendTo(this.map, this.baseLayerLength);
      }
      //数值_wave
      if (!this.seaW_GridValue_wave && this.seaW_GridValue_checked_wave) {
        this.seaW_GridValue_wave = new SeaWeather_GridValue({
          data: data ? data : this.seaWeatherData,
          mapApi: this.mapApi,
          map: this.map,
          minValue: this.weatherParam.minValue,
          maxValue: this.weatherParam.maxValue,
          weatherType: "wave",
        });
        this.seaW_GridValue_wave.appendTo(this.map, this.baseLayerLength + 2);
      }
      this.addLandLayer(type, true);
    },

    addLandLayer(type, checked) {},

    //风场
    //默认
    changeWeatherDefault_wind(res) {
      if (res) {
        this.seaW_Distribute_checked_wind = true;

        this.seaW_Distribute_wind
          ? this.seaW_Distribute_wind.clearWeather()
          : "";
        this.seaW_Distribute_wind = null;
        if (!this.seaW_Distribute_wind && this.seaW_Distribute_checked_wind) {
          this.seaW_Distribute_wind = new SeaWeather_Distribute({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "wind",
          });
          this.seaW_Distribute_wind.appendTo(
            this.map,
            this.baseLayerLength + this.lLayerCount
          );
        }
      } else {
        this.seaW_Distribute_checked_wind = false;
        this.seaW_Distribute_wind
          ? this.seaW_Distribute_wind.clearWeather()
          : "";
        this.seaW_Distribute_wind = null;
      }
      this.infoFlag(res);
    },
    //风羽
    changeWeatherAngle_wind(res) {
      if (res) {
        this.seaW_GridAngle_checked_wind = true;

        this.seaW_GridAngle_wind ? this.seaW_GridAngle_wind.clearWeather() : "";
        this.seaW_GridAngle_wind = null;
        if (!this.seaW_GridAngle_wind && this.seaW_GridAngle_checked_wind) {
          this.seaW_GridAngle_wind = new SeaWeather_GridAngle({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "wind",
          });
        }
      } else {
        this.seaW_GridAngle_checked_wind = false;
        this.seaW_GridAngle_wind ? this.seaW_GridAngle_wind.clearWeather() : "";
        this.seaW_GridAngle_wind = null;
      }
      this.infoFlag(res);
    },
    //粒子
    changeWeatherParticle_wind(res) {
      if (res) {
        this.seaW_Particle_checked_wind = true;

        this.seaW_Particle_wind ? this.seaW_Particle_wind.clearWeather() : "";
        this.seaW_Particle_wind = null;
        if (!this.seaW_Particle_wind && this.seaW_Particle_checked_wind) {
          this.seaW_Particle_wind = new SeaWeather_Particle({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            colorScale: ["rgb(36,104, 180)"],
            weatherType: "wind",
          });
          this.seaW_Particle_wind.appendTo(
            this.map,
            this.baseLayerLength + this.lLayerCount + 1
          );
        }
      } else {
        this.seaW_Particle_checked_wind = false;
        this.seaW_Particle_wind ? this.seaW_Particle_wind.clearWeather() : "";
        this.seaW_Particle_wind = null;
      }
      this.infoFlag(res);
    },
    //数值
    changeWeatherValue_wind(res) {
      if (res) {
        this.seaW_GridValue_checked_wind = true;

        this.seaW_GridValue_wind ? this.seaW_GridValue_wind.clearWeather() : "";
        this.seaW_GridValue_wind = null;
        if (!this.seaW_GridValue_wind && this.seaW_GridValue_checked_wind) {
          this.seaW_GridValue_wind = new SeaWeather_GridValue({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "wind",
          });
          this.seaW_GridValue_wind.appendTo(
            this.map,
            this.baseLayerLength + this.lLayerCount + 2
          );
        }
      } else {
        this.seaW_GridValue_checked_wind = false;
        this.seaW_GridValue_wind ? this.seaW_GridValue_wind.clearWeather() : "";
        this.seaW_GridValue_wind = null;
      }
      this.infoFlag(res);
    },

    //流场
    //默认
    changeWeatherDefault_current(res) {
      if (res) {
        this.seaW_Distribute_checked_current = true;

        this.seaW_Distribute_current
          ? this.seaW_Distribute_current.clearWeather()
          : "";
        this.seaW_Distribute_current = null;
        if (
          !this.seaW_Distribute_current &&
          this.seaW_Distribute_checked_current
        ) {
          this.seaW_Distribute_current = new SeaWeather_Distribute({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "current",
          });
          this.seaW_Distribute_current.appendTo(this.map, this.baseLayerLength);
        }
        this.addLandLayer("current", true);
      } else {
        this.seaW_Distribute_checked_current = false;
        this.seaW_Distribute_current
          ? this.seaW_Distribute_current.clearWeather()
          : "";
        this.seaW_Distribute_current = null;
      }
      this.infoFlag(res);
    },
    //粒子
    changeWeatherParticle_current(res) {
      if (res) {
        this.seaW_Particle_checked_current = true;

        this.seaW_Particle_current
          ? this.seaW_Particle_current.clearWeather()
          : "";
        this.seaW_Particle_current = null;
        if (!this.seaW_Particle_current && this.seaW_Particle_checked_current) {
          this.seaW_Particle_current = new SeaWeather_Particle({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "current",
          });
          this.seaW_Particle_current.appendTo(
            this.map,
            this.baseLayerLength + 1
          );
        }
        this.addLandLayer("current", true);
      } else {
        this.seaW_Particle_checked_current = false;
        this.seaW_Particle_current
          ? this.seaW_Particle_current.clearWeather()
          : "";
        this.seaW_Particle_current = null;
      }
      this.infoFlag(res);
    },
    //数值
    changeWeatherValue_current(res) {
      if (res) {
        this.seaW_GridValue_checked_current = true;

        this.seaW_GridValue_current
          ? this.seaW_GridValue_current.clearWeather()
          : "";
        this.seaW_GridValue_current = null;
        if (
          !this.seaW_GridValue_current &&
          this.seaW_GridValue_checked_current
        ) {
          this.seaW_GridValue_current = new SeaWeather_GridValue({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "current",
          });
          this.seaW_GridValue_current.appendTo(
            this.map,
            this.baseLayerLength + 2
          );
        }
        this.addLandLayer("current", true);
        // console.log(this.map.getLayers().array_);
      } else {
        this.seaW_GridValue_checked_current = false;
        this.seaW_GridValue_current
          ? this.seaW_GridValue_current.clearWeather()
          : "";
        this.seaW_GridValue_current = null;
      }
      this.infoFlag(res);
    },

    //海浪
    //默认
    changeWeatherDefault_wave(res) {
      if (res) {
        this.seaW_Distribute_checked_wave = true;

        this.seaW_Distribute_wave
          ? this.seaW_Distribute_wave.clearWeather()
          : "";
        this.seaW_Distribute_wave = null;
        if (!this.seaW_Distribute_wave && this.seaW_Distribute_checked_wave) {
          this.seaW_Distribute_wave = new SeaWeather_Distribute({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "wave",
          });
          this.seaW_Distribute_wave.appendTo(this.map, this.baseLayerLength);
        }
        this.addLandLayer("wave", true);
      } else {
        this.seaW_Distribute_checked_wave = false;
        this.seaW_Distribute_wave
          ? this.seaW_Distribute_wave.clearWeather()
          : "";
        this.seaW_Distribute_wave = null;
      }
      this.infoFlag(res);
    },
    //数值
    changeWeatherValue_wave(res) {
      if (res) {
        this.seaW_GridValue_checked_wave = true;

        this.seaW_GridValue_wave ? this.seaW_GridValue_wave.clearWeather() : "";
        this.seaW_GridValue_wave = null;
        if (!this.seaW_GridValue_wave && this.seaW_GridValue_checked_wave) {
          this.seaW_GridValue_wave = new SeaWeather_GridValue({
            data: this.seaWeatherData,
            mapApi: this.mapApi,
            map: this.map,
            minValue: this.weatherParam.minValue,
            maxValue: this.weatherParam.maxValue,
            weatherType: "wave",
          });
          this.seaW_GridValue_wave.appendTo(this.map, this.baseLayerLength + 2);
        }
        this.addLandLayer("wave", true);
      } else {
        this.seaW_GridValue_checked_wave = false;
        this.seaW_GridValue_wave ? this.seaW_GridValue_wave.clearWeather() : "";
        this.seaW_GridValue_wave = null;
      }
      this.infoFlag(res);
    },

    infoFlag(flag) {
      let count = 0;
      if (this.seaW_Distribute_checked_wind) count++;
      if (this.seaW_Particle_checked_wind) count++;
      if (this.seaW_GridValue_checked_wind) count++;
      if (this.seaW_GridAngle_checked_wind) count++;
      if (this.seaW_Distribute_checked_current) count++;
      if (this.seaW_Particle_checked_current) count++;
      if (this.seaW_GridValue_checked_current) count++;
      if (this.seaW_Distribute_checked_wave) count++;
      if (this.seaW_GridValue_checked_wave) count++;
      if (count === 1 && flag) this.dealTimeLine(true);
      else if (count === 0 && !flag) {
        this.$refs.mapCom.removeSeaWeatherInfo();
        this.dealTimeLine(false);
      }
    },

    clearWeather(type) {
      switch (type) {
        case "wind":
        case "current":
        case "wave":
          this.clearSeaWeatherTar();
          break;
        case "typhoon":
          this.clearAllTyphoonRelavent();
          break;
        default:
          this.clearSeaWeatherTar();
          this.clearAllTyphoonRelavent();
          break;
      }
    },

    //当且切换跟气象互斥的板块或者是气象板块自身清掉气象对象
    clearSeaWeatherTar() {
      this.weatherParam = null;

      //风场
      this.legendFlag_wind = false;
      this.seaW_Distribute_wind ? this.seaW_Distribute_wind.clearWeather() : "";
      this.seaW_Distribute_wind = null;
      this.seaW_Particle_wind ? this.seaW_Particle_wind.clearWeather() : "";
      this.seaW_Particle_wind = null;
      this.seaW_GridValue_wind ? this.seaW_GridValue_wind.clearWeather() : "";
      this.seaW_GridValue_wind = null;
      this.seaW_GridAngle_wind ? this.seaW_GridAngle_wind.clearWeather() : "";
      this.seaW_GridAngle_wind = null;
      this.seaW_Distribute_checked_wind = false;
      this.seaW_Particle_checked_wind = false;
      this.seaW_GridValue_checked_wind = false;
      this.seaW_GridAngle_checked_wind = false;
      //流场
      this.legendFlag_current = false;
      this.seaW_Distribute_current
        ? this.seaW_Distribute_current.clearWeather()
        : "";
      this.seaW_Distribute_current = null;
      this.seaW_Particle_current
        ? this.seaW_Particle_current.clearWeather()
        : "";
      this.seaW_Particle_current = null;
      this.seaW_GridValue_current
        ? this.seaW_GridValue_current.clearWeather()
        : "";
      this.seaW_GridValue_current = null;
      this.seaW_Distribute_checked_current = false;
      this.seaW_Particle_checked_current = false;
      this.seaW_GridValue_checked_current = false;
      //海浪
      this.legendFlag_wave = false;
      this.seaW_Distribute_wave ? this.seaW_Distribute_wave.clearWeather() : "";
      this.seaW_Distribute_wave = null;
      this.seaW_GridValue_wave ? this.seaW_GridValue_wave.clearWeather() : "";
      this.seaW_GridValue_wave = null;
      this.seaW_Distribute_checked_wave = false;
      this.seaW_GridValue_checked_wave = false;
      //台风
      this.legendFlag_typhoon = false;

      this.$refs.mapCom.removeSeaWeatherInfo();
      this.seaWeatherData = null;
      this.chartClosed();
      this.addLandLayer("", false);
    },

    getImageData(param) {
      return new Promise((resolve) => {
        let imageValue = [];
        let all = {};
        all.ImgData = new Image();
        all.ImgData.crossOrigin = "Anonymous";
        all.ImgData.src = param.imageUrl;
        all.ImgData.onload = function () {
          let imgCanvas = document.createElement("canvas");
          imgCanvas.width = all.ImgData.width;
          imgCanvas.height = all.ImgData.height;

          const imageContext = imgCanvas.getContext("2d");
          imageContext.drawImage(all.ImgData, 0, 0);
          let imagedata = imageContext.getImageData(
            0,
            0,
            all.ImgData.width,
            all.ImgData.height
          ).data;
          let valuesR = [],
            valuesG = [],
            values = [],
            types = [],
            n = null,
            R = null,
            G = null,
            B = null,
            valueR = null,
            valueG = null,
            computeWindUV = null,
            uv_value = null;
          for (let i = 0; i < all.ImgData.height; i++) {
            imageValue[i] = new Array();
            for (let j = 0; j < all.ImgData.width; j++) {
              n = i * 4 * all.ImgData.width + j * 4;
              R = imagedata[n];
              G = imagedata[n + 1];
              B = imagedata[n + 2];

              switch (param.type) {
                case "wind":
                case "current":
                  if (R == 0 && G == 0) {
                    imageValue[i][j] = 0;
                    valuesR.push(0);
                    valuesG.push(0);
                    continue;
                  }
                  valueR =
                    param.minValueR +
                    (R * (param.maxValueR - param.minValueR)) / 255;
                  valueG =
                    param.minValueG +
                    (G * (param.maxValueG - param.minValueG)) / 255;
                  valuesR.push(valueR);
                  valuesG.push(valueG);
                  computeWindUV = (u, v) => {
                    var value = Math.sqrt(u * u + v * v);
                    var angle = Math.acos(u / value);
                    if (v < 0) {
                      angle = -angle;
                    }
                    angle = Math.round((angle / Math.PI) * 180 + 90);
                    if (angle < 0) {
                      angle = angle + 360;
                    }
                    angle = Math.round(360 - angle);
                    value = value.toFixed(2);
                    return {
                      value: parseFloat(value),
                      angle: angle,
                    };
                  };
                  uv_value = computeWindUV(valueR, valueG);
                  imageValue[i][j] = {
                    value: uv_value.value,
                    angle: uv_value.angle,
                    uv: [valueR, valueG],
                  };
                  break;
                case "wave":
                  if (B == 255) {
                    imageValue[i][j] = 0;
                    valuesR.push(0);
                    continue;
                  }
                  valueR =
                    param.minValueR +
                    (R * (param.maxValueR - param.minValueR)) / 255;
                  valuesR.push(valueR);

                  imageValue[i][j] = {
                    value: valueR,
                  };
                  break;
              }
            }
          }
          valuesR.length !== 0 ? values.push(valuesR) : "";
          valuesG.length !== 0 ? values.push(valuesG) : "";
          switch (param.type) {
            case "wind":
              types.push("windU");
              types.push("windV");
              break;
            case "current":
              types.push("currentU");
              types.push("currentV");
              break;
            case "wave":
              types.push("waveU");
              types.push("waveV");
              break;
          }
          resolve([
            values,
            all.ImgData.width,
            all.ImgData.height,
            imageValue,
            types,
          ]);
        };
      });
    },

    getImageParam(url) {
      return new Promise((resolve) => {
        this.$axios({
          method: "get",
          url: url,
        }).then((result) => {
          resolve(result.data);
        });
      });
    },

    handleCheck(row) {
      this.$axios({
        method: "get",
        url:
          "https://map.hengtianyi.com/api/getTyphoonRoute.json?typhoonId=" +
          row.TYPHOON_ID,
        headers: {
          "Content-Type": "application/json;charset=utf8",
          token: "XG5medfPW41LQZ0sj8WXPbgGaNq9hh3MMzkGIETVws",
        },
      })
        .then((res) => {
          if (res && res.data && res.data.success)
            this.addTyphoon(res.data.result);
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {});
    },

    handleUnCheck(row) {
      if (row) this.clearTyphoon(row);
      else this.clearAllTyphoon();
    },

    addTyphoonList() {
      //显示台风列表
      this.$refs.typhoonList.changeTyphoonListStatus(true);
    },

    /**
     * 添加台风
     */
    addTyphoon(typhoonData) {
      if (!typhoonData) return;
      this.$refs.typhoon.drawTyphoon(typhoonData);
    },
    /**
     * 移除台风
     */
    clearTyphoon(data) {
      if (!data) return;
      let id = data.TYPHOON_ID ? data.TYPHOON_ID : data;
      this.$refs.typhoon.clearTyphoon(id);
      this.$refs.mapCom.closeInfoWinOver();
    },
    /**
     * 移除所有台风
     */
    clearAllTyphoonRelavent() {
      this.clearAllTyphoon();
    },
    clearAllTyphoon() {
      this.$refs.typhoon.clearAllTyphoon();
      this.$refs.mapCom.closeInfoWinOver();
    },
    /**
     * 显示图表历史数据
     */
    showChart(param) {
      this.imgPoint = param;
      switch (param.type) {
        case "wind":
          this.showWindBarbsChart = true;
          break;
        case "current":
          this.showCurrentChart = true;
          break;
        case "wave":
          this.showWaveChart = true;
          break;
      }
    },
    chartClosed() {
      this.showWindBarbsChart = false;
      this.showCurrentChart = false;
      this.showWaveChart = false;
    },
  },
  beforeDestroy() {
    this.$mapConfig.baseLayerLength = false;
    this.clearWeather();
  },
};
</script>
<style lang="scss" scoped>
.legend {
  position: absolute;
  z-index: 99;
  top: 19%;
  // right: 1rem;
  // transform: translate(0, -35%);
  width: 2.5rem;
}
.check-items {
  position: absolute;
  right: 1rem;
  bottom: 10rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  border: 0.02rem solid #ffffff;
  border-radius: 0.02rem;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.5rem 1rem;

  div {
    margin: 0.06rem 0;
  }

  ::v-deep {
    .el-switch__label {
      color: #ffffff;
    }
    .el-switch__core {
      border-color: rgba(255, 255, 255, 0.4);
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 6px;

      &::after {
        left: 2px;
        border-radius: 25%;
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
    .el-switch.is-checked .el-switch__core {
      border-color: #fdd701;
      background-color: rgba(41, 48, 54, 0.9);

      &::after {
        background-color: #fed900;
        left: 100%;
        margin-left: -18px;
      }
    }
  }
}
</style>