class GridAngle {
    constructor(props) {
        //这里初始化class的时候需要传map对象进来
        this.map = props.map
        this.id = props.id ? props.id : '_gridAngle'
        this.mapApi = props.mapApi
        this.disX = props.disX ? props.disX : 100
        this.disY = props.disY ? props.disY : 100
        this.extent = props.extent ? props.extent : [115.94182490303399, 33.846405515799184, 124.16724989874709, 38.846405515799184]
        this.gridLayer = null
        this.grid = null
        this.gridSource = null
        this.gridValue = props.gridValue
        this.moveend = null
        this.movestart = null
        this.svg = null
        this.init()
    }
    init() {
        this.removeGrid()
    }
    insertElement(tagName, options, father) {
        var svgTags = ['svg', 'g', 'path', 'filter', 'animate', 'marker', 'line', 'polyline', 'rect', 'circle', 'ellipse', 'polygon'];
        let newElement;
        if (svgTags.indexOf(tagName) >= 0) {
            newElement = document.createElementNS("http://www.w3.org/2000/svg", tagName);
        } else {
            newElement = document.createElement(tagName);
        }
        if (options) {
            if (options.css) {
                newElement.style.cssText = options.css;
            }
            if (options.props) {
                for (var key in options.props) {
                    newElement.setAttribute(key, options.props[key])
                }
            }
        }
        if (father) {
            father.append(newElement);
        }
        return newElement;
    }
    addSvg() {
        const ele = document.getElementsByClassName("ol-unselectable ol-layers")[0].children[0]
        const size = this.map.getSize()
        var svg = this.svg = this.insertElement('svg', null, ele)
        const width = size[0] + 'px';
        const height = size[1] + 'px';
        svg.setAttribute('style', 'position:absolute;left:0px;top:0px;width:' + width + ';height:' + height + ';')
        svg.setAttribute("width", '100%');
        svg.setAttribute("height", '100%');
        svg.setAttribute("id", 'seaWeather_Angle');
    }
    addGrid() {
        var fun = () => {
            this.removeGridAngle()
            this.addSvg()
            this.createGrid()
            this.addXYAngle()
            // this.addGridLine()
            // this.addExtent()
        }
        var fun1 = () => {
            this.removeGridAngle()
        }
        fun1()
        fun()
        this.moveend = this.map.on("moveend", () => {
            fun()
        })
        this.movestart = this.map.on("movestart", () => {
            fun1()
        })
        return this
    }
    removeGrid() {
        this.removeGridAngle()
        this.mapApi.unByKey(this.moveend);
        this.mapApi.unByKey(this.movestart);
        return this
    }
    removeGridAngle() {
        if (document.getElementById('seaWeather_Angle')) document.getElementById('seaWeather_Angle').remove()
        return this
    }
    getLayer(layerId, isFullLayer) {
        let layer = null;
        if (isFullLayer) {
            this.map.getLayers().array_.map((v) => {
                if (v && v.get("id") && v.get("id") === layerId) {
                    layer = v;
                }
            });
        } else {
            this.map.getLayers().array_.map((v) => {
                if (v && v.get("id") && v.get("id").indexOf(layerId) > -1) {
                    layer = v;
                }
            });
        }
        return layer;
    }
    getExtent(ex) {
        let tar = {}
        const extent = () => {
            ex = ex ? ex : this.map.getView().calculateExtent()
            tar.extent = ex
            return tar
        }
        const expand = (value) => {
            ex[0] += value
            ex[1] += value
            ex[2] -= value
            ex[3] -= value
            tar.extent = ex
            return tar
        }
        const getGeom = () => {
            var _viewGeom = new this.mapApi.fromExtent(ex);
            tar.geom = _viewGeom
            return tar
        }
        tar.expand = expand
        tar.getGeom = getGeom
        extent()
        return tar
    }
    clearFeatures() {
        if (this.gridSource) this.gridSource.clear()
        return this
    }
    compareExtent(extent) {
        let mapExtent = [], returnExtent = []
        mapExtent = this.getExtent().extent
        extent[0] < mapExtent[0] ? returnExtent[0] = mapExtent[0] : returnExtent[0] = extent[0]
        extent[1] < mapExtent[1] ? returnExtent[1] = mapExtent[1] : returnExtent[1] = extent[1]
        extent[2] > mapExtent[2] ? returnExtent[2] = mapExtent[2] : returnExtent[2] = extent[2]
        extent[3] > mapExtent[3] ? returnExtent[3] = mapExtent[3] : returnExtent[3] = extent[3]
        // console.log('mapExtent:', mapExtent)
        // console.log('returnExtent:', returnExtent)
        return returnExtent
    }
    createGrid() {
        let extent = null
        if (this.extent) extent = this.compareExtent(this.getExtent(this.extent).extent)
        else {
            extent = this.getExtent(this.extent).extent
        }
        const disX = this.disX
        const disY = this.disY
        const pix1 = this.map.getPixelFromCoordinate([extent[0], extent[1]])
        const pix2 = this.map.getPixelFromCoordinate([extent[2], extent[3]])
        // console.log(pix1, pix2)
        // const pos1 = this.map.getCoordinateFromPixel(pix1)
        // const pos2 = this.map.getCoordinateFromPixel(pix2)
        // console.log(pos1, pos2)
        let xCount = parseInt((pix2[0] - pix1[0]) / disX)
        let yCount = Math.abs(parseInt((pix2[1] - pix1[1]) / disY))
        const xd = (pix2[0] - pix1[0]) % disX
        const yd = (pix2[1] - pix1[1]) % disY
        // let startX = pix1[0] + xd / 2
        // let startY = pix1[1] + yd / 2
        let startX = pix1[0] + xd / 2 - disX
        let startY = pix1[1] + yd / 2 + disY
        xCount = xCount + 2
        yCount = yCount + 2
        // yCount += 1
        // console.log([startX, startY])
        let grid = {
            nodePixelXY: [],
            nodePosXY: [],
            centerPixelXY: [],
            centerPosXY: [],
            nodePixelYX: [],
            nodePosYX: [],
            centerPixelYX: [],
            centerPosYX: [],
            count: 0
        }
        const that = this
        const getXYGrid = function () {
            for (let i = 0, x = 0, y = 0, centerX = 0, centerY = 0, nodePosXY = 0, centerPosXY = 0; i <= xCount; i++) {
                grid.nodePixelXY[i] = []
                grid.nodePosXY[i] = []
                grid.centerPixelXY[i] = []
                grid.centerPosXY[i] = []
                x = startX + disX * i
                centerX = x + disX / 2
                for (let j = 0; j <= yCount; j++) {
                    y = startY - disY * j
                    centerY = y - disY / 2
                    nodePosXY = that.map.getCoordinateFromPixel([x, y])
                    centerPosXY = that.map.getCoordinateFromPixel([centerX, centerY])
                    grid.nodePixelXY[i][j] = [x, y]
                    grid.centerPixelXY[i][j] = [centerX, centerY]
                    grid.nodePosXY[i][j] = nodePosXY
                    grid.centerPosXY[i][j] = centerPosXY
                    grid.count++
                }
            }
        }
        const getYXGrid = function () {
            for (let i = 0, x = 0, y = 0, centerX = 0, centerY = 0, nodePosYX = 0, centerPosYX = 0; i <= yCount; i++) {
                grid.nodePixelYX[i] = []
                grid.nodePosYX[i] = []
                grid.centerPixelYX[i] = []
                grid.centerPosYX[i] = []
                y = startY - disY * i
                centerY = y - disY / 2
                for (let j = 0; j <= xCount; j++) {
                    x = startX + disX * j
                    centerX = x + disX / 2
                    nodePosYX = that.map.getCoordinateFromPixel([x, y])
                    centerPosYX = that.map.getCoordinateFromPixel([centerX, centerY])
                    grid.nodePixelYX[i][j] = [x, y]
                    grid.centerPixelYX[i][j] = [centerX, centerY]
                    grid.nodePosYX[i][j] = nodePosYX
                    grid.centerPosYX[i][j] = centerPosYX
                }
            }
        }
        getXYGrid()
        getYXGrid()
        this.grid = grid
        return this
    }

    addXYAngle() {
        let isValue = function isValue(x) {
            return x !== null && x !== undefined;
        };
        let computeWindUV = (u, v) => {
            var value = Math.sqrt(u * u + v * v);
            var angle = Math.acos(u / value);
            if (v < 0) {
                angle = -angle;
            }
            angle = Math.round((angle / Math.PI) * 180 + 90);
            if (angle < 0) {
                angle = angle + 360;
            }
            angle = Math.round(360 - angle);
            value = value.toFixed(2);
            return {
                value: parseFloat(value),
                angle: angle,
            };
        };
        let chooseWindLevel = function (windValue) {
            var windLevel;
            if (windValue >= 0 && windValue <= 1.5) {
                windLevel = 1;
            } else if (windValue > 1.5 && windValue <= 3.3) {
                windLevel = 2;
            } else if (windValue > 3.3 && windValue <= 5.4) {
                windLevel = 3;
            } else if (windValue > 5.4 && windValue <= 7.9) {
                windLevel = 4;
            } else if (windValue > 7.9 && windValue <= 10.7) {
                windLevel = 5;
            } else if (windValue > 10.7 && windValue <= 13.8) {
                windLevel = 6;
            } else if (windValue > 13.8 && windValue <= 17.1) {
                windLevel = 7;
            } else if (windValue > 17.1 && windValue <= 20.7) {
                windLevel = 8;
            } else if (windValue > 20.7 && windValue <= 24.4) {
                windLevel = 9;
            } else if (windValue > 24.4 && windValue <= 28.4) {
                windLevel = 10;
            } else if (windValue > 28.4 && windValue <= 32.6) {
                windLevel = 11;
            } else if (windValue > 32.6 && windValue <= 36.9) {
                windLevel = 12;
            } else if (windValue > 36.9 && windValue <= 41.1) {
                windLevel = 13;
            } else if (windValue > 41.1 && windValue <= 46.1) {
                windLevel = 14;
            } else if (windValue > 46.1 && windValue <= 50.9) {
                windLevel = 15;
            } else {
                windLevel = 15;
            }
            return windLevel;
        };
        const grid = this.grid
        const getAngle = (pos) => {
            let value = this.gridValue.interpolate(pos[0], pos[1]);
            if (isValue(value[2]) && !isNaN(value[2]) && value[2] >= this.gridValue.minValue && value[2] <= this.gridValue.maxValue) {
                let level = chooseWindLevel(value[2])
                let angle = computeWindUV(value[0], value[1]).angle
                let xy = this.map.getPixelFromCoordinate(pos)
                addAngle(xy[0], xy[1], angle, level, this.svg)
            }

        }
        const addAngle = (x, y, angle, windLevel, svg) => {
            var path = this.insertElement('path', null, svg)
            var COLORSCALE = [
                [80, 61, 134], //1
                [83, 81, 179], //2
                [92, 95, 246], //3
                [70, 146, 169], //4
                [79, 167, 143], //5
                [139, 205, 54], //6
                [199, 162, 37], //7
                [184, 96, 37], //8
                [193, 40, 15], //9
                [103, 9, 63], //10
                [193, 43, 15], //11
                [117, 13, 55], //12
            ];
            var windLevelColor = COLORSCALE[0]
            switch (windLevel) {
                case 1:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 10) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z');
                    windLevelColor = COLORSCALE[0]
                    break;
                case 2:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z');
                    windLevelColor = COLORSCALE[1]
                    break;
                case 3:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + 'M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 10) + ' ' + (y + 10) + ' ' + 'Z');
                    windLevelColor = COLORSCALE[2]
                    break;
                case 4:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 20) + ' ' + (y + 10) + ' ' + 'Z');
                    windLevelColor = COLORSCALE[3]
                    break;
                case 5:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 20) + ' ' + (y + 10) + ' ' + 'Z M' + x + ' ' + (y + 20) + ' ' + 'L' + (x + 10) + ' ' + (y + 20) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[4]
                    break;
                case 6:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 20) + ' ' + (y + 10) + ' ' + 'Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[5]
                    break;
                case 7:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 20) + ' ' + (y + 10) + ' ' + 'Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 30) + ' ' + ' L' + (x + 10) + ' ' + (y + 30) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[6]
                    break;
                case 8:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 20) + ' ' + (y + 10) + ' ' + 'Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 30) + ' ' + ' L' + (x + 20) + ' ' + (y + 30) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[7]
                    break;
                case 9:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + x + ' ' + (y + 10) + ' ' + 'L' + (x + 20) + ' ' + (y + 10) + ' ' + 'Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 30) + ' ' + ' L' + (x + 20) + ' ' + (y + 30) + ' ' + '  Z M' + x + ' ' + (y + 40) + ' ' + ' L' + (x + 10) + ' ' + (y + 40) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[8]
                    break;
                case 10:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + (x + 20) + ' ' + y + ' ' + 'L' + x + ' ' + (y + 20) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[9]
                    break;
                case 11:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + (x + 20) + ' ' + y + ' ' + 'L' + x + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[10]
                    break;
                case 12:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + (x + 20) + ' ' + y + ' ' + ' L' + x + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 25) + ' ' + 'L' + (x + 20) + ' ' + (y + 25) + ' ' + '  Z');
                    windLevelColor = COLORSCALE[11]
                    break;
                case 13:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + (x + 20) + ' ' + y + ' ' + 'L' + x + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 25) + ' ' + 'L' + (x + 20) + ' ' + (y + 25) + ' ' + ' Z M' + x + ' ' + (y + 30) + ' ' + ' L' + (x + 20) + ' ' + (y + 30) + ' ' + '  Z');
                    windLevelColor = COLORSCALE[11]
                    break;
                case 14:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + (x + 20) + ' ' + y + ' ' + 'L' + x + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 25) + ' ' + 'L' + (x + 20) + ' ' + (y + 25) + ' ' + ' Z M' + x + ' ' + (y + 30) + ' ' + ' L' + (x + 20) + ' ' + (y + 30) + ' ' + '  Z M' + x + ' ' + (y + 35) + ' ' + ' L' + (x + 20) + ' ' + (y + 35) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[11]
                    break;
                case 15:
                    path.setAttribute("d", 'M' + x + ' ' + y + ' ' + 'L' + (x + 20) + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + y + ' ' + 'Z' + 'M' + x + ' ' + (y + 20) + ' ' + 'L' + x + ' ' + (y + 50) + ' ' + 'Z' + ' M' + (x + 20) + ' ' + y + ' ' + 'L' + x + ' ' + (y + 20) + ' ' + ' Z M' + x + ' ' + (y + 20) + ' ' + ' L' + (x + 20) + ' ' + (y + 20) + ' ' + ' Z M' + (x + 20) + ' ' + (y + 20) + ' ' + ' L' + x + ' ' + (y + 35) + ' ' + ' Z');
                    windLevelColor = COLORSCALE[11]
                    break;
                default:
                    break;

            }

            path.setAttribute("fill", 'none');
            path.setAttribute("stroke", 'rgb(' + windLevelColor[0] + ',' + windLevelColor[1] + ',' + windLevelColor[2] + ')');
            path.setAttribute("stroke-width", '5');
            path.setAttribute('transform', 'translate(' + (x + 10) + ',' + (y + 25) + ') scale(0.5) rotate(' + angle + ') translate(' + (-(x + 10)) + ',' + (-(y + 25)) + ')')
        }
        for (let m = 1; m < grid.centerPosXY.length - 2; m++) { //纵向超过范围的第一条（m = 0）跟最后一条(m = grid.centerPosXY.length - 2)过滤掉
            for (let n = 1, centerPosXY = 0; n < grid.centerPosXY[m].length - 2; n++) { //横向超过范围的第一条（n = 0）跟最后一条(n = grid.centerPosXY.length - 2)过滤掉
                centerPosXY = grid.centerPosXY[m][n];
                getAngle(centerPosXY)
            }
        }
        return this
    }
    addGridLine() {
        const grid = this.grid
        const addLine = (nodePos, nodePosNext) => {
            const line = new this.mapApi.LineString([nodePos, nodePosNext]);
            let _feature = new this.mapApi.Feature({
                geometry: line,
            });
            this.gridSource.addFeature(_feature)
        }
        const addGridXY = function () {
            for (let m = 1; m < grid.nodePosXY.length - 1; m++) { //纵向超过范围的第一条（m = 0）跟最后一条(m = grid.nodePosXY.length - 1)过滤掉
                for (let n = 0, nodePosXY = 0, nodePosNext = 0; n < grid.nodePosXY[m].length; n++) {
                    nodePosXY = grid.nodePosXY[m][n];
                    nodePosNext = grid.nodePosXY[m][n + 1];
                    if (nodePosNext) addLine(nodePosXY, nodePosNext)
                }
            }
        }
        const addGridYX = function () {
            for (let m = 1; m < grid.nodePosYX.length - 1; m++) { //横向超过范围的第一条（m = 0）跟最后一条(m = grid.nodePosYX.length - 1)过滤掉
                for (let n = 0, nodePosYX = 0, nodePosNext = 0; n < grid.nodePosYX[m].length; n++) {
                    nodePosYX = grid.nodePosYX[m][n];
                    nodePosNext = grid.nodePosYX[m][n + 1];
                    if (nodePosNext) addLine(nodePosYX, nodePosNext)
                }
            }
        }
        addGridXY()
        addGridYX()
        return this
    }
    addExtent() {
        const geom = this.getExtent(this.extent).getGeom().geom
        const fea = new this.mapApi.Feature({
            geometry: geom
        })
        this.gridSource.addFeature(fea)
        return this
    }
}
export default GridAngle
