const state = {
  selected: {},//pageType,id,isPosTo,name
  change: 0,
}
const mutations = {
  SET_SELECTED: (state, param) => {
    state.selected = Object.assign(param, { change: !state.change })
  },
}
const actions = {
  setSelected(context, param) {
    context.commit('SET_SELECTED', param)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}