const state = {
  selected: {
    type: null,//定位类型   0:单纯定位;1:查看船只详情
    deviceType: null, //设备类型 '0':北斗; '1':AIS; '2':雷达; '0,1':北斗+AIS; 
    position: [],
    data: {},
    change: null,
    isOnline: '1', //'1': '在线', '0': '离线', （所有含有状态的船只均要加）
    showType: "AIS（✓）",    //"北斗（✓）","AIS（✓）" ,"斗（✓）,AIS","北斗,AIS（✓）"    搭载的设备
    dataType: '1',            // "1"：'ais',"2"：'bd',     最新数据(只有融合数据需要加)
  },
}
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected)
        if (param[v] !== undefined) state.selected[v] = param[v]
      state.selected['change'] = !state.selected['change']
    })
  },
}
const actions = {
  setSelected(context, param) {
    context.commit('SET_SELECTED', param)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}