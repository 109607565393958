<template>
<div></div>
  <!-- <hl-dialog :title="title"
             :bottom="bottom"
             :right="right"
             :width="width"
             :height="height"
             topeArea="t"
             :bgimg="require('../../../assets/image/map/information_box.png')"
             ref="Dialog">
    <div class="content"
         v-for="(item, index) in legendData"
         :key="index">
      <div>
        <img :src="item.image"
             alt=""
             :style="{ width: '1.5rem', height: '1.5rem' }" />
      </div>
      <div>
        <font>{{ item.name }}</font>
      </div>
    </div>
  </hl-dialog> -->
</template>
<script>
// <script module = 'mapLegendContainer' lang="renderjs">
// import HlDialog from "../../../components/hlDialog/index.vue"
export default {
  name: "mapLegend",
  components: {
    // HlDialog,
  },
  props: ["title", "legendData", "right", "bottom", "width", "height"],
  data () {
    return {};
  },
  inject: [],
  watch: {},
  onLoad () { },
  mounted () {
    // this.initMap(this.map)
  },
  methods: {
    // 初始化地图
    initMap (map) {
      this.map = map;
      this.$refs.mapCom.initMap(map);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  padding: 0.5rem 0 0 0;
}
.content > div {
  display: flex;
  align-items: center;
  color: #fff;
}
font {
  // vertical-align: -webkit-baseline-middle;
}
</style>