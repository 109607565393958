const state = {
  selected: {
    // 船名
    // 终端号
    // 船主
    // 时间
    // 位置
    // 航速
    // 航向
    // 透明
    item: { 船名: true, 终端号: true, 船主: true, 时间: true, 位置: true, 航速: true, 航向: true, 透明: true }, // 信息窗口显示字段
    fontColor: ["#FF0000", "#8A8A8A"], //单击信息窗口船舶颜色（根据离线在线判断）
    isOnlineShow: false, //离线显示
    shipNameShow: false, //名称显示
    manageInOutShow: false, // 管内管外
    touchTimeOut: 0 * 1000, // 悬浮延迟    
    change: null,
    shipAisCount: 0,
    shipBdCount: 0,
  },
}
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected)
        if (param[v] !== undefined) state.selected[v] = param[v]
      state.selected['change'] = !state.selected['change']
    })
  },
}
const actions = {
  setSelected(context, param) {
    context.commit('SET_SELECTED', param)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}