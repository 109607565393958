<template>
  <div class="mapTailContainer">
    <map-base ref="mapCom" :map="map" />
  </div>
</template>
<script>
// <script module = 'tailContainer' lang="renderjs">
import "./map-tail.css";
export default {
  name: "mapTail",
  props: ["map"],
  components: {},
  watch: {
    "mapTail.selected": {
      handler(v) {
        if (v) {
          this.$store.dispatch("mapTail/getSelected", this.getTailContainer(v));
        }
      },
      deep: true,
    },
    "mapTail.unSelected": {
      handler(v) {
        if (v) {
          this.clearShipTailRelevantAll(v);
        }
      },
      deep: true,
    },
    "mapInfoWin.selected": {
      handler(v) {
        if (v) {
          this.clearSingleTail({
            pageType: v.layerId,
            id: v.id,
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      mapTail: this.$store.getters.mapTail,
      mapInfoWin: this.$store.getters.mapInfoWin,
      //尾迹数组
      mapTailSpeedArray: [],
      //尾迹定时器
      tailIntervalArray: [],
      //船舶尾迹记录添加索引值
      recordIndex: null,
      //尾迹最后一个点处理函数
      mapTailArray: [],
      //尾迹加载方式
      tailLoadType: {
        0: "posTo", //更新并定位
        1: "update", //仅限更新
      },
    };
  },
  mounted() {},
  methods: {
    //添加尾迹html
    getTailContainer(param) {
      let html =
        '<div class="allCenter" id="' +
        param.id +
        '" data-id="' +
        param.id +
        '" data-name="' +
        param.name +
        '" data-pt="' +
        param.pageType +
        '" data-k="' +
        param.key +
        '">' +
        "<label>是否显示</label>" +
        '<input type="checkbox"/>' +
        "</div>";
      this.addTailEvent(param);
      return html;
    },
    //添加尾速字段
    addTailEvent(param) {
      let id = param.id;
      if (id) {
        const tailCheckedFun = (id) => {
          let timeOut = null;
          const fun = () => {
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
              let ele = document.getElementById(id);
              if (ele) {
                this.changeTailChecked(Object.assign(param, { type: 2 }));
                clearTimeout(timeOut);
                ele.addEventListener("click", (e) => {
                  if (e.target.checked) {
                    //尾迹最后一个点处理函数
                    this.dealTailArray(Object.assign(param, { type: 3 }));
                    //尾迹复选
                    this.mapTailSpeedArray.push(param);
                    //添加尾迹路径
                    this.setTail(
                      Object.assign(param, { loadType: this.tailLoadType[0] })
                    );
                  } else {
                    this.clearSingleTail(Object.assign(param, { type: 0 }));
                  }
                });
              } else {
                fun();
              }
            });
          };
          fun();
        };
        tailCheckedFun(id);
      }
    },
    /**
     * 尾迹数组处理
     * id：对应的id;
     * type: 0--删除;1--查询;2--添加;3--更新;
     */
    dealTailArray(param) {
      //1、param不存在；2、页面是单页面并且无节点id;此两种情况清空所有尾迹节点
      if (!param || (!param.pageType && !param.id)) {
        this.mapTailArray = [];
      }
      //页面存在且节点id不存在的情况下，则清空当前页面类型的所有尾迹
      else if (param.pageType && !param.id) {
        this.mapTailArray.map((v, k) => {
          if (v.pageType === param.pageType) {
            this.mapTailArray.splice(k, 1);
          }
        });
      }
      //节点id存在的情况下，如果页面类型不存在，则定义为单页面；反之页面类型存在，则默认当前页面
      else {
        if (!param.pageType) param.pageType = "singlePage";
        switch (param.type) {
          case "0": //删除
          case 0:
            this.mapTailArray.map((v, k) => {
              if (v.pageType + v.id === param.pageType + param.id) {
                this.mapTailArray.splice(k, 1);
              }
            });
            break;
          case "1": //查询
          case 1:
            this.mapTailArray.map(() => {
              // if (param.pageType + param.id === v.pageType + v.id) {
              // }
            });
            break;
          case "2": //添加
          case 2:
            this.mapTailArray.push(param);
            break;
          case "3": //更新
          case 3:
            {
              let existFlag = false;
              this.mapTailArray.map((v) => {
                if (param.pageType + param.id === v.pageType + v.id) {
                  existFlag = true;
                }
              });
              //如果不含有该条尾迹，则更新添加该条尾迹
              if (!existFlag) this.mapTailArray.push(param);
            }
            break;
          default:
            break;
        }
      }
    },
    /**
     * 清空单一尾迹
     */
    clearSingleTail(param) {
      //尾迹复选
      this.changeTailChecked(Object.assign(param, { type: 1 }));
      //尾迹当前路径
      this.removeTail(param);
      //尾迹数组处理
      this.dealTailArray(Object.assign(param, { type: 0 }));
      //尾迹控制容器当前记录
      this.removeMapTailContainerItem(param);
      //关闭当前尾迹定时
      this.dealMapTailInterval(Object.assign(param, { type: 0 }));
    },
    /**
     * 改变尾迹选中状态
     * id：dom对象id
     * type ：2-选中 1-取消 0-全部取选
     */
    changeTailChecked(param) {
      const pageType = param.pageType;
      const id = param.id;
      const type = param.type;
      let ele = document.getElementById(id);
      if (!type) {
        for (let i = 0; i < this.mapTailSpeedArray.length; i++) {
          if (this.mapTailSpeedArray[i].pageType === pageType) {
            this.mapTailSpeedArray.splice(i, 1);
            i--;
          }
        }
      } else if (type === 2 && ele) {
        this.mapTailSpeedArray.map((v) => {
          if (v.id === id && v.pageType === pageType) {
            ele.querySelector("input").checked = true;
            return;
          }
        });
      } else if (type === 1 && ele) {
        //默认取选当前需要操作的复选
        ele.querySelector("input").checked = false;
        this.mapTailSpeedArray.map((v, k) => {
          if (v.id === id && v.pageType === pageType) {
            this.mapTailSpeedArray.splice(k, 1);
            return;
          }
        });
      }
    },
    /**
     * 获取尾迹数据
     */
    async setTail(param) {
      let tailCoords = [];
      let data = await this.getData(param.key, param);
      if (data && data.length > 0) {
        data.map((v) => {
          if (v.longitude && v.latitude) {
            tailCoords.push([v.longitude, v.latitude]);
          }
        });
        //添加尾迹控制容器
        this.addMapTailContainer(param);
        //添加尾迹定时
        let tailInterval = setInterval(() => {
          this.setTail(param);
        }, 60 * 1000);
        this.dealMapTailInterval(
          Object.assign(param, { type: 1, interval: tailInterval })
        );
        //添加尾迹
        this.addTail(Object.assign(param, { tailCoords: tailCoords }));
      } else {
        this.$message.error("渔船" + param.name + "无尾迹");
        this.clearSingleTail(param);
      }
    },
    /**
     * 处理尾迹interval
     * type:1 添加尾迹 0 清空尾迹  -1 清空所有尾迹
     */
    dealMapTailInterval(param) {
      let type = param.type;
      let id = param.id;
      let pageType = param.pageType;
      if (type === 1) {
        if (this.tailIntervalArray.length === 0) {
          this.tailIntervalArray.push(param);
        } else {
          for (let i = this.tailIntervalArray.length - 1; i >= 0; i--) {
            if (
              this.tailIntervalArray[i].id === id &&
              this.tailIntervalArray[i].pageType === pageType
            ) {
              clearInterval(this.tailIntervalArray[i].interval);
              this.tailIntervalArray.splice(i, 1);
            }
          }
          this.tailIntervalArray.push(param);
        }
      } else if (type === 0) {
        this.tailIntervalArray.map((v, k) => {
          if (v.id === id && v.pageType === pageType) {
            clearInterval(v.interval);
            this.tailIntervalArray.splice(k, 1);
            return;
          }
        });
      } else {
        for (let i = 0; i < this.tailIntervalArray.length; i++) {
          if (this.tailIntervalArray[i].pageType === pageType) {
            clearInterval(this.tailIntervalArray[i].interval);
            this.tailIntervalArray.splice(i, 1);
            i--;
          }
        }
      }
    },
    /**
     * 移除尾迹控制容器记录
     */
    removeMapTailContainerItem(param) {
      const pageType = param.pageType;
      const id = param.id;

      let tailContentContainer = document.querySelector(
        "#tailContainerContent"
      );
      if (tailContentContainer) {
        let tailAllContainer = tailContentContainer.parentNode;
        //清空轨迹相关的div
        if (id) {
          let container = document.querySelector(
            "#tailContainer" + pageType + id
          );
          this.recordIndex = this.getIndex(container);
          container.remove();
        } else {
          let children = tailContentContainer.children;
          for (let i = children.length - 1; i >= 0; i--) {
            if (children[i].id.indexOf(pageType) > -1) {
              children[i].remove();
            }
          }
        }
        if (tailContentContainer.children.length === 0) {
          tailAllContainer.remove();
        }
      }
    },
    //获取轨迹记录索引值
    getIndex(ele) {
      let index = 0;
      let parent = ele.parentNode;
      let children = parent.children;
      for (let i = 0; i < children.length; i++) {
        if (children[i].id === ele.id) index = i;
      }
      return index;
    },
    //添加尾迹容器
    addMapTailContainer(param) {
      let id = param.id;
      let name = param.name;
      let pageType = param.pageType;
      let key = param.key;
      const tailEventListenser = (
        tailButtonId,
        stopTailId,
        clearTailId,
        retryDrawTailId
      ) => {
        //清空
        let eleClear = document.querySelector("#" + clearTailId);
        let handerClear = () => {
          this.clearSingleTail(param);
        };
        eleClear.addEventListener("click", handerClear);

        //刷新
        let eleRetry = document.querySelector("#" + retryDrawTailId);
        let handerRetry = () => {
          let ele = document.querySelector("#" + tailButtonId);
          this.setTail({
            name: ele.getAttribute("data-name"),
            id: ele.getAttribute("data-id"),
            pageType: ele.getAttribute("data-pt"),
            key: ele.getAttribute("data-k"),
            isPosTo: this.mapTail.isPosTo[1],
          });
        };
        eleRetry.addEventListener("click", handerRetry);
      };
      /**
       * 创建尾迹跟踪
       */
      const createTailContainer = () => {
        let tailContainerId = "tailContainer" + pageType + id;
        let tailButtonId = "tailButton" + pageType + id;
        let stopTailId = "stopTail" + pageType + id;
        let clearTailId = "clearTail" + pageType + id;
        let retryDrawTailId = "retryDrawTail" + pageType + id;
        //预先判断是否有重名id,如果有，则干掉重名id
        if (document.querySelector("#" + tailContainerId)) {
          this.removeMapTailContainerItem(param);
          this.removeTail(param);
        } else {
          this.recordIndex = null;
        }
        //加载尾迹的最外层容器
        if (!document.querySelector("#tailContainerAll")) {
          let tailContainerAll = document.createElement("div");
          tailContainerAll.id = "tailContainerAll";
          document
            .querySelector(".mapTailContainer")
            .appendChild(tailContainerAll);
          tailContainerAll.className = "tailContainerAll";
          tailContainerAll.innerHTML +=
            "<div class='tailContainerTitle' id='tailContainerTitle_tail'>渔船尾迹" +
            "<div class = 'folderTailContainer' id='folderTailContainer'>^</div>" +
            "</div>";
          tailContainerAll.innerHTML +=
            "<div class='tailContainerContent' id='tailContainerContent'></div>";

          let eleTitle = document.querySelector("#tailContainerTitle_tail");
          if (this.handerTitle)
            eleTitle.removeEventListener("click", this.handerTitle);
          this.handerTitle = () => {
            let eleContent = document.querySelector("#tailContainerContent");
            let elefolder = document.querySelector("#folderTailContainer");
            if (eleContent.style.display === "none") {
              elefolder.style.transform = "rotate(360deg)";
              eleContent.style.display = "block";
            } else {
              elefolder.style.transform = "rotate(180deg)";
              tailContainerAll.style.width = "auto";
              eleContent.style.display = "none";
            }
          };
          eleTitle.addEventListener("click", this.handerTitle);
        } else if (!document.querySelector("#tailContainerContent")) {
          document.querySelector("#tailContainerAll").innerHTML +=
            "<div class='tailContainerContent' id='tailContainerContent'></div>";
        }
        //添加尾迹记录
        let tailContainer = document.createElement("div");
        tailContainer.className = "tailContainer";
        tailContainer.id = tailContainerId;
        tailContainer.innerHTML =
          "<div id=" +
          tailButtonId +
          '  class="tailButton"' +
          " data-name=" +
          name +
          " data-id=" +
          id +
          " data-pt=" +
          pageType +
          " data-k=" +
          key +
          ">" +
          "<button id=" +
          clearTailId +
          ' class="tailButtonContent clearTail">清空尾迹</button>' +
          "<button id=" +
          retryDrawTailId +
          ' class="tailButtonContent retryDrawTail">刷新尾迹</button>' +
          '<font class="tailName">' +
          name +
          "</font>" +
          "</div>";
        let eleContent = document.querySelector("#tailContainerContent");
        //1、尚未添加节点或者没有孩子节点（无索引）
        //2、删除掉的是列表最后一个节点
        if (
          (!this.recordIndex && this.recordIndex != 0) ||
          eleContent.children.length === 0 ||
          eleContent.children.length === this.recordIndex
        ) {
          eleContent.appendChild(tailContainer);
        } else {
          eleContent.insertBefore(
            tailContainer,
            eleContent.children[this.recordIndex]
          );
        }
        eleContent.scrollTop = eleContent.scrollHeight;
        tailEventListenser(
          tailButtonId,
          stopTailId,
          clearTailId,
          retryDrawTailId
        );
      };
      createTailContainer();
    },
    /**
     * 清空尾迹相关所有
     */
    clearShipTailRelevantAll(param) {
      //尾迹复选
      this.changeTailChecked(param);
      //移除尾迹
      this.removeTail(param);
      //尾迹最后一个点的数组处理
      this.dealTailArray(param);
      //尾迹控制容器移除
      this.removeMapTailContainerItem(param);
      //关闭所有尾迹定时
      this.dealMapTailInterval(Object.assign(param, { type: -1 }));
    },
    /**
     * 移除尾迹
     */
    removeTail(param) {
      let id = param.id;
      let pageType = param.pageType;
      if (id) {
        this.$refs.mapCom.removeLayer("tailLineLayer" + pageType + id);
      } else {
        this.$refs.mapCom.removeLayer("tailLineLayer" + pageType);
      }
    },
    //添加尾迹线
    addTail(param) {
      /**
       * 计算拐点处小车的偏移角度
       * @param {*} startPosition  起点
       * @param {*} endPosition    终点
       */
      function Angle(startPosition, endPosition) {
        let isRigth = false;
        if (startPosition[0] < endPosition[0]) {
          //左->右
          isRigth = true;
        }
        let dx = startPosition[0] - endPosition[0];
        let dy = startPosition[1] - endPosition[1];
        let rotation = Math.atan2(dy, dx);
        if (rotation > Math.PI / 2) {
          //修正
          rotation = Math.PI - rotation;
        } else if (rotation < -1 * (Math.PI / 2)) {
          rotation = -1 * Math.PI - rotation;
        } else {
          rotation = -rotation;
        }
        return { isRigth: isRigth, rotation: rotation };
      }
      let id = param.id;
      let name = param.name;
      let pageType = param.pageType;
      let tailCoords = param.tailCoords;
      const isPosTo = param.isPosTo;
      this.$refs.mapCom.addLayer("tailLineLayer" + pageType + id);
      for (
        let tailCoordsIndex = 0;
        tailCoordsIndex < tailCoords.length;
        tailCoordsIndex++
      ) {
        let markerFeature = new this.mapApi.Feature(
          new this.mapApi.Point(tailCoords[tailCoordsIndex])
        );

        let imgData = null;
        let scale = 1;
        let rightImg = require("@/assets/image/map/shipLine/wj_2.png");
        let leftImg = require("@/assets/image/map/shipLine/wj_1.png");

        if (tailCoordsIndex === 0) {
          imgData = {};
        }
        if (tailCoords.length === 1) {
          imgData = {};
          leftImg = require("@/assets/image/map/shipLine/ic_ship4.png");
          rightImg = require("@/assets/image/map/shipLine/ic_ship4.png");
        } else {
          if (tailCoordsIndex === tailCoords.length - 1) {
            scale = 1;
            imgData = {};
            leftImg = require("@/assets/image/map/shipLine/ic_ship4.png");
            rightImg = require("@/assets/image/map/shipLine/ic_ship4.png");
          } else {
            scale = 0.7;
            imgData = tailCoords[tailCoordsIndex + 1]
              ? Angle(
                  tailCoords[tailCoordsIndex],
                  tailCoords[tailCoordsIndex + 1]
                )
              : {};
          }
        }
        markerFeature.setStyle(
          new this.mapApi.Style({
            image: new this.mapApi.Icon({
              src: imgData.isRigth ? rightImg : leftImg,
              scale: scale,
              rotateWithView: true,
              rotation: imgData.rotation
                ? imgData.rotation
                : Math.random() * 360,
            }),
            text: new this.mapApi.Text({
              font: "bold 20px sans-serif",
              offsetX: 0,
              offsetY: 0,
              scale: this.map.getView().getZoom() * 0.05,
            }),
          })
        );
        this.$refs.mapCom
          .getLayer("tailLineLayer" + pageType + id)
          .getSource()
          .addFeature(markerFeature);
        // }
        //最新位置点添加以及定位
        if (tailCoordsIndex === tailCoords.length - 1) {
          if (isPosTo === this.mapTail.isPosTo[1]) {
            if (tailCoords.length > 1) {
              //定位到最新位置
              this.map.getView().fit(
                this.$refs.mapCom.getExtent(
                  this.$refs.mapCom
                    .getLayer("tailLineLayer" + pageType + id)
                    .getSource()
                    .getExtent(),
                  0.0005
                )
              );
            } else {
              this.map.getView().animate({
                center: tailCoords[tailCoordsIndex],
              });
            }
          }
          //添加最新位置图标
          let markerFeature = new this.mapApi.Feature(
            new this.mapApi.Point(tailCoords[tailCoordsIndex])
          );
          markerFeature.setStyle(
            new this.mapApi.Style({
              image: new this.mapApi.Icon({
                src: require("@/assets/image/map/j.png"),
                scale: 1,
              }),
              text: new this.mapApi.Text({
                font: "bold 20px sans-serif",
                offsetX: 50,
                offsetY: 0,
                scale: this.map.getView().getZoom() * 0.1,
                text: name,
              }),
            })
          );
        }
        //尾迹绘制
        if (tailCoordsIndex != tailCoords.length - 1) {
          let line = new this.mapApi.LineString([
            tailCoords[tailCoordsIndex],
            tailCoords[tailCoordsIndex + 1],
          ]);
          let outputLength = this.mapApi.getLength(line, {
            projection: "EPSG:4326",
          });
          let feature = new this.mapApi.Feature({
            geometry: line,
          });
          if (outputLength > 1000) {
            outputLength =
              Math.round((outputLength / 1000) * 100) / 100 + " " + "km";
          } else {
            outputLength = Math.round(outputLength * 100) / 100 + " " + "m";
          }
          feature.setStyle(
            new this.mapApi.Style({
              fill: new this.mapApi.Fill({
                //填充样式
                color: "rgba(255, 0, 0, 0.4)",
              }),
              stroke: new this.mapApi.Stroke({
                lineDash: [1, 2, 3],
                color: "rgba(0, 0, 0, 1)",
                width: 1,
              }),
            })
          );
          this.$refs.mapCom
            .getLayer("tailLineLayer" + pageType + id)
            .getSource()
            .addFeature(feature);
        }
      }
      this.map.render();
    },
    getData(key, value) {
      value = {
        bdId: value.id,
        xbdId: value.id,
        aisId: value.id,
        startTime: new Date().getTime() - 24 * 60 * 60 * 1000,
        endTime: new Date().getTime(),
      };
      return new Promise((resolve) => {
        try {
          this.$store
            .dispatch(key, value)
            .then((res) => {
              resolve(res.result);
            })
            .catch(() => {})
            .finally(() => {
              resolve(require("./data/mapTailData.json"));
            });
        } catch (error) {
          console.log(error);
          resolve(require("./data/mapTailData.json"));
        }
      });
    },
  },
  beforeDestroy() {
    for (let i = 0; i < this.tailIntervalArray.length; i++) {
      clearInterval(this.tailIntervalArray[i].interval);
      this.tailIntervalArray.splice(i, 1);
      i--;
    }
  },
};
</script>
<style lang="scss">
.mapTailContainer {
  position: absolute;
  z-index: 999;
  right: 30rem;
  top: calc(6rem + 150px);
}
</style>