<template>
  <div>
    <van-popup
      v-model="visible"
      closeable
      close-icon="close"
      position="bottom"
      @close="close"
      :style="{ height: '50%' }"
    >
      <slot></slot>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
export default {
  components: {
    [Popup.name]: Popup,
  },
  name: "Popup",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  // position: {
  //   type: String,
  //   default: 'bottom',
  // },
  data() {
    return {};
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("closePop");
      },
    },
  },
  methods: {
    close() {
      this.$emit("closePop");
    },
  },
};
</script>

<style scoped></style>
