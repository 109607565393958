//数据静态页

const mapId = 'ygh-map'
// 系统的地图相关访问url
const mapUrl = {
  1: 'http://58.59.43.176:8001/gisdata/', // 数据地址
  2: 'http://58.59.43.124:8003/',
  3: 'http://192.168.0.35:8080/',
  4: 'http://192.168.0.59:8082/yinggehai/',
  5: 'http://192.168.0.124:8001/static/seamap/all/',//所有 //'http://192.168.0.124:8001/static/seamap/std/ 标准
}
const changeZoom = 11 //聚类级别
const clusterDistance = 100 //聚类距离
const heatBlur = 10 //热力图blur
const heatRadius = 5 //热力图radius
const layer = {
  0: {
    layerId: 'bd',
  },
  1: {
    layerId: 'ais',
  },
  4: {
    layerId: 'bdais',
    legendData: []
  },
  5: {
    layerId: 'comb',
    legendData: []
  },
  6: {
    layerId: "seaWeatherInfo"
  },
  7: {
    layerId: 'mapInfowinContainer'
  },
  8: {
    layerId: 'typhoon'
  },
  9: {
    layerId: 'service'
  },
  10: {
    layerId: 'bsd',
    legendData: []
  },
  11: {
    layerId: 'manageInOut',
    legendData: []
  },
}

const mapServices = {
  0: '',
  1: '',
  2: 'http://58.59.43.124:8003/geoserver/rizhaohaiyang/wms?service=WMS&version=1.1.0&request=GetMap&layers=rizhaohaiyang:breedArea&styles=&bbox=119.28555170000016,34.95717805999999,119.86734809999996,35.58959528000008&width=706&height=768&srs=EPSG:4326&format=application/openlayers',
  3: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:authorityBreedArea&styles=&bbox=119.29304722200004,34.95934166699999,120.05807527800005,35.33220277999999&width=768&height=374&srs=EPSG:4326&format=application/openlayers',
  4: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:yc&styles=&bbox=117.54978300000016,35.499968890000105,125.00012320000008,41.00067050000007&width=768&height=567&srs=EPSG:4326&format=application/openlayers',
  5: '',
  6: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:zhArea&styles=&bbox=121.95666666666705,27.000000000000114,128.53666666666697,30.666666669999984&width=768&height=427&srs=EPSG:4326&format=application/openlayers',
  7: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:zrArea&styles=&bbox=121.95666666666705,27.000000000000114,128.53666666666697,30.666666669999984&width=768&height=427&srs=EPSG:4326&format=application/openlayers',
  8: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:machineInnerWaterArea&styles=&bbox=119.0,27.0,124.0,40.0&width=330&height=768&srs=EPSG:4326&format=application/openlayers',
  9: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:sealIne3&styles=&bbox=117.64607041927178,26.442435789672384,124.16261503545888,40.8819058678063&width=346&height=768&srs=EPSG:4326&format=application/openlayers',
  10: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:chaoxianWaterArea&styles=&bbox=123.50000000000011,37.000000000000114,124.0,39.00000000000006&width=330&height=768&srs=EPSG:4326&format=application/openlayers',
  11: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:rusiaRelaventLayer&styles=&bbox=120.0,42.06498544850007,185.0,68.34466713519998&width=768&height=330&srs=EPSG:4326&format=application/openlayers',
  12: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hbhManageWaterLine&styles=&bbox=118.9999,34.9999,120.74779000000001,35.0001&width=768&height=330&srs=EPSG:4326&format=application/openlayers',
  13: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:japanSeaLine&styles=&bbox=-1.0,-1.0,0.0,0.0&width=768&height=768&srs=EPSG:4326&format=application/openlayers',
  14: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:shWaterLine&styles=&bbox=118.5,37.5,120.00000000000011,38.49999999999994&width=768&height=511&srs=EPSG:4326&format=application/openlayers',
  15: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:sjWaterLine&styles=&bbox=119.99990000000011,34.99990000000011,122.0001,35.00010000000012&width=768&height=330&srs=EPSG:4326&format=application/openlayers',
  16: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:slWaterArea&styles=&bbox=121.00000000000011,38.00000000000006,122.0,39.00000000000006&width=767&height=768&srs=EPSG:4326&format=application/openlayers',
  17: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:chinaSeaBaseLine&styles=&bbox=108.60000000000002,18.158329999999978,123.16166999999996,38.49999999999994&width=549&height=768&srs=EPSG:4326&format=application/openlayers',
  18: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanRelaventAllLayer&styles=&bbox=124.0,32.18333333000004,132.0,42.25472222&width=610&height=768&srs=EPSG:4326&format=application/openlayers',
  19: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:chaohanWaterAreaSeaLine3&styles=&bbox=123.93683292900005,32.14425274610005,125.37980584400009,38.05325679639998&width=330&height=768&srs=EPSG:4326&format=application/openlayers',
  20: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanWaterArea&styles=&bbox=124.00833330000012,32.18333333000004,128.5304592000001,37.000000000000114&width=721&height=768&srs=EPSG:4326&format=application/openlayers',
  21: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanWaterLineSingle&styles=&bbox=124.1508333,32.75,130.9241667,42.25472222&width=547&height=768&srs=EPSG:4326&format=application/openlayers',
  22: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanBaseLine12&styles=&bbox=124.83579016900012,32.93188990700014,129.83156594000002,38.62080898680006&width=674&height=768&srs=EPSG:4326&format=application/openlayers',
  23: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanUniqueAreaWest&styles=&bbox=124.0,37.1666666700001,127.00000000000011,38.050000000000125&width=768&height=330&srs=EPSG:4326&format=application/openlayers',
  24: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanUniqueArea&styles=&bbox=124.0,37.000000000000114,127.00000000000011,37.5&width=768&height=330&srs=EPSG:4326&format=application/openlayers',
  25: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanBaseLine15&styles=&bbox=124.77564888899997,32.88210591970005,129.8930398860001,38.639990224600126&width=682&height=768&srs=EPSG:4326&format=application/openlayers',
  100: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:rzAdminArea&styles=&bbox=118.60141499999999,34.993622000000016,119.920256,36.03840100000002&width=768&height=608&srs=EPSG:4326&format=application/openlayers',
  101: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:fishPortExtent&styles=&bbox=119.24320534741837,35.039478167029294,119.4611732288876,35.223253141388454&width=768&height=647&srs=EPSG:4326&format=application/openlayers',
  102: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:sealine2&styles=&bbox=117.62843509361431,26.449723687632417,124.16032498647995,40.895151776286134&width=347&height=768&srs=EPSG:4326&format=application/openlayers',
  103: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:land3&styles=&bbox=119.24996292700007,35.11932693700004,119.61490536000007,35.62003247800004&width=559&height=768&srs=EPSG:4326&format=application/openlayers',
  104: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:channel&styles=&bbox=119.369027,35.037556,119.993841,35.565157&width=768&height=648&srs=EPSG:4326&format=application/openlayers',
  105: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:seaWay&styles=&bbox=119.518611,35.0,120.5,35.394722&width=768&height=330&srs=EPSG:4326&format=application/openlayers',
  106: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:anchor&styles=&bbox=119.395832,35.004444,120.067224,35.481057&width=768&height=545&srs=EPSG:4326&format=application/openlayers',
  107: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:northSouthRoad&styles=&bbox=120.66499999999996,30.83333333333337,123.50000000000011,37.5&width=330&height=768&srs=EPSG:4326&format=application/openlayers',
  108: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:tempForbitSailzone&styles=&bbox=120.90333333333342,35.14999999999998,123.2833333333333,36.88333333333344&width=768&height=559&srs=EPSG:4326&format=application/openlayers',
  110: 'http://58.59.43.176:8001/geoserver/lanshan/wms?service=WMS&version=1.1.0&request=GetMap&layers=lanshan:hanWaterLineDouble&styles=&bbox=124.1508333,33.10222222,128.0,39.82166667&width=439&height=768&srs=EPSG:4326&format=application/openlayers'
}

const infoWinPosition = {
  'countX': 1,
  'countY': 6,
  'height': 0,
  'infoNormalObj': null,
  'pixel': {
    0: '', //居中
    1: '',//左上角
    2: '',//右上角
    3: '',//右下角
    4: '',//左下角
  }
}
export default {
  mapId: mapId,
  mapUrl: mapUrl,
  changeZoom: changeZoom,
  clusterDistance: clusterDistance,
  heatBlur: heatBlur,
  heatRadius: heatRadius,
  layer: layer,
  mapServices: mapServices,
  infoWinPosition: infoWinPosition,
}

