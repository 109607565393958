<template>
  <!-- 底部图表 -->
  <el-drawer
    :title="drawerTitle"
    class="drawer-chart"
    :visible.sync="drawer"
    :destroy-on-close="true"
    direction="btt"
    @closed="drawerClosed"
  >
    <x-chart
      class="high"
      v-loading="loading"
      id="windbarbs-chart"
      :option="option"
    ></x-chart>
  </el-drawer>
</template>

<script>
// <script module = 'windbarbsChartContainer' lang="renderjs">
import Highcharts from "highcharts";
import windbarb from "highcharts/modules/windbarb";
windbarb(Highcharts);

import XChart from "./Charts";

export default {
  name: "windbarbsChart",
  props: {
    // id: {
    //   type: String,
    //   default: 'windbarbs-chart'
    // },
    // option: {
    //   type: Object
    // }
    imgPoint: {
      type: Object,
    },
  },
  components: {
    XChart,
  },
  data() {
    return {
      loading: false,
      drawer: false,
      drawerTitle: "图表",
      option: {},
      xAxisData: [],
      yAxisData: [],
    };
  },
  mounted() {
    this.drawer = true;
    this.getData();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    drawerClosed() {
      this.$emit("closed");
    },
    getData() {
      let url =
        this.$mapValue.mapUrl[2] +
        `api/windData/getDataList.json?longitude=${this.imgPoint.imglat}&latitude=${this.imgPoint.imglon}`;
      this.loading = true;
      this.drawerTitle = `风场（东经${this.imgPoint.lon}° 北纬${this.imgPoint.lat}°）`;
      document.getElementById("el-drawer__title").children[1].innerHTML =
        "<div>X</div>";
      // this.$axios.post(url)
      this.$axios({
        method: "post",
        url: url,
        headers: { token: "XG5medfPW41LQZ0sj8WXPbgGaNq9hh3MMzkGIETVws" },
      })
        .then((res) => {
          if (res && res.data && res.data.success) {
            res.data.result.forEach((element) => {
              this.xAxisData.push(
                new Date(
                  (element.currentTime + "").replace(
                    /^(\d{4})(\d{2})(\d{2})(\d{2})$/,
                    "$1/$2/$3 $4:"
                  )
                )
              );
              this.yAxisData.push([
                parseFloat(element.speed),
                parseFloat(element.angle),
              ]);
            });
            this.initChart();
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    initChart() {
      var vm = this;
      Highcharts.setOptions({
        global: {
          useUTC: false, //关闭UTC
        },
      });
      // Construct the chart
      this.option = {
        title: {
          text: null,
        },
        xAxis: {
          tickInterval: 3,
          categories: this.xAxisData,
          type: "datetime",
          labels: {
            step: 6,
            overflow: "justify",
            formatter: function () {
              return Highcharts.dateFormat("%d日%H时", this.value);
            },
          },
          offset: 40,
        },
        yAxis: [
          {
            labels: {
              format: "{value}",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            title: {
              text: "风速m/s",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            plotLines: [
              {
                color: "#FFD700",
                dashStyle: "dash",
                width: 2,
                value: 10.8,
                label: {
                  align: "left",
                  style: {
                    color: "#FFD700",
                  },
                  text: "6级风",
                  x: -2,
                },
                zIndex: 3,
              },
              {
                color: "#FF0000",
                dashStyle: "dash",
                width: 2,
                value: 17.2,
                label: {
                  align: "left",
                  style: {
                    color: "#FF0000",
                  },
                  text: "8级风",
                  x: -2,
                },
                zIndex: 3,
              },
            ],
          },
        ],
        // plotOptions: {
        //   series: {
        //     pointStart: Date.UTC(2017, 0, 29),
        //     pointInterval: 36e5
        //   }
        // },
        tooltip: {
          formatter: function () {
            var d = new Date(this.x);
            var s =
              "<b>" +
              d.getFullYear() +
              "年" +
              (d.getMonth() + 1) +
              "月" +
              d.getDate() +
              "日" +
              d.getHours() +
              "时" +
              "</b>";
            s +=
              "<br/><span>\u25CF" +
              this.point.series.name +
              ": " +
              vm.yAxisData[this.point.index][0].toFixed(2) +
              "m/s </span>";
            var f = Number(vm.yAxisData[this.point.index][1]);
            var dir;
            if (f >= 0 && f < 22.5) {
              dir = "北";
            } else if (f >= 22.5 && f < 45) {
              dir = "北东北";
            } else if (f >= 45 && f < 67.5) {
              dir = "东北";
            } else if (f >= 67.5 && f < 90) {
              dir = "东东北";
            } else if (f >= 90 && f < 112.5) {
              dir = "东";
            } else if (f >= 112.5 && f < 135) {
              dir = "东东南";
            } else if (f >= 135 && f < 157.5) {
              dir = "东南";
            } else if (f >= 157.5 && f < 180) {
              dir = "南东南";
            } else if (f >= 180 && f < 202.5) {
              dir = "南";
            } else if (f >= 202.5 && f < 225) {
              dir = "南西南";
            } else if (f >= 225 && f < 247.5) {
              dir = "西南";
            } else if (f >= 247.5 && f < 270) {
              dir = "西西南";
            } else if (f >= 270 && f < 292.5) {
              dir = "西";
            } else if (f >= 292.5 && f < 315) {
              dir = "西西北";
            } else if (f >= 315 && f < 337.5) {
              dir = "西北";
            } else if (f >= 337.5) {
              dir = "北西北";
            } else {
              dir = "未知";
            }
            s +=
              "<br/><span>\u25CF" +
              this.point.series.name +
              ": " +
              dir +
              (this.point.beaufortLevel
                ? "(" + this.point.beaufortLevel + "级)"
                : "") +
              " </span>";

            return s;
          },
        },
        // tooltip: {
        //   shared: true,
        //   formatter: function () {
        //     var d = new Date(this.x);
        //     var s = '<b>' + d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日' + d.getHours() + '时' + '</b>';
        //     this.points.forEach(point => {
        //       if (point.y) {
        //         s += '<br/><span style="color:' + point.series.color + '">\u25CF' + point.series.name + ': ' + point.y.toFixed(2) + 'm/s </span>';
        //       }
        //       if (point.point.direction) {
        //         var f = Number(point.point.direction)
        //         var dir;
        //         if (f >= 0 && f < 22.5) {
        //           dir = "北"
        //         }
        //         else if (f >= 22.5 && f < 45) {
        //           dir = "北东北"
        //         }
        //         else if (f >= 45 && f < 67.5) {
        //           dir = "东北"
        //         }
        //         else if (f >= 67.5 && f < 90) {
        //           dir = "东东北"
        //         }
        //         else if (f >= 90 && f < 112.5) {
        //           dir = "东"
        //         }
        //         else if (f >= 112.5 && f < 135) {
        //           dir = "东东南"
        //         }
        //         else if (f >= 135 && f < 157.5) {
        //           dir = "东南"
        //         }
        //         else if (f >= 157.5 && f < 180) {
        //           dir = "南东南"
        //         }
        //         else if (f >= 180 && f < 202.5) {
        //           dir = "南"
        //         }
        //         else if (f >= 202.5 && f < 225) {
        //           dir = "南西南"
        //         }
        //         else if (f >= 225 && f < 247.5) {
        //           dir = "西南"
        //         }
        //         else if (f >= 247.5 && f < 270) {
        //           dir = "西西南"
        //         }
        //         else if (f >= 270 && f < 292.5) {
        //           dir = "西"
        //         }
        //         else if (f >= 292.5 && f < 315) {
        //           dir = "西西北"
        //         }
        //         else if (f >= 315 && f < 337.5) {
        //           dir = "西北"
        //         }
        //         else if (f >= 337.5) {
        //           dir = "北西北"
        //         }
        //         else {
        //           dir = '未知'
        //         }
        //         s += '<br/><span style="color:' + point.series.color + '">\u25CF' + point.series.name + ': ' + dir + '(' + point.point.beaufortLevel + '级) </span>';
        //       }
        //     });
        //     return s;
        //   }
        // },
        series: [
          {
            name: "风速",
            type: "areaspline",
            keys: ["y", "rotation"], // rotation is not used here
            data: this.yAxisData,
            // [
            //   [9.8, 177.9],
            //   [10.1, 177.2],
            //   [11.3, 179.7],
            //   [10.9, 175.5],
            //   [9.3, 159.9],
            //   [8.8, 159.6],
            //   [7.8, 162.6],
            //   [5.6, 186.2],
            //   [6.8, 146.0],
            //   [6.4, 139.9],
            //   [3.1, 180.2],
            //   [4.3, 177.6],
            //   [5.3, 191.8],
            //   [6.3, 173.1],
            //   [7.7, 140.2],
            //   [8.5, 136.1],
            //   [9.4, 142.9],
            //   [10.0, 140.4],
            //   [5.3, 142.1],
            //   [3.8, 141.0],
            //   [3.3, 116.5],
            //   [1.5, 327.5],
            //   [0.1, 1.1],
            //   [1.2, 11.1]
            // ],
            color: Highcharts.getOptions().colors[0],
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0.25)
                    .get(),
                ],
              ],
            },
            threshold: null,
          },
          {
            name: "风向",
            type: "windbarb",
            data: this.yAxisData,
            // [
            //   [9.8, 177.9],
            //   [10.1, 177.2],
            //   [11.3, 179.7],
            //   [10.9, 175.5],
            //   [9.3, 159.9],
            //   [8.8, 159.6],
            //   [7.8, 162.6],
            //   [5.6, 186.2],
            //   [6.8, 146.0],
            //   [6.4, 139.9],
            //   [3.1, 180.2],
            //   [4.3, 177.6],
            //   [5.3, 191.8],
            //   [6.3, 173.1],
            //   [7.7, 140.2],
            //   [8.5, 136.1],
            //   [9.4, 142.9],
            //   [10.0, 140.4],
            //   [5.3, 142.1],
            //   [3.8, 141.0],
            //   [3.3, 116.5],
            //   [1.5, 327.5],
            //   [0.1, 1.1],
            //   [1.2, 11.1]
            // ],
            color: Highcharts.getOptions().colors[1],
            showInLegend: false,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-chart {
  z-index: 9999 !important;
  font-size: 0.18rem;

  ::v-deep .el-drawer__header {
    padding: 0.1rem;
    margin-bottom: 0;

    span[role="heading"] {
      text-align: center;
      font-size: 0.2rem;
      font-weight: 600;
    }
    span:focus {
      outline: 0 !important;
    }
  }
}
</style>