<template>
  <div class="tchart">
    <div :id="id" :option="option" class="linechart"></div>
    <div class="corner top-left" />
    <div class="corner top-right" />
    <div class="corner bottom-left" />
    <div class="corner bottom-right" />
  </div>
</template>

<script>
// <script module = 'chartsContainer' lang="renderjs">
import Highcharts from "highcharts"

export default {
  name: "charts",
  props: {
    id: {
      type: String,
    },
    option: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    option() {
      this.chart = Highcharts.chart(this.id, this.option)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = Highcharts.chart(this.id, this.option)
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart = null
  },
}
</script>

<style lang="scss" scoped>
.tchart {
  position: relative;
  width: 100%;
  height: 250px;
  font-size: 0.16rem;
  color: #ffffff;
  border: 0.01rem solid #3366ba;
  border-radius: 0.03rem;
  background-color: rgba(0, 16, 53, 0.8);

  .linechart {
    width: 100%;
    height: 100%;
  }

  .corner {
    border: none;
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
  }
  .top-left {
    top: 0;
    left: 0;
    border-top: 0.01rem solid #00f2ff;
    border-left: 0.01rem solid #00f2ff;
    border-top-left-radius: 0.03rem;
  }
  .top-right {
    top: 0;
    right: 0;
    border-top: 0.01rem solid #00f2ff;
    border-right: 0.01rem solid #00f2ff;
    border-top-right-radius: 0.03rem;
  }
  .bottom-left {
    bottom: 0;
    left: 0;
    border-bottom: 0.01rem solid #00f2ff;
    border-left: 0.01rem solid #00f2ff;
    border-bottom-left-radius: 0.03rem;
  }
  .bottom-right {
    bottom: 0;
    right: 0;
    border-bottom: 0.01rem solid #00f2ff;
    border-right: 0.01rem solid #00f2ff;
    border-bottom-right-radius: 0.03rem;
  }

  ::v-deep .highcharts-credits {
    display: none;
  }
}
</style>
