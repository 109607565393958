import axios from "axios";
// import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true,
  timeout: 60000,
});

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://map.hengtianyi.com/';
}
service.interceptors.request.use(
  (config) => {
    // if (store.getters.user.token) {
    //   config.headers['token'] = store.getters.user.token
    // } else {
    //   config.headers['token'] = localStorage.getItem("mobiletoken")
    // }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

//配置成功后的拦截器
service.interceptors.response.use(
  (res) => {
    if (res.status == 200) {
      return res.data;
    } else {
      return Promise.reject(res.data.msg);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
