// import "./mapTool.css";
import Draw from "../draw/draw.js";
import Grid from "../grid/grid.js";
class MapTool {
    constructor(props) {
        this.map = props.map;
        this.mapApi = props.mapApi;
        this.style = {
            fill: new this.mapApi.Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new this.mapApi.Stroke({
                color: 'red',
                width: 2
            }),
            image: new this.mapApi.Circle({
                radius: 7,
                fill: new this.mapApi.Fill({
                    color: 'red'
                })
            }),
            imageIcon: new this.mapApi.Icon({
                // anchor: [0.5, 0.5],
                src: 'a.png',
                scale: 1,
                // rotation: this.getRadius(data.angle)
                // anchorYUnits: 'pixels'
            })
        }
        this.callData = null //回调数据 
        this.dragZoomIn; //拉框缩小
        this.dragZoomOut; //拉框放大
        this.dragZoomIn; //拉框缩小
        this.dragZoomOut; //拉框放大

        //默认工具条专用
        this.mapTool;
        this.mapTip;
        this.mapDom = props.mapContainer;
        this.container = props.container;
        this.drawData = [
            {
                name: '漫游',
                type: 'Pan',
                icon: require("@/assets/image/map/mapTool/ic_my.png"),
            },
            {
                name: '放大',
                type: 'Zoomin',
                icon: require("@/assets/image/map/mapTool/ic_fd.png"),
            },
            {
                name: '缩小',
                type: 'Zoomout',
                icon: require("@/assets/image/map/mapTool/ic_sx.png"),
            },
            {
                name: '拉框搜索',
                type: 'Extent',
                icon: require("@/assets/image/map/mapTool/extent.svg"),
            },
            {
                name: '标注',
                type: 'marker',
                icon: require("@/assets/image/map/mapTool/ic_eljcz1.png"),
            },
            {
                name: '定位',
                type: 'Pos',
                icon: require("@/assets/image/map/mapTool/pos.png"),
            },
            {
                name: '测量',
                type: 'LineString',
                icon: require("@/assets/image/map/mapTool/ic_cl.png"),
            },
            {
                name: '默认视野',
                type: 'View',
                icon: require("@/assets/image/map/mapTool/view.svg"),
            },
            {
                name: '渔区经纬网格化',
                type: 'Grid',
                icon: require("@/assets/image/map/mapTool/grid.svg"),
            },
            {
                name: '自定义点、线、面',
                type: 'Identify',
                icon: require("@/assets/image/map/mapTool/doIdentify.svg"),
            },
            {
                name: '清空',
                type: 'Clear',
                icon: require("@/assets/image/map/mapTool/ic_sc.png"),
            },
        ]
        this.addRootDom()


        this.singleTool = props.singleTool;
        this.crossTool = props.crossTool;
        this.updateTool = props.updateTool;
        this.identifyTool = props.identifyTool;
        this.$mapConfig = props.mapConfig;
        this.allDrawContainer = [];
        this.draw = null

        this.addDragZoomIn(false)
        this.addDragZoomOut(false)
    }
    //默认工具条专用
    init() {
    }
    addRootDom() {
        if (this.mapDom) {
            if (!this.container) {
                const rootDom = document.createElement('div')
                rootDom.className = 'container'
                this.mapDom.append(rootDom)
                this.container = rootDom
            } else {
                this.container.setAttribute('class', 'container')
            }
        }
    }
    addMapTool() {
        const mapTool = document.createElement('div')
        mapTool.id = 'mapTool';
        mapTool.className = 'mapTool';
        this.container.append(mapTool)
        this.mapTool = document.getElementById('mapTool')
        return mapTool
    }
    addMapToolTip() {
        const mapTip = document.createElement('div')
        mapTip.id = 'mapTip';
        mapTip.className = 'mapTip';
        this.mapTool.append(mapTip)
        let dtc = '';
        this.drawData.map((v, k) => {
            dtc += '<div class="mapTip dtc" data-name=' + v.name + ' data-value=' + v.type + ' data-index=' + k + '>'
            dtc += '<div><img src=' + v.icon + ' style="width:20px"/></div>'
            // dtc += '<div class="title"><font>' + v.name + '</font></div>'
            dtc += '</div>'
        })
        mapTip.innerHTML += dtc
        this.mapTip = document.getElementById('mapTip')
        return mapTip
    }
    _mapToolColorEvt(tar) {
        const dtcArray = tar.parentNode.children
        const index = tar.getAttribute('data-index')
        for (let i = 0; i < dtcArray.length; i++) dtcArray[i].className = 'mapTip dtc'
        dtcArray[index].className = 'mapTip dtcSelected'
    }

    initPosTool() {
        const posToolContainer = document.createElement('div')
        posToolContainer.id = 'posToolContainer';
        posToolContainer.className = 'posToolContainer';
        this.container.append(posToolContainer)
        this.posToolContainer = document.getElementById('posToolContainer')

        const coordInput = document.createElement('input')
        coordInput.id = 'coordInput';
        coordInput.className = 'coordInput';
        posToolContainer.append(coordInput)
        coordInput.style.height = '100%'
        this.coordInput = coordInput

        const coordButton = document.createElement('button')
        coordButton.id = 'coordButton';
        coordButton.className = 'coordButton';
        posToolContainer.append(coordButton)
        coordButton.style.height = '100%'
        coordButton.innerHTML = '定位'
        coordButton.setAttribute('placeholder', '数据输入格式：120,36')
        this.coordButton = coordButton
        const that = this
        coordButton.addEventListener('click', () => {
            const coordInputValue = that.coordInput.value
            if (coordInputValue) {
                const array = coordInputValue.split(',')
                const x = Number(array[0])
                const y = Number(array[1])
                const position = (x && y) ? [x, y] : [120, 36]
                that.positionTo(position)
            }
        })
        return posToolContainer
    }
    initDrawTool() {
        const drawToolContainer = document.createElement('div')
        drawToolContainer.id = 'drawToolContainer';
        drawToolContainer.className = 'drawToolContainer';
        this.container.append(drawToolContainer)
        this.drawToolContainer = document.getElementById('drawToolContainer')
        return drawToolContainer
    }
    addDrawContainer() {
        this.addMapTool()
        const mapTip = this.addMapToolTip()
        this.initPosTool()
        this.initDrawTool()
        mapTip.addEventListener('click', (e) => {
            const getTarget = (tar) => {
                return tar.getAttribute('data-index') ?
                    tar : getTarget(tar.parentNode)
            }
            this.addMapToolEvent(getTarget(e.target));
        }, false)
    }
    //默认工具条初始化
    initDraw() {
        this.initPosTool()
        this.initDrawTool()
        return this
    }
    addLayer() {
        this.map.addLayer(this.toolLayer)
    }
    insertAtLayer(index, layer) {
        let layersArray = this.map.getLayers();
        layersArray.insertAt(index, layer);
    }
    setDefaultInteraction() {
        this.hidePosTool()
        this.hideDrawTool()
        this.removeDraw()
        this.removeGrid()
        // this.deactiveDraw()
        this.deactiveZoomIn()
        this.deactiveZoomOut()
        // this.removeMessureInteraction()
        this.mapDom.style.cursor = "default";
    }
    showPosTool(pos) {
        if (!pos) {
            this.posToolContainer.style.display = 'flex'
            this.posToolContainer.style.height = this.mapTool ? this.mapTool.children[0].offsetHeight + 'px' : this.container.style.offsetHeight + 'px'
        } else {
            this.positionTo(pos)
        }
    }
    hidePosTool() {
        this.posToolContainer.style.display = 'none'
    }
    hideDrawTool() {
        this.drawToolContainer.style.display = 'none'
        let doms = this.drawToolContainer.children
        for (let i = 0; i < doms.length; i++) {
            doms[i].remove()
        }
    }
    showDrawTool() {
        this.posToolContainer.style.height = this.mapTool ? this.mapTool.children[0].offsetHeight + 'px' : this.container.style.offsetHeight + 'px'
        this.drawToolContainer.style.display = 'block'
    }

    positionTo(position, zoom, degree) {
        if (!zoom) zoom = this.map.getView().getZoom();
        if (!degree) degree = 360;
        this.map.getView().animate({
            center: position,
            duration: 2000,
            zoom: zoom,
            rotation:
                ((this.map.getView().getRotation() + Math.PI * 2) / 360) * degree
        });
    }
    addMapToolEvent(t, t1, t2, t3) {
        if (t instanceof HTMLElement) {
            this._mapToolColorEvt(t);
            this.setDefaultInteraction()
            this._mapToolConductEvt(t.getAttribute('data-value'))
        } else {
            this.mapDom.style.cursor = "default";

            if (this.crossTool) this.deactiveDraw() //跨用具当且只取消工具，但保留工具图层
            else { if (t !== 'Clear') { this.removeDraw(); this.removeGrid() } }
            this.removeMessureLast()
            this.deactiveZoomIn()
            this.deactiveZoomOut()
            this._mapToolConductEvt(t, t1, t2, t3)
        }
        return this
    }
    //自定义专用
    _mapToolConductEvt(type, type1, type2, type3) {
        // this.setCallData()
        switch (type) {
            case "Pan"://平移
                this.setPan()
                this.setCallData('Pan', [])
                break
            case "Zoomin"://放大
                this.dragMapZoomIn()
                this.setCallData('Zoomin', [])
                break
            case "Zoomout"://缩小
                this.dragMapZoomOut()
                this.setCallData('Zoomout', [])
                break
            case "Extent"://框选
                this.setExtent(type, 'Extent_Polygon')
                break
            case "Direction"://方位角
                this.setDirection(type, 'Direction')
                break
            case "marker": //设置标识
                this.setMarker('Point', 'Marker')
                break
            case "Pos"://定位
                // this.showPosTool(type1)
                this.positionTo(type1)
                this.setCallData('Pos', type1)
                break
            case "LineString"://测量
                this.initMeasureTools('Messure')
                break
            case "View"://默认视图
                this.setDefaultExtent()
                this.setCallData('View', [])
                break
            case "Grid"://格网
                this.removeGrid()
                this.addGrid(type1)
                this.setCallData('Grid', [])
                break
            case "Identify": //默认工具条方式执行
                this.addDrawTool('Identify')
                break
            case "IdentifyPost": //传入方式执行
                this.addIdentifyPost(type1, type2, type3, 'Calculate')
                break
            case "Clear":
                // this.deactiveDraw();

                this.removeAllDraw();
                this.removeGrid()
                this.setCallData('Clear', [])
                break
            case "Deactive":
                this.deactiveDraw()
                this.setCallData('Deactive', [])
                break
            default:
                this.setCallData('other', [])
                break
        }
    }
    removeAllDraw() {
        this.allDrawContainer.map(v => {
            if (v) v.removeLayer()
        })
    }
    removeDraw() {
        if (this.draw) this.draw.removeLayer()
    }
    deactiveDraw() {
        if (this.draw) this.draw.deactiveDraw()
    }
    //移除格网
    removeGrid() {
        if (this.grid) this.grid.removeGrid()
    }
    //移除最后一次测量相关
    removeMessureLast() {
        if (this.draw) { this.draw.removeMessureListener(); this.draw.removeMessureOverlay(1) }
    }
    showGridLayer() {
        if (this.grid) this.grid.addLayer()
    }
    deactiveZoomIn() {
        this.dragZoomIn.setActive(false);
    }
    deactiveZoomOut() {
        this.dragZoomOut.setActive(false);
    }
    activeZoomIn() {
        this.dragZoomIn.setActive(true);
    }
    activeZoomOut() {
        this.dragZoomOut.setActive(true);
    }
    // 放大
    addDragZoomIn(status) {
        this.dragZoomIn = new this.mapApi.InteractionDragZoom({
            condition: this.mapApi.always,
            out: false,
        });
        this.map.addInteraction(this.dragZoomIn);
        this.dragZoomIn.setActive(status);
    }
    //缩小
    addDragZoomOut(status) {
        this.dragZoomOut = new this.mapApi.InteractionDragZoom({
            condition: this.mapApi.always,
            out: true,
        });
        this.map.addInteraction(this.dragZoomOut);
        this.dragZoomOut.setActive(status);
    }
    //平移
    setPan() {
        this.deactiveZoomIn()
        this.deactiveZoomOut()
        this.mapDom.style.cursor = "move";
    }
    //初始化放大
    dragMapZoomIn() {
        this.map.getView().setZoom(this.map.getView().getZoom() + 1)
    }
    //初始化缩小
    dragMapZoomOut() {
        this.map.getView().setZoom(this.map.getView().getZoom() - 1)
    }
    //拉框查询
    setExtent(type, rootType) {
        this.draw = new Draw({
            map: this.map,
            mapApi: this.mapApi,
            setCallData: this.setCallData.bind(this),
            rootType: rootType,
            singleTool: this.singleTool,
            updateTool: this.updateTool,
            identifyTool: this.identifyTool,
            mapConfig: this.$mapConfig
        })
        this.draw.drawingTool(type)
        this.allDrawContainer.push(this.draw)
    }
    //拉框查询
    setDirection(type, rootType) {
        this.draw = new Draw({
            map: this.map,
            mapApi: this.mapApi,
            setCallData: this.setCallData.bind(this),
            rootType: rootType,
            singleTool: this.singleTool,
            updateTool: this.updateTool,
            identifyTool: this.identifyTool,
            mapConfig: this.$mapConfig
        })
        this.draw.drawingTool(type)
        this.allDrawContainer.push(this.draw)
    }
    //设置标注
    setMarker(type, rootType) {
        this.draw = new Draw({
            map: this.map,
            mapApi: this.mapApi,
            setCallData: this.setCallData.bind(this),
            rootType: rootType,
            singleTool: this.singleTool,
            updateTool: this.updateTool,
            identifyTool: this.identifyTool,
            mapConfig: this.$mapConfig
        })
        this.draw.drawingTool(type)
        this.allDrawContainer.push(this.draw)
    }
    //默认视野
    setDefaultExtent() {
        this.map
            .getView()
            .fit([104.02435024870165, 15.750582910522061, 112.69188553367938, 22.586948769096047])
    }
    //经纬网格化
    addGrid(type) {
        this.grid = new Grid({
            map: this.map,
            mapApi: this.mapApi,
            mapConfig: this.$mapConfig
            // extent: [119.120916, 34.672952, 120.045458, 35.430688],
        }).addGrid(type)
        this.map.render()
    }
    //添加自定义点线面工具条
    addDrawTool(rootType) {
        this.showDrawTool()
        this.draw = new Draw({
            map: this.map,
            container: this.drawToolContainer,
            mapApi: this.mapApi,
            setCallData: this.setCallData.bind(this),
            rootType: rootType,
            singleTool: this.singleTool,
            updateTool: this.updateTool,
            identifyTool: this.identifyTool,
            mapConfig: this.$mapConfig
        })
        this.draw.addDrawContainer()
        this.allDrawContainer.push(this.draw)
        return this
    }
    addIdentifyPost(type, type1, type2, rootType) {
        this.draw = new Draw({
            map: this.map,
            mapApi: this.mapApi,
            setCallData: this.setCallData.bind(this),
            rootType: rootType,
            singleTool: this.singleTool,
            updateTool: this.updateTool,
            identifyTool: this.identifyTool,
            mapConfig: this.$mapConfig
        })
        this.draw.drawPost(type, type1, type2)
        this.allDrawContainer.push(this.draw)
    }
    //测量工具
    initMeasureTools(rootType) {
        this.removeMessureLast()
        this.draw = new Draw({
            map: this.map,
            mapApi: this.mapApi,
            setCallData: this.setCallData.bind(this),
            rootType: rootType,
            singleTool: this.singleTool,
            updateTool: this.updateTool,
            identifyTool: this.identifyTool,
            mapConfig: this.$mapConfig
        })
        this.draw.drawingTool('Messure')
        this.allDrawContainer.push(this.draw)
    }
    removeMessureInteraction() {
        this.map.removeInteraction(this.drawMessure);
    }
    setCallData(callType, data) {
        // this.draw.removeAllDraw
        this.callData = {
            type: callType,
            data: data,
        }
    }
    update(param) {
        if (this.draw)
            for (let key in param) {
                if (Object.prototype.hasOwnProperty.call(this, key)) this.draw[key] = param[key];
            }
    }
}
export default MapTool
